import { Box } from '@mui/material'
import { EditorContent } from '@tiptap/react'

import { editorStyle } from '../styles'
import TiptapBubbleMenu from '../TiptapBubbleMenu/TiptapBubbleMenu'
import TiptapFloatingMenu from '../TiptapFloatingMenu/TiptapFloatingMenu'

const TiptapEditor = ({ editor, sx = {} }) => {
  return (
    editor && (
      <Box sx={{ ...editorStyle, ...sx }}>
        <EditorContent editor={editor} />
        {editor.isEditable && (
          <>
            <TiptapFloatingMenu editor={editor} />
            <TiptapBubbleMenu editor={editor} />
          </>
        )}
      </Box>
    )
  )
}

export default TiptapEditor
