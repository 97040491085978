import { Box } from '@mui/material'
import { useMutation, useQuery } from '@redwoodjs/web'
import { useEditor } from '@tiptap/react'
import { useEffect, useMemo } from 'react'
import { extensions } from 'src/components/Tiptap/extensions'
import { debounce } from 'lodash'

import { logger } from 'src/lib/logger'
import TiptapEditor from 'src/components/Tiptap/TiptapEditor/TiptapEditor'
const GET_PAGE_FOR_ACTION = gql`
  query pageForAction($actionId: String!, $workspaceId: String!) {
    pageForAction(actionId: $actionId, workspaceId: $workspaceId) {
      id
      actionIds
      title
      contentJson
    }
  }
`

const UPDATE_PAGE_FROM_ACTION_MUTATION = gql`
  mutation UpdatePageFromActionMutation(
    $id: String!
    $input: UpdatePageInput!
  ) {
    updatePage(id: $id, input: $input) {
      id
      createdAt
      updatedAt
      ownerEmail
      title
      publishedForUserAt
      madeExternalAt
      contentJson
      contentHtml
    }
  }
`

function getTitleText(editor) {
  const doc = editor.state.doc
  let titleText = ''

  let n

  // Traverse the document to find the 'title' node
  doc.descendants((node, pos) => {
    if (node.type.name === 'title') {
      n = node
      node.content.forEach((child) => {
        if (child.isText) {
          titleText += child.text
        } else if (child.isAtom || child.isLeaf) {
          // Handle specific cases for object chips or other structures
          if (child.attrs && child.attrs.displayName) {
            titleText += ` ${child.attrs.displayName} `
          }
        }
      })
      return false // Stop traversal once the title is found
    }
  })

  logger.dev({ titleText, node: n })

  return titleText
}

const ActionDraftTiptap = ({
  actionId,
  workspaceId,
}: {
  actionId: string
  workspaceId: string
}) => {
  const { data } = useQuery(GET_PAGE_FOR_ACTION, {
    variables: { actionId, workspaceId },
  })
  const [updatePage, { loading: saving }] = useMutation(
    UPDATE_PAGE_FROM_ACTION_MUTATION
  )

  const handleUpdatePageContent = (editor) => {
    if (editor && page) {
      const contentJson = editor.getJSON()
      const contentHtml = editor.getHTML()
      updatePage({
        variables: {
          id: page.id,
          input: {
            title: getTitleText(editor) || '',
            contentJson,
            contentHtml,
          },
        },
      })
    }
  }

  const page = useMemo(() => data?.pageForAction, [data])

  const debouncedUpdateContent = useMemo(
    () => debounce((editor) => handleUpdatePageContent(editor), 750),
    [page]
  )
  const editor = useEditor({
    extensions,
    autofocus: true,
    editable: true,
    onUpdate: ({ editor }) => debouncedUpdateContent(editor),
  })

  useEffect(() => {
    if (editor && editor.isEmpty && page?.contentJson) {
      editor.commands.setContent(page.contentJson)
    }
  }, [editor, page?.contentJson])

  return (
    <Box sx={{ height: '500px', width: '100%', overflowY: 'auto' }}>
      <TiptapEditor
        editor={editor}
        sx={{
          height: '100%',
          width: '100%',
          h1: { display: 'none' },
          m: 0,
          '& .tiptap>*': { px: '12px' },
        }}
      />
    </Box>
  )
}

export default ActionDraftTiptap
