import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material'
import { useMutation } from '@redwoodjs/web'
import { IconBolt, IconPlus, IconX } from '@tabler/icons-react'
import { useContext, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import ContactTile from 'src/components/ContactTile/ContactTile'
import ObjectFinder, {
  SearchResult,
} from 'src/components/ObjectFinder/ObjectFinder'
import OpportunityChip from 'src/components/OpportunityChip/OpportunityChip'
import OrganizationTile from 'src/components/Organizations/OrganizationTile/OrganizationTile'
import Row from 'src/components/Row/Row'
import { actionButtonStyle } from 'src/components/Sidebar/Sidebar'
import { extractEmailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'
import { ActionType, ActionPriority, CreateActionInput } from 'types/graphql'

/*
input CreateActionInput {
    id: String
    workspaceId: String!
    userId: String
    title: String!
    description: String
    status: ActionStatus
    type: ActionType!
    people: [String]
    domains: [String]
    opportunityIds: [String]
    priority: ActionPriority
    reasoning: String
    draftBody: String
    draftTitle: String
    timeframeStart: DateTime
    timeframeEnd: DateTime

    channelType: ActionChannelType
    channelId: String
    channelLabel: String
    channelAccountId: String

    ownerEmail: String
    ownerId: String

    sourceId: String
    sourceType: ActionSourceType
    sourceLabel: String
}
*/

const CREATE_ACTION_FROM_DIALOG = gql`
  mutation CreateActionFromDialog($input: CreateActionInput!) {
    createActionByUser(input: $input)
  }
`

const ActionTypes = {
  SUPPORT: { key: 'SUPPORT', label: 'Support' },
  FOLLOWUP: { key: 'FOLLOWUP', label: 'Follow Up' },
  MEETINGPREP: { key: 'MEETINGPREP', label: 'Meeting Prep' },
  FEATURE_REQUEST: { key: 'FEATURE_REQUEST', label: 'Feature Request' },
  OTHER: { key: 'OTHER', label: 'Other' },
}

const ActionChannelTypes = {
  GMAIL: { key: 'GMAIL', label: 'Gmail' },
  EMAIL: { key: 'EMAIL', label: 'Email' },
  SLACK: { key: 'SLACK', label: 'Slack' },
  MEETING: { key: 'MEETING', label: 'Meeting' },
  NOTE: { key: 'NOTE', label: 'Note' },
  PAGE: { key: 'PAGE', label: 'Page' },
}

const ActionPriorities = {
  LOW: { key: 'LOW', label: 'Low' },
  MEDIUM: { key: 'MEDIUM', label: 'Medium' },
  HIGH: { key: 'HIGH', label: 'High' },
  URGENT: { key: 'URGENT', label: 'Urgent' },
}

const ActionCreateDialog = ({
  workspaceId,
  variant,
  orgs,
  people,
  oppIds,
  onUpdate,
}: {
  workspaceId: string
  variant: 'icon' | 'button' | 'chip'
  orgs?: string[]
  people?: string[]
  oppIds?: string[]
  onUpdate?: () => void
}) => {
  const { pipelines } = useContext(DayContext)
  const oppsById = useMemo(() => {
    const oppsMap = {}
    for (const pipeline of pipelines) {
      for (const stage of pipeline.stages) {
        for (const opportunity of stage.opportunities) {
          oppsMap[opportunity.id] = opportunity
        }
      }
    }
    return oppsMap
  }, [pipelines])

  const initAction = {
    workspaceId,
    domains: orgs ?? [],
    people: people ?? [],
    opportunityIds: oppIds ?? [],
    status: null,
    type: ActionTypes.FOLLOWUP.key as ActionType,
    priority: ActionPriorities.MEDIUM.key as ActionPriority,
    title: '',
    description: '',
  }

  const [open, setOpen] = useState(false)
  const [actionToCreate, setActionToCreate] =
    useState<Partial<CreateActionInput> | null>(null)

  const [createActionFromDialog] = useMutation(CREATE_ACTION_FROM_DIALOG)

  const removePerson = (person: string) => {
    setActionToCreate((prev) => ({
      ...prev,
      people: prev?.people?.filter((p) => p !== person),
    }))
  }

  const removeOrg = (org: string) => {
    setActionToCreate((prev) => ({
      ...prev,
      domains: prev?.domains?.filter((d) => d !== org),
    }))
  }

  const removeOpportunity = (opp: string) => {
    setActionToCreate((prev) => ({
      ...prev,
      opportunityIds: prev?.opportunityIds?.filter((o) => o !== opp),
    }))
  }

  const reset = () => {
    setActionToCreate(null)
    setOpen(false)
    onUpdate()
  }

  const createAction = () => {
    logger.dev(actionToCreate)
    toast.promise(
      createActionFromDialog({
        variables: { input: actionToCreate },
      }),
      {
        loading: 'Creating action...',
        success: () => {
          setOpen(false)
          setActionToCreate(null)
          onUpdate()
          return 'Action created'
        },
        error: 'Error creating action',
      }
    )
  }

  const handleInputChange = (field: keyof CreateActionInput, value: any) => {
    setActionToCreate((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const isValidAction = () => {
    return actionToCreate?.title && actionToCreate?.type
  }

  const addObject = (object: SearchResult) => {
    if (object.objectType === NativeObjectTypes.Person) {
      setActionToCreate((prev) => ({
        ...prev,
        people: [...new Set([...(prev?.people ?? []), object.objectId])],
        domains: [
          ...new Set(
            [
              ...(prev?.domains ?? []),
              extractEmailDomain(object.objectId),
            ].filter(Boolean)
          ),
        ],
      }))
    } else if (object.objectType === NativeObjectTypes.Organization) {
      setActionToCreate((prev) => ({
        ...prev,
        domains: [...new Set([...(prev?.domains ?? []), object.objectId])],
      }))
    } else if (object.objectType === NativeObjectTypes.Opportunity) {
      logger.dev({ object })
      setActionToCreate((prev) => ({
        ...prev,
        opportunityIds: [
          ...new Set([...(prev?.opportunityIds ?? []), object.objectId]),
        ],
      }))
    }
  }

  if (!actionToCreate && open) {
    setActionToCreate(initAction)
  }

  return (
    <>
      {variant === 'icon' && (
        <IconButton onClick={() => setOpen(true)}>
          <IconPlus size={16} />
        </IconButton>
      )}
      {variant === 'button' && (
        <Button onClick={() => setOpen(true)}>Add Action</Button>
      )}
      {variant === 'chip' && (
        <Chip
          variant="outlined"
          sx={actionButtonStyle}
          size="small"
          clickable={true}
          icon={<IconBolt size={16} />}
          label="Add Action"
          onClick={() => setOpen(true)}
        />
      )}
      <Dialog
        open={open}
        onClose={() => {
          reset()
        }}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>Create Action</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            required
            label="Title"
            value={actionToCreate?.title ?? ''}
            onChange={(e) => handleInputChange('title', e.target.value)}
            sx={{ mb: 2, mt: 2 }}
          />

          <TextField
            fullWidth
            multiline
            rows={3}
            label="Description"
            value={actionToCreate?.description ?? ''}
            onChange={(e) => handleInputChange('description', e.target.value)}
            sx={{ mb: 2 }}
          />

          <Row sx={{ gap: 2, mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                value={actionToCreate?.type ?? ActionTypes.SUPPORT.key}
                label="Type"
                onChange={(e) => handleInputChange('type', e.target.value)}
              >
                {Object.values(ActionTypes).map((type) => (
                  <MenuItem
                    key={type.key}
                    value={type.key}
                  >
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Priority</InputLabel>
              <Select
                value={actionToCreate?.priority ?? ActionPriorities.MEDIUM.key}
                label="Priority"
                onChange={(e) => handleInputChange('priority', e.target.value)}
              >
                {Object.values(ActionPriorities).map((priority) => (
                  <MenuItem
                    key={priority.key}
                    value={priority.key}
                  >
                    {priority.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Row>

          {actionToCreate?.people?.length > 0 && (
            <>
              <Typography
                variant="h4"
                sx={{ mt: 3, mb: 2 }}
              >
                People
              </Typography>
              {actionToCreate?.people?.map((person, index) => (
                <Row
                  key={`person-${index}-${person}`}
                  sx={{ justifyContent: 'space-between', mb: 2 }}
                >
                  <ContactTile
                    email={person}
                    size={48}
                  />
                  <IconButton onClick={() => removePerson(person)}>
                    <IconX size={16} />
                  </IconButton>
                </Row>
              ))}
            </>
          )}

          {actionToCreate?.domains?.length > 0 && (
            <>
              <Typography
                variant="h4"
                sx={{ mt: 3, mb: 2 }}
              >
                Organizations
              </Typography>
              {actionToCreate?.domains?.map((org, index) => (
                <Row
                  key={`organization-${index}-${org}`}
                  sx={{ justifyContent: 'space-between', mb: 2 }}
                >
                  <OrganizationTile domain={org} />
                  <IconButton onClick={() => removeOrg(org)}>
                    <IconX size={16} />
                  </IconButton>
                </Row>
              ))}
            </>
          )}
          {actionToCreate?.opportunityIds?.length > 0 && (
            <>
              <Typography
                variant="h4"
                sx={{ mt: 3, mb: 2 }}
              >
                Opportunities
              </Typography>
              {actionToCreate?.opportunityIds?.map((oppId, index) => (
                <Row
                  key={`opportunity-${index}-${oppId}`}
                  sx={{ justifyContent: 'space-between', mb: 2 }}
                >
                  <OpportunityChip
                    oppId={oppId}
                    oppsById={oppsById}
                  />
                  <IconButton onClick={() => removeOpportunity(oppId)}>
                    <IconX size={16} />
                  </IconButton>
                </Row>
              ))}
            </>
          )}

          <ObjectFinder
            objectTypes={[
              NativeObjectTypes.Person,
              NativeObjectTypes.Organization,
              NativeObjectTypes.Opportunity,
            ]}
            onChangeQuery={(query) => {
              console.log(query)
            }}
            onSelect={addObject}
            sx={{ mb: 2, m: 0, p: 0 }}
            inputSx={{
              px: 0,
              mx: 0,
            }}
            textFieldSx={{
              px: 0,
              mx: 0,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setActionToCreate(null)
              setOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => createAction()}
            disabled={!isValidAction()}
            variant="contained"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ActionCreateDialog
