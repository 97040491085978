import { Box, Button, Chip } from '@mui/material'
import { Action } from 'types/graphql'

import SlackLogo from 'src/components/Slack/SlackLogo/SlackLogo'
import { IconCalendarBolt, IconFileText } from '@tabler/icons-react'

export enum ActionChannelType {
  EMAIL = 'EMAIL',
  GMAIL = 'GMAIL',
  SLACK = 'SLACK',
  MEETING = 'MEETING',
  NOTE = 'NOTE',
  PAGE = 'PAGE',
}

const ActionChannelChip = ({
  action,
  label = null,
  variant = 'chip',
  width = '112px',
  iconSize = 16,
}: {
  action: Action
  label?: string
  variant?: 'chip' | 'icon' | 'button'
  width?: string
  iconSize?: number
}) => {
  const channel = action?.channel

  const display = {
    icon: null,
    label: null,
    onClick: null,
  }

  let channelTypeId = channel?.type as ActionChannelType
  if (channelTypeId === 'EMAIL') {
    channelTypeId = 'GMAIL' as ActionChannelType
  }

  switch (channelTypeId) {
    case ActionChannelType.GMAIL:
      display.icon = (
        <Box
          component="img"
          src={'/gmail-icon.svg'}
          sx={{
            height: '12px',
            width: '12px',
          }}
        />
      )
      display.label = action?.draft?.title || action.channel.label || 'Gmail'

      break
    case ActionChannelType.SLACK:
      display.icon = <SlackLogo />
      display.label = channel.label ? `#${channel.label}` : 'Slack'

      break
    case ActionChannelType.MEETING:
      display.icon = <IconCalendarBolt />
      display.label = 'Meeting'
      break
    case ActionChannelType.NOTE:
      display.icon = <IconFileText />
      display.label = 'Note'
      break
    case ActionChannelType.PAGE:
      display.icon = <IconFileText />
      display.label = action?.draft?.title || 'Page'
      break
  }

  return (
    action?.id && (
      <>
        {variant === 'chip' ? (
          <Chip
            icon={display.icon}
            label={label || display.label}
            variant="outlined"
            size="small"
            sx={{
              width,
              border: 'none',
              justifyContent: 'left',
              fontWeight: '600',
              fontSize: '11px',
              letterSpacing: '-0.2px',
              '& .MuiChip-icon, svg': {
                flexShrink: 0,
                height: '16px',
                width: '16px',
                ml: '4px',
                mr: '0px',
              },
            }}
          />
        ) : variant === 'icon' ? (
          display.icon && (
            <>
              {React.cloneElement(display.icon, {
                style: {
                  height: `${iconSize}px`,
                  width: `${iconSize}px`,
                },
                size: iconSize,
              })}
            </>
          )
        ) : (
          <Button
            startIcon={React.cloneElement(display.icon, {
              style: {
                height: '24px',
                width: '24px',
              },
            })}
            onClick={display.onClick}
            variant="outlined"
            size="large"
            fullWidth={true}
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              letterSpacing: '-0.2px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              flexShrink: 1,
            }}
          >
            {label || display.label}
          </Button>
        )}
      </>
    )
  )
}

export default ActionChannelChip
