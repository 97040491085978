import { useContext, useEffect, useState } from 'react'

import { Box } from '@mui/material'

import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import SidebarLayoutClip from './SidebarLayoutClip/SidebarLayoutClip'
import SidebarLayoutContact from './SidebarLayoutContact/SidebarLayoutContact'
import SidebarLayoutMeetingRecording from './SidebarLayoutMeetingRecording/SidebarLayoutMeetingRecording'
import SidebarLayoutOpportunity from './SidebarLayoutOpportunity/SidebarLayoutOpportunity'
import SidebarLayoutOrganization from './SidebarLayoutOrganization/SidebarLayoutOrganization'
import SidebarLayoutPage from './SidebarLayoutPage/SidebarLayoutPage'

export const actionChipStyle = {
  px: '8px',
  height: '24px',
  fontSize: '0.7rem',
  fontWeight: 500,
  backgroundColor: (theme) => theme.palette.background.default,
  letterSpacing: '-0.6px',
}

export const sidebarBodyTextStyle = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '140%',
  letterSpacing: '-0.22px',
  color: (theme) => theme.palette.text.secondary,
}

export const sidebarHeadingTextStyle = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '130%',
  letterSpacing: '-0.7px',
  color: (theme) => theme.palette.text.primary,
}

export const socialIconStyle = {
  px: '0px',
  width: '24px',
  height: '24px',
  '& .MuiChip-label': {
    width: '0px',
    minWidth: '0px',
    m: 0,
    p: 0,
    pr: '10px',
  },
  '& .MuiChip-icon': {
    color: 'white',
  },
}

export const actionButtonStyle = {
  borderRadius: '50px',
  px: '6px',
  background: (theme) => theme.palette.background.default,
  border: '0.5px solid rgba(53, 64, 82, 0.5) !important',
  fontSize: '12px',
  fontWeight: 500,
  '&:hover': {
    background: (theme) => theme.palette.action.selected,
    border: '0.5px solid rgba(53, 64, 82, 0.5) !important',
  },
  letterSpacing: '-0.6px',
}

const Sidebar = ({
  isOpen = false,
  crmObject,
  onClose = () => {},
}: {
  isOpen?: boolean
  crmObject: any
  onClose?: () => void
}) => {
  const { internalDomains } = useContext(DayContext)
  const [open, setOpen] = useState(isOpen)

  const widths = {
    [NativeObjectTypes.Opportunity]: '680px',
    [NativeObjectTypes.Organization]: '680px',
    [NativeObjectTypes.Contact]: '680px',
  }

  const width = widths[crmObject?.objectType] || '380px'

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (!open) onClose()
  }, [open])

  useEffect(() => {
    if (
      crmObject?.objectId &&
      !internalDomains.includes(crmObject?.properties?.domain)
    ) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [crmObject, internalDomains])

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 1000,
        background: 'white',
        width: open ? width : 0,
        height: '100vh',
        overflowY: 'auto',
        top: 0,
        right: 0,
        p: 0,
        m: 0,
        boxShadow: `
        0 1px 2px rgba(136, 161, 183, 0.07),
        0 2px 4px rgba(136, 161, 183, 0.07),
        0 4px 8px rgba(136, 161, 183, 0.07),
        0 8px 16px rgba(136, 161, 183, 0.07),
        0 16px 32px rgba(136, 161, 183, 0.07),
        0 32px 64px rgba(136, 161, 183, 0.07)`,
        borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
        borderTopLeftRadius: '12px',
        borderBottomLeftRadius: '12px',
        //transition: 'all 0.4s ease',
      }}
    >
      <Box
        sx={{
          opacity: open ? 1 : 0,
          //filter: open ? 'saturate(100%)' : 'saturate(0%)',
          //transition: 'all 1.2s ease',
        }}
      >
        {crmObject?.objectId &&
          crmObject?.objectType === NativeObjectTypes.Contact && (
            <SidebarLayoutContact
              crmObject={crmObject}
              setOpen={setOpen}
            />
          )}

        {crmObject?.objectId &&
          crmObject?.objectType === NativeObjectTypes.Organization && (
            <SidebarLayoutOrganization
              domain={crmObject?.properties?.domain}
              setOpen={setOpen}
            />
          )}

        {crmObject?.objectId &&
          crmObject?.objectType === NativeObjectTypes.Opportunity && (
            <SidebarLayoutOpportunity
              crmObject={crmObject}
              setOpen={setOpen}
            />
          )}

        {/* DEPRECATED */}
        {crmObject?.objectId &&
          crmObject?.objectType === NativeObjectTypes.MeetingRecording && (
            <SidebarLayoutMeetingRecording
              crmObject={crmObject}
              setOpen={setOpen}
            />
          )}
        {crmObject?.objectId &&
          crmObject?.objectType === NativeObjectTypes.Page && (
            <SidebarLayoutPage setOpen={setOpen} />
          )}
        {crmObject?.objectId &&
          crmObject?.objectType === NativeObjectTypes.MeetingRecordingClip && (
            <SidebarLayoutClip
              crmObject={crmObject}
              setOpen={setOpen}
            />
          )}
      </Box>
    </Box>
  )
}

export default Sidebar
