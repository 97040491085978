import { useContext } from 'react'

import {
  Notes,
  OndemandVideoOutlined,
  TheatersOutlined,
} from '@mui/icons-material'
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import {
  RiArrowRightSLine,
  RiBarChartFill,
  RiBuilding2Line,
  RiContactsLine,
} from '@remixicon/react'

import { routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import SlackSupport from 'src/components/Slack/SlackSupport/SlackSupport'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'
import {
  getRelationshipSummaryTypeLabel,
  getRelationshipSummaryTypeMetadataFromKey,
} from 'src/lib/relationshipSummary'

const SidebarAdminHeader = ({ crmObject, setOpen }) => {
  const { currentUser: user } = useAuth()
  const {
    setSidebarObject,
    selectedWorkspace,
    workAccountWorkspaceConnections,
  } = useContext(DayContext)
  let objectLink = ''
  if (crmObject?.objectType === NativeObjectTypes.MeetingRecording) {
    if (
      workAccountWorkspaceConnections?.some(
        ({ connectedWorkspaces }) => connectedWorkspaces.length > 0
      )
    ) {
      objectLink = routes.workspaceMeetingRecording({
        workspaceId: selectedWorkspace,
        recordingId: crmObject?.objectId,
      })
    } else {
      objectLink = routes.reviewMeeting({
        id: crmObject?.objectId,
      })
    }
  }
  if (
    crmObject?.objectType === NativeObjectTypes.MeetingRecordingClip &&
    crmObject?.properties?.meetingRecording?.id
  ) {
    if (
      workAccountWorkspaceConnections?.some(
        ({ connectedWorkspaces }) => connectedWorkspaces.length > 0
      )
    ) {
      objectLink = `${routes.workspaceMeetingRecording({
        workspaceId: selectedWorkspace,
        recordingId: crmObject?.properties?.meetingRecording?.id,
      })}#clips.${crmObject?.objectId}`
    } else {
      objectLink = `${routes.reviewMeeting({
        id: crmObject?.properties?.meetingRecording?.id,
      })}#clips.${crmObject?.objectId}`
    }
  }
  /*if (crmObject?.objectType === NativeObjectTypes.Page) {
    objectLink = routes.note({ id: crmObject?.objectId })
  }*/
  let icon = <></>
  if (crmObject?.objectType === NativeObjectTypes.Contact) {
    const typeObject = getRelationshipSummaryTypeMetadataFromKey(
      crmObject?.properties?.['relationshipSummary/type']
    )
    icon = typeObject?.icon
  }

  return (
    <Box sx={{ position: 'sticky' }}>
      <Box
        sx={{
          height: '64px',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            color: (theme) => theme.palette.secondary.contrastText,
            background: (theme) => theme.palette.secondary.main,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'left',
            borderRadius: '4px',
            fontWeight: 600,
            ml: 2,
            py: '4px',
            px: '4px',
          }}
        >
          <Box
            sx={{
              '& .remixicon': {
                height: '14px',
              },
            }}
          >
            {crmObject?.objectType === NativeObjectTypes.Contact &&
              (icon || <RiContactsLine />)}
            {crmObject?.objectType === NativeObjectTypes.Organization && (
              <RiBuilding2Line />
            )}
            {crmObject?.objectType === NativeObjectTypes.Opportunity && (
              <>
                {crmObject?.properties.domain ? (
                  <Box sx={{ mr: 1, ml: '3px', my: '4px' }}>
                    <DomainAvatar
                      domain={crmObject?.properties.domain}
                      photoUrl={crmObject?.properties.photoSquare}
                      size={24}
                      borderRadius={2}
                    />
                  </Box>
                ) : (
                  <RiBarChartFill />
                )}
              </>
            )}
            {crmObject?.objectType === NativeObjectTypes.MeetingRecording && (
              <OndemandVideoOutlined style={{ height: '14px' }} />
            )}
            {crmObject?.objectType ===
              NativeObjectTypes.MeetingRecordingClip && (
              <TheatersOutlined sx={{ height: '14px' }} />
            )}
            {crmObject?.objectType === NativeObjectTypes.Page && (
              <Notes style={{ height: '14px' }} />
            )}
          </Box>
          <Box
            sx={{
              ml: '2px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '0.8rem',
                mr: '4px',
              }}
            >
              {crmObject?.objectType === NativeObjectTypes.Contact && (
                <>
                  {crmObject?.properties?.['relationshipSummary/type'] ? (
                    <Tooltip
                      title={
                        <>
                          {
                            crmObject?.properties?.[
                              'relationshipSummary/typeReasoning'
                            ]
                          }
                          <SlackSupport
                            size={14}
                            label={null}
                            defaultMessage={`Hi, I see that my contact (${crmObject.objectId}) is labeled for me as ${crmObject?.properties?.['relationshipSummary/type']} but I don't think this is quite right. Could we discuss?`}
                            sx={{
                              '& .remixicon': {
                                color: (theme) =>
                                  theme.palette.primary.contrastText,
                              },
                            }}
                          />
                        </>
                      }
                      arrow={true}
                      placement="left"
                    >
                      <span>
                        {getRelationshipSummaryTypeLabel(
                          crmObject,
                          null,
                          'labels',
                          user.email
                        ) || 'Person'}
                      </span>
                    </Tooltip>
                  ) : (
                    'Person'
                  )}
                </>
              )}
              {crmObject?.objectType === NativeObjectTypes.Organization &&
                'Organization'}
              {crmObject?.objectType === NativeObjectTypes.Opportunity &&
                'Opportunity'}
              {crmObject?.objectType === NativeObjectTypes.MeetingRecording &&
                'Meeting Recording'}
              {crmObject?.objectType ===
                NativeObjectTypes.MeetingRecordingClip && 'Clip'}
              {crmObject?.objectType === NativeObjectTypes.Page && 'Page'}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          {objectLink && (
            <Button
              size="small"
              component="a"
              href={objectLink}
              sx={{
                minWidth: '0px',
                height: '64px',
                borderRadius: '0px !important',
                py: 0,
                px: 1,
              }}
            >
              more
            </Button>
          )}
          <IconButton
            sx={{
              borderRadius: '4px',
              mr: 1,
            }}
            onClick={() => {
              setOpen(false)
              setSidebarObject(null)
            }}
          >
            <RiArrowRightSLine size={24} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

export default SidebarAdminHeader
