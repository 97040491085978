import { useContext, useState } from 'react'

import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import {
  RiGroupFill,
  RiGroupLine,
  RiLinkedinBoxFill,
  RiLockFill,
  RiLockLine,
  RiShieldUserFill,
  RiShieldUserLine,
  RiUserUnfollowLine,
} from '@remixicon/react'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'

import { useMutation, useQuery } from '@redwoodjs/web'

import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import RelationshipTypeChip from 'src/components/Relationships/RelationshipTypeChip/RelationshipTypeChip'
import Row from 'src/components/Row/Row'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

import PersonEnrichmentButton from '../PersonEnrichmentButton/PersonEnrichmentButton'

const GET_PERSON_ENRICHMENT_REVIEW = gql`
  query GetPersonEnrichmentReview($email: String!, $workspaceId: String!) {
    personEnrichmentReview(email: $email, workspaceId: $workspaceId) {
      person {
        email
        firstName
        lastName
        fullName
        currentCompanyName
        currentJobTitle
        photoUrl
        linkedInUrl
        relationship {
          long
          short
          oneSentence
          type
        }
      }
      personEnriched {
        fullName
        currentCompanyName
        currentJobTitle
        photoUrl
      }
      caches
      workspace {
        id
        name
        domains {
          domain
        }
      }
    }
  }
`

const UPDATE_PERSON = gql`
  mutation UpdatePerson($input: UpdatePersonInput!) {
    updatePersonByUser(input: $input)
  }
`

const EXCLUDE_VENDOR_CACHE = gql`
  mutation ExcludeVendorCache($key: String!, $source: String!) {
    excludeVendorCache(key: $key, source: $source)
  }
`

const fieldLabels = {
  firstName: 'First Name',
  lastName: 'Last Name',
  currentCompanyName: 'Current Organization',
  currentJobTitle: 'Current Title',
  photoUrl: 'Photo',
  linkedInUrl: 'LinkedIn Profile',
}

const vendorColors = {
  target: '#FFB3BA', // Light Pink
  rockies: '#BAFFC9', // Light Green
  uno: '#BAE1FF', // Light Blue
  columbia: '#FFFFBA', // Light Yellow
  spiral: '#FFDFBA', // Light Peach
  emperor: '#E6BAFF', // Light Purple
  pineapple: '#FFF0BA', // Light Gold
}

const PersonEnrichmentReview = ({
  email,
  onClose,
}: {
  email: string
  onClose: () => void
}) => {
  const confirm = useConfirm()
  const { selectedWorkspace, userCoreContact } = useContext(DayContext)

  const [tab, setTab] = useState<'workspace' | 'user' | 'caches'>('workspace')
  const [workspacePerson, setWorkspacePerson] = useState(null)

  const {
    data: enrichmentData,
    loading: enrichmentLoading,
    refetch,
  } = useQuery(GET_PERSON_ENRICHMENT_REVIEW, {
    variables: { email, workspaceId: selectedWorkspace },
    skip: !selectedWorkspace || !email,
    pollInterval: 3000,
  })

  const [updatePerson, { loading: updatePersonLoading }] =
    useMutation(UPDATE_PERSON)
  const [excludeVendorCache, { loading: excludeVendorCacheLoading }] =
    useMutation(EXCLUDE_VENDOR_CACHE)

  const handleUpdatePerson = () => {
    logger.dev({ workspacePerson })
    toast.promise(
      updatePerson({
        variables: {
          input: {
            workspaceId: selectedWorkspace,
            email,
            data: workspacePerson,
          },
        },
      }),
      {
        loading: 'Saving...',
        success: () => {
          refetch()
          return 'Saved!'
        },
        error: 'Error saving',
      }
    )
  }

  const data = enrichmentData?.personEnrichmentReview
  logger.dev({ data })

  if (data && data?.person?.email !== workspacePerson?.email) {
    setWorkspacePerson(data?.person)
  }

  const handleMarkIncorrect = async (key: string, source: string) => {
    try {
      await confirm({
        title: 'Mark as wrong person',
        description: 'Are you sure this is a different person?',
      })
      await toast.promise(
        excludeVendorCache({
          variables: { key, source },
        }),
        {
          loading: 'Marking as incorrect...',
          success: () => {
            refetch()
            return 'Marked as incorrect!'
          },
          error: 'Error marking as incorrect',
        }
      )
    } catch (error) {
      logger.warn('Error marking incorrect: ' + error)
    }
  }

  return (
    email && (
      <Dialog
        open={true}
        onClose={onClose}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent sx={{ height: '70vh' }}>
          {data ? (
            <>
              <Row sx={{ justifyContent: 'space-between' }}>
                <Row gap={2}>
                  <ContactAvatar
                    email={email}
                    size={64}
                  />
                  <Box>
                    <Typography variant="h2">
                      {data?.personEnriched?.fullName}
                    </Typography>
                    <Typography variant="h4">{email}</Typography>
                  </Box>
                </Row>
                <Tabs
                  value={tab}
                  onChange={(e, value) => setTab(value)}
                  sx={{
                    justifyContent: 'space-between',
                    pt: 0,
                    '& .MuiTab-root': {
                      minHeight: '24px',
                      pt: 0,
                    },
                  }}
                >
                  <Tab
                    label="Enrichment Data"
                    value="caches"
                    icon={
                      tab === 'caches' ? (
                        <RiShieldUserFill size={16} />
                      ) : (
                        <RiShieldUserLine size={16} />
                      )
                    }
                    iconPosition="start"
                  />
                  <Tab
                    label={`Workspace`}
                    value="workspace"
                    icon={
                      tab === 'workspace' ? (
                        <RiGroupFill size={16} />
                      ) : (
                        <RiGroupLine size={16} />
                      )
                    }
                    iconPosition="start"
                  />
                  <Tab
                    label={`Private to you`}
                    value="user"
                    icon={
                      tab === 'user' ? (
                        <RiLockFill size={16} />
                      ) : (
                        <RiLockLine size={16} />
                      )
                    }
                    iconPosition="start"
                  />
                </Tabs>
              </Row>
              {tab === 'caches' && (
                <Box sx={{ mt: 2 }}>
                  <Alert
                    severity="info"
                    sx={{
                      mb: 2,
                      lineHeight: '150%',
                      color: (theme) => theme.palette.secondary.dark,
                    }}
                    icon={
                      <RiShieldUserFill
                        size={24}
                        style={{ marginTop: '2px' }}
                      />
                    }
                    action={
                      <PersonEnrichmentButton
                        email={email}
                        label={'Re-request enrichment'}
                      />
                    }
                  >
                    This data is from the Day.ai global enrichment service.
                    Sometimes one or more data sources Day.ai uses incorrectly
                    map data to a Person, and in these cases you can mark those
                    as incorrect and help improve the service for all users.
                  </Alert>
                  {enrichmentLoading &&
                    Object.keys(data?.caches ?? {}).length === 0 && (
                      <CircularProgress />
                    )}
                  {Object.keys(data?.caches)
                    .filter((cacheKey) => {
                      const entry = data?.caches[cacheKey]
                      const disallowed = ['emperor', 'pineapple']
                      return (
                        !disallowed.includes(cacheKey) &&
                        (entry.photoUrl ||
                          entry.currentCompanyName ||
                          entry.linkedInUrl ||
                          entry.firstName ||
                          entry.lastName)
                      )
                    })
                    ?.map((cacheKey) => (
                      <Row
                        key={`cache-${cacheKey}-${email}`}
                        sx={{
                          p: 3,
                          border: (theme) =>
                            `1px solid ${theme.palette.divider}`,
                          borderRadius: '8px',
                          mb: 2,
                          justifyContent: 'space-between',
                          borderLeft: `6px solid ${vendorColors[cacheKey]}`,
                        }}
                      >
                        <Row
                          gap={1}
                          sx={{ width: '400px' }}
                        >
                          <Avatar
                            src={data?.caches?.[cacheKey]?.photoUrl}
                            sx={{ width: 48, height: 48 }}
                          />
                          <Box>
                            <Typography variant="h4">
                              {`${data?.caches?.[cacheKey]?.firstName || ''} ${
                                data?.caches?.[cacheKey]?.lastName || ''
                              }`}
                            </Typography>
                            {data?.caches?.[cacheKey]?.currentCompanyName && (
                              <Typography variant="body1">
                                {data?.caches?.[cacheKey]?.currentCompanyName}
                              </Typography>
                            )}
                            {data?.caches?.[cacheKey]?.linkedInUrl && (
                              <Row gap={'4px'}>
                                <RiLinkedinBoxFill
                                  size={16}
                                  style={{ color: '#0077B5' }}
                                />
                                <Box
                                  component="a"
                                  href={data?.caches?.[cacheKey]?.linkedInUrl}
                                  target="_blank"
                                  sx={{ color: '#0077B5', fontSize: '11px' }}
                                >
                                  {data?.caches?.[cacheKey]?.linkedInUrl}
                                </Box>
                              </Row>
                            )}
                          </Box>
                        </Row>
                        <Button
                          startIcon={<RiUserUnfollowLine size={16} />}
                          onClick={() => handleMarkIncorrect(email, cacheKey)}
                          size="small"
                          variant="outlined"
                        >
                          Mark as incorrect
                        </Button>
                      </Row>
                    ))}
                </Box>
              )}
              {tab === 'workspace' && workspacePerson && (
                <Box sx={{ mt: 2 }}>
                  <Alert
                    severity="info"
                    sx={{ mb: 2 }}
                  >
                    This data is private to your workspace and will take
                    precedence in display. Other data that displays is from the
                    Day.ai global enrichment service.
                  </Alert>
                  <Row
                    sx={{ flexWrap: 'wrap' }}
                    gap={2}
                  >
                    {Object.keys(fieldLabels).map((field, index) => (
                      <Box
                        key={`${field}-${index}`}
                        sx={{ mt: 2, width: 'calc(50% - 16px)' }}
                      >
                        <TextField
                          fullWidth={true}
                          label={fieldLabels[field]}
                          value={workspacePerson?.[field] || ''}
                          onChange={(e) =>
                            setWorkspacePerson({
                              ...workspacePerson,
                              [field]: e.target.value,
                            })
                          }
                        />
                      </Box>
                    ))}
                  </Row>
                  <Box sx={{ mt: 3 }}>
                    <Button
                      variant="contained"
                      fullWidth={true}
                      size="large"
                      disableElevation={true}
                      color="primary"
                      onClick={handleUpdatePerson}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              )}
              {tab === 'user' && (
                <Box sx={{ mt: 5 }}>
                  <Row sx={{ justifyContent: 'space-between' }}>
                    <Typography variant="h3">Relationship</Typography>
                    <Box sx={{ width: '212px' }}>
                      <RelationshipTypeChip
                        type={data?.person?.relationship?.type}
                      />
                    </Box>
                  </Row>
                  <Typography
                    variant="h4"
                    sx={{ mt: 2, mb: 1 }}
                  >
                    One Sentence
                  </Typography>
                  <Typography variant="body1">
                    {data?.person?.relationship?.oneSentence}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{ mt: 2, mb: 1 }}
                  >
                    Short
                  </Typography>
                  <Typography variant="body1">
                    {data?.person?.relationship?.short}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{ mt: 2, mb: 1 }}
                  >
                    Long
                  </Typography>
                  <Typography variant="body1">
                    {data?.person?.relationship?.long}
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <CircularProgress />
          )}
        </DialogContent>
      </Dialog>
    )
  )
}

export default PersonEnrichmentReview
