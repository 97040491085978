import { useQuery } from '@apollo/client'
import { Box, Chip, Typography } from '@mui/material'
import {
  RiFileCopyLine,
  RiMailOpenLine,
  RiMailUnreadFill,
} from '@remixicon/react'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'

import { sidebarBodyTextStyle, sidebarHeadingTextStyle } from '../Sidebar'

const GET_THREADS_QUERY = gql`
  query GetContactSidebarThreads($email: String!) {
    threads: recentThreadsForEmail(email: $email) {
      id
      subject
      latestMessageDate
      isUnread
      externalUrl
      summary
    }
  }
`

const SidebarEmailHistory = ({
  email,
  setCount,
}: {
  email: string
  setCount?: React.Dispatch<React.SetStateAction<number>>
}) => {
  const { data, loading, error } = useQuery(GET_THREADS_QUERY, {
    variables: { email },
    onCompleted: (data) => {
      setCount(data.threads.length)
    },
  })

  return (
    <Box sx={{ height: '100%' }}>
      <Chip
        label={email}
        icon={<RiFileCopyLine size={16} />}
        clickable={true}
        variant="outlined"
        onClick={() => {
          // copy to clipboard and toast:
          navigator.clipboard.writeText(email)
          toast(`Email copied to clipboard`, { icon: '📨' })
        }}
        sx={{
          borderRadius: '4px',
          width: '100%',
          px: 3,
          mb: 2,
        }}
      />
      {!(data?.threads?.length > 0) ? (
        <Typography
          sx={{
            ...sidebarBodyTextStyle,
            p: 0,
            m: 0,
          }}
        >
          {loading ? `Looking for recent emails...` : `No email history found`}
        </Typography>
      ) : (
        data &&
        data.threads.map((thread, index) => (
          <Box
            key={thread.id + index}
            component="a"
            href={thread.externalUrl}
            target="_blank"
            sx={{
              color: (theme) => theme.palette.text.primary,
              textDecoration: 'none',
              mb: 2,
              display: 'block',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 1,
                cursor: 'pointer',
                textDecoration: 'none',
              }}
            >
              <Typography
                sx={{
                  ...sidebarHeadingTextStyle,
                  fontWeight: thread.isUnread ? 600 : 400,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'left',
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                {thread.isUnread ? (
                  <RiMailUnreadFill
                    style={{
                      marginRight: '4px',
                      flexShrink: 0,
                    }}
                    size={16}
                  />
                ) : (
                  <RiMailOpenLine
                    style={{
                      marginRight: '4px',
                      flexShrink: 0,
                    }}
                    size={16}
                  />
                )}
                {thread.subject}
              </Typography>
              <Typography
                sx={{
                  ...sidebarHeadingTextStyle,
                  fontWeight: thread.isUnread ? 600 : 400,
                  minWidth: '64px',
                  textAlign: 'right',
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                {thread.latestMessageDate
                  ? dayjs(new Date(thread.latestMessageDate)).format('M/D/YY')
                  : ''}
              </Typography>
            </Box>
            <Box sx={{}}>
              {thread?.summary?.oneSentenceSummary && (
                <Typography sx={sidebarBodyTextStyle}>
                  {thread?.summary?.oneSentenceSummary}
                </Typography>
              )}
              {thread?.summary?.openQuestions?.length > 0 &&
                thread?.summary?.openQuestions.map((question, index) => (
                  <Typography
                    key={`threadQuestion-${index}`}
                    sx={{
                      borderLeft: (theme) =>
                        `2px solid ${theme.palette.divider}`,
                      pl: 1,
                      mt: 1,
                      fontSize: '0.7rem',
                      fontWeight: 500,
                    }}
                  >
                    "{question}"
                  </Typography>
                ))}
            </Box>
          </Box>
        ))
      )}
    </Box>
  )
}

export default SidebarEmailHistory
