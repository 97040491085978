import { AvatarGroup, Avatar, Typography } from '@mui/material'
import Row from 'src/components/Row/Row'

import { Person } from 'types/graphql'

const PeopleChip = ({ people }: { people: Person[] }) => {
  if (!(people?.length > 0)) return null

  const firstFullName = people
    ?.filter(Boolean)
    ?.find((p) => p.fullName)?.fullName
  const label = firstFullName
    ? `${firstFullName} & ${people.length - 1} other${people.length - 1 > 1 ? 's' : ''}`
    : `${people.length} people`
  return (
    <Row sx={{ width: '100%' }}>
      <AvatarGroup
        max={6}
        sx={{
          '& .MuiAvatar-root': {
            width: 20,
            height: 20,
            fontSize: '11px',
          },
        }}
      >
        {people?.filter(Boolean).map((p) => (
          <Avatar
            key={p.id}
            src={p.photoUrl}
          />
        ))}
      </AvatarGroup>
      <Typography
        sx={{
          width: '100%',
          fontWeight: 600,
          fontSize: '11px',
          letterSpacing: '-0.2px',
          lineHeight: '120%',
          overflowY: 'hidden',
          pl: '4px',
          textOverflow: 'ellipsis',
          overflowX: 'hidden',
          whiteSpace: 'nowrap',
          opacity: 0.8,
        }}
      >
        {label}
      </Typography>
    </Row>
  )
}

export default PeopleChip
