import { useContext, useMemo } from 'react'

import { Chip, Typography } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import { extractEmailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'
import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import Row from 'src/components/Row/Row'

const GET_PERSON_FOR_CHIP = gql`
  query GetPersonForChip($email: String!, $workspaceId: String!) {
    getPerson(email: $email, workspaceId: $workspaceId) {
      fullName
      photoUrl
    }
  }
`
const PersonChip = ({ email, showSidebar = false, variant = 'chip' }) => {
  const { selectedWorkspace, setSidebarObject, peopleByEmail } =
    useContext(DayContext)

  const personByEmail = useMemo(
    () => peopleByEmail[email],
    [peopleByEmail, email]
  )

  const { data } = useQuery(GET_PERSON_FOR_CHIP, {
    variables: { email, workspaceId: selectedWorkspace },
    skip:
      true ||
      !selectedWorkspace ||
      !email ||
      (!!personByEmail?.photoUrl && !!personByEmail?.fullName),
  })
  const person = personByEmail || data?.getPerson
  const domain = extractEmailDomain(email)

  if (!email) {
    return null
  }

  return variant === 'chip' ? (
    <Chip
      label={person?.fullName || email}
      clickable={showSidebar}
      onClick={() => {
        if (showSidebar) {
          setSidebarObject({
            objectType: NativeObjectTypes.Person,
            objectId: email,
            properties: { email },
          })
        }
      }}
      avatar={
        <ContactAvatar
          email={email}
          size={18}
          borderRadius={100}
        />
      }
      sx={{
        fontSize: '12px',
        fontWeight: 500,
        height: '22px',
        lineHeight: '130%',
        letterSpacing: '-0.6px',
        color: (theme) => theme.palette.text.primary,
        border: `1px solid rgba(53, 64, 82, 0.20)`,
        background: '#EBECEE',
        borderRadius: '100px',
        p: '2px',
        flexShrink: 1,
      }}
    />
  ) : variant === 'text' ? (
    <Row
      sx={{ alignItems: 'flex-end', height: '18px', mt: '1px' }}
      gap={'6px'}
    >
      {person?.photoUrl ? (
        <ContactAvatar
          email={email}
          size={16}
          borderRadius={100}
        />
      ) : domain ? (
        <DomainAvatar
          domain={domain}
          size={16}
          sx={{
            flexShrink: 0,
          }}
        />
      ) : null}
      <Typography
        sx={{
          fontSize: '11px',
          fontWeight: 600,
          letterSpacing: '-0.2px',
          lineHeight: '18px',
          opacity: 0.8,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {person?.fullName || email}
      </Typography>
    </Row>
  ) : null
}

export default PersonChip
