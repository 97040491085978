const SidebarLayoutPage = () => {
  return (
    <div>
      <h2>{'SidebarLayoutPage'}</h2>
      <p>
        {
          'Find me in ./web/src/components/SidebarLayoutPage/SidebarLayoutPage.tsx'
        }
      </p>
    </div>
  )
}

export default SidebarLayoutPage
