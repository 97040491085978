import { Box } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { getCompanyLogoUrl } from 'src/lib/formatters'
import { logger } from 'src/lib/logger'

import Row from '../Row/Row'

const GET_CORE_COMPANIES_FOR_MASONRY_AVATAR = gql`
  query GetCoreCompaniesForMasonryAvatar($domains: [String!]!) {
    coreCompaniesByDomains(domains: $domains) {
      domain
      photoSquare
      colorVibrant
      colorDarkMuted
      colorLightMuted
      colorLightVibrant
      colorMuted
    }
  }
`

const MasonryAvatar = ({ domains, size = 64, borderRadius = 100 }) => {
  const { data, error, loading } = useQuery(
    GET_CORE_COMPANIES_FOR_MASONRY_AVATAR,
    {
      variables: { domains },
      skip: !domains,
    }
  )

  let valid = []
  if (data?.coreCompaniesByDomains) {
    while (valid.length < 25) {
      valid = valid.concat(data.coreCompaniesByDomains)
    }
    valid = valid.slice(0, 25)
  }

  const howManyUp = data?.coreCompaniesByDomains?.length < 5 ? 2 : 4

  logger.dev({ domains, size, borderRadius, data })
  return (
    <Box
      sx={{
        height: `${size}px`,
        width: `${size}px`,
        borderRadius: `${borderRadius}px`,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          transform: 'rotate(45deg)',
          width: '100%',
          height: '100%',
        }}
      >
        <Row sx={{ width: '100%', height: '100%', flexWrap: 'wrap' }}>
          {valid.map((company, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: company.colorVibrant,
                backgroundImage: `url(${getCompanyLogoUrl({
                  domain: company.domain,
                  photoUrl: company.photoSquare,
                })})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                height: `${100 / howManyUp}%`,
                width: `${100 / howManyUp}%`,
              }}
            />
          ))}
        </Row>
      </Box>
    </Box>
  )
}

export default MasonryAvatar
