import { Node, mergeAttributes } from '@tiptap/core'

const TiptapTitle = Node.create({
  name: 'title',
  group: 'block',
  content: 'inline*',
  defining: true,

  addAttributes() {
    return {
      level: {
        default: 1,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'h1.title',
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    return ['h1', mergeAttributes(HTMLAttributes, { class: 'title' }), 0]
  },
})

export default TiptapTitle
