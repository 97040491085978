import { FooterLogo } from '../common/Icons'

import { footerSocialIcons } from './Helper'
const Footer = () => {
  let currentYear = new Date().getFullYear()
  return (
    <div className="bg-night-black px-3 py-20 text-center">
      <a
        className="inline-block"
        href="/"
        aria-label="home page link"
      >
        <FooterLogo />
      </a>
      <div className="my-12 flex items-center justify-center gap-12">
        {footerSocialIcons.map((obj, index) => {
          return (
            <a
              aria-label="social links"
              className="duration-300 hover:translate-y-[-4px]"
              href={obj.url}
              target="_blank"
              rel="noopener noreferrer"
              key={index}
            >
              {obj.icon}
            </a>
          )
        })}
      </div>
      <div className="flex items-center justify-center gap-6">
        <a
          className="text-sm font-normal tracking-sm text-white duration-300 hover:text-blue"
          href="https://day.ai/privacy"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="path  for trems"
        >
          Privacy Policy
        </a>
        <a
          className="text-sm font-normal tracking-sm text-white duration-300 hover:text-blue"
          href="https://day.ai/terms"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="path  for trems"
        >
          Terms and conditions
        </a>
      </div>
      <p className="mt-12 text-sm font-normal tracking-sm text-white">
        © {currentYear} Day.ai Inc. all rights reserved
      </p>
    </div>
  )
}
export default Footer
