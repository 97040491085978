import { useContext, useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/material'
import { EditorContent, useEditor } from '@tiptap/react'
import { debounce } from 'lodash'

import { useMutation, useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

import SidebarActions from '../Sidebar/SidebarActions/SidebarActions'
import { extensionsMeetingRecordingActionItems } from '../Tiptap/extensions'
import { editorStyle } from '../Tiptap/styles'

const GET_MEETING_RECORDING_ACTION_ITEMS_EDITOR = gql`
  query getMeetingRecordingActionItemsEditor($meetingRecordingId: String!) {
    meetingRecordingActionItemsEditor(meetingRecordingId: $meetingRecordingId) {
      meetingRecordingId
      state
    }
  }
`

const UPDATE_MEETING_RECORDING_ACTION_ITEMS_EDITOR = gql`
  mutation updateMeetingRecordingActionItemsEditor(
    $meetingRecordingId: String!
    $input: UpdateMeetingRecordingActionItemsEditorInput!
  ) {
    updateMeetingRecordingActionItemsEditor(
      meetingRecordingId: $meetingRecordingId
      input: $input
    ) {
      meetingRecordingId
      state
    }
  }
`

const GET_ACTIONS_FOR_MEETING_RECORDING = gql`
  query getActionsForMeetingRecording(
    $workspaceId: String!
    $meetingRecordingId: String!
  ) {
    actionsForMeetingRecording(
      workspaceId: $workspaceId
      meetingRecordingId: $meetingRecordingId
    ) {
      id
      workspaceId
      createdAt
      updatedAt
      title
      reasoning
      type
      priority
      people
      organizations
      opportunityIds
      channel {
        id
        label
        type
        accountId
      }
      status {
        id
        label
        updatedAt
      }
      assignedAt
      owner {
        id
        email
      }
      draft {
        title
        body
      }
      source {
        label
        type
        id
      }
      pipelineType
      timeframe {
        dueDate
        reminderDate
        updatedAt
      }
    }
  }
`

const MeetingRecordingActionItems = ({
  meetingRecordingId,
  isPublic = false,
}) => {
  const { selectedWorkspace: workspaceId } = useContext(DayContext)
  const [errored, setErrored] = useState(false)
  const { data } = useQuery(GET_MEETING_RECORDING_ACTION_ITEMS_EDITOR, {
    variables: { meetingRecordingId },
    skip: !meetingRecordingId || errored,
    onError: () => setErrored(true),
  })

  const [updateMeetingRecordingActionItemsEditor] = useMutation(
    UPDATE_MEETING_RECORDING_ACTION_ITEMS_EDITOR
  )

  const { data: actionsData, refetch } = useQuery(
    GET_ACTIONS_FOR_MEETING_RECORDING,
    {
      variables: { workspaceId, meetingRecordingId },
      skip: !meetingRecordingId || errored || !workspaceId,
      onError: () => setErrored(true),
      onCompleted: ({ actionsForMeetingRecording }) => {
        logger.dev({ actionsForMeetingRecording })
      },
    }
  )

  const actions = actionsData?.actionsForMeetingRecording

  const handleUpdateState = (editor) => {
    if (editor && !editor.isEmpty && data) {
      updateMeetingRecordingActionItemsEditor({
        variables: {
          meetingRecordingId,
          input: {
            state: editor.getJSON(),
          },
        },
      })
    }
  }

  const debouncedUpdateState = useMemo(
    () => debounce((editor) => handleUpdateState(editor), 750),
    [data]
  )

  const editor = useEditor({
    extensions: extensionsMeetingRecordingActionItems,
    autofocus: true,
    editable: !isPublic,
    content: data?.meetingRecordingActionItemsEditor?.state,
    onUpdate: ({ editor }) => debouncedUpdateState(editor),
  })

  useEffect(() => {
    if (editor?.isEmpty) {
      if (data?.meetingRecordingActionItemsEditor?.state)
        editor?.commands.setContent(
          data?.meetingRecordingActionItemsEditor.state
        )
      else {
        logger.dev('adding placeholder state')
        editor?.commands.setContent({
          type: 'doc',
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: 'text',
                  text: 'No action items found.',
                },
              ],
            },
          ],
        })
      }
    }
  }, [data])

  return (
    meetingRecordingId &&
    actionsData && (
      <>
        {actions.length > 0 ? (
          <Box>
            <SidebarActions
              actions={actions}
              onUpdate={refetch}
            />
          </Box>
        ) : (
          <Box
            className="meeting-recording-action-items"
            sx={{
              ...editorStyle,
              '& .tiptap.ProseMirror': {
                '& ul[data-type=taskList]': {
                  ml: 0,
                  '& li': {
                    ml: '6px',
                    '& li': {
                      ml: '8px',
                    },
                  },
                },

                '&  > *': {
                  px: 0,
                },
              },
            }}
          >
            <EditorContent editor={editor} />
          </Box>
        )}
      </>
    )
  )
}

export default MeetingRecordingActionItems
