import { Box, Tooltip } from '@mui/material'
import { RiDoorLockBoxFill, RiDoorLockBoxLine } from '@remixicon/react'

import Row from '../Row/Row'

const PrivateToYou = ({
  tooltipText = 'Your relationship summaries, status and history are always private to you and not visible to others in the workspace.',
  iconSize = 16,
  filled = false,
  sx = {},
}) => {
  return (
    <Box sx={sx}>
      <Tooltip
        title={tooltipText}
        arrow={true}
      >
        <Row>
          {filled ? (
            <RiDoorLockBoxFill size={iconSize} />
          ) : (
            <RiDoorLockBoxLine size={iconSize} />
          )}
        </Row>
      </Tooltip>
    </Box>
  )
}

export default PrivateToYou
