import { useContext } from 'react'

import { Autocomplete, LinearProgress, TextField } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

const GET_PAGES_FOR_FINDER = gql`
  query GetPagesForFinder($workspaceId: String!) {
    pages(workspaceId: $workspaceId) {
      id
      title
      emoji
      madeExternalAt
      publishedForUserAt
      createdAt
      updatedAt
    }
  }
`

const PageFinder = ({ onSelect }) => {
  const { selectedWorkspace } = useContext(DayContext)

  const { data, loading } = useQuery(GET_PAGES_FOR_FINDER, {
    variables: { workspaceId: selectedWorkspace },
    skip: !selectedWorkspace,
  })

  const options =
    data?.pages.map((page) => ({
      id: page.id,
      label: `${page.emoji ? page.emoji + ' ' : ''}${page.title}`,
    })) || []

  logger.dev({ options })

  if (!data && loading)
    return (
      <LinearProgress
        color="secondary"
        sx={{ mt: 1 }}
      />
    )

  return (
    data?.pages &&
    data.pages.length > 0 && (
      <Autocomplete
        options={options}
        onChange={(e, v) => onSelect(v)}
        getOptionKey={(option) => option?.id}
        fullWidth={true}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose Page"
            variant="standard"
          />
        )}
      />
    )
  )
}

export default PageFinder
