import { useCallback, useContext, useMemo, useState } from 'react'

import { Facebook, GitHub, LinkedIn, Twitter } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { DayContext } from 'src/lib/dayContext'

import ContactAvatar from '../../ContactAvatar/ContactAvatar'
import Row from '../../Row/Row'

const GET_CONTACT_BY_EMAIL_FOR_MEETING_TILE = gql`
  query GetContactByEmailForTodayMeetingTile(
    $contactEmail: String!
    $ownerEmail: String!
  ) {
    getContactByEmail(contactEmail: $contactEmail, ownerEmail: $ownerEmail) {
      objectId
      objectType
      properties
    }
  }
`

const socialIconStyle = {
  fontSize: '1.0rem',
  cursor: 'pointer',
}

const TodayPersonTile = ({ email, displayName = '' }) => {
  const { setSidebarObject } = useContext(DayContext)
  const { currentUser: user } = useAuth()
  const [data, setData] = useState(null)
  const contactEmail = useMemo(() => email, [email])
  const ownerEmail = useMemo(() => user?.email, [user?.email])

  useQuery(GET_CONTACT_BY_EMAIL_FOR_MEETING_TILE, {
    variables: { contactEmail, ownerEmail },
    skip: !!data || !contactEmail || !ownerEmail,
    onCompleted: ({ getContactByEmail }) => {
      setData(getContactByEmail)
    },
  })

  const handleSetSidebarObject = useCallback(() => {
    setSidebarObject(data)
  }, [data, setSidebarObject])

  const iconContainerStyle = useMemo(
    () => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      mr: 0.5,
      '&:hover': {
        backgroundColor: (theme) => theme.palette.action.hover,
      },
    }),
    []
  )

  return (
    data && (
      <Row
        onClick={() => handleSetSidebarObject()}
        sx={{
          alignItems: 'start',
          cursor: 'pointer',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
          }}
        >
          <ContactAvatar
            email={email}
            size={48}
            photo_url={data?.properties?.photoUrl}
            borderRadius={50}
            showVerification={!!user}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            width: '100%',
            ml: 2,
          }}
        >
          <Row
            sx={{
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: '0.9rem', mr: 2 }}>
              {data?.properties?.firstName ? (
                <>
                  {data?.properties?.firstName} {data?.properties?.lastName}
                </>
              ) : displayName ? (
                displayName
              ) : (
                email
              )}
            </Typography>
            {data?.properties?.linkedInUrl && (
              <Box
                component="a"
                sx={iconContainerStyle}
                href={data?.properties?.linkedInUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedIn sx={{ ...socialIconStyle, color: '#0077B5' }} />
              </Box>
            )}
            {data?.properties?.twitterUrl && (
              <Box
                component="a"
                sx={iconContainerStyle}
                href={data?.properties?.twitterUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter sx={{ ...socialIconStyle, color: '#1DA1F2' }} />
              </Box>
            )}
            {data?.properties?.facebookUrl && (
              <Box
                component="a"
                sx={iconContainerStyle}
                href={data.getContactByEmail?.properties?.facebookUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook sx={{ ...socialIconStyle, color: '#4267B2' }} />
              </Box>
            )}
            {data?.properties?.githubUrl && (
              <Box
                component="a"
                sx={iconContainerStyle}
                href={data?.properties?.githubUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <GitHub
                  sx={{
                    ...socialIconStyle,
                    fontSize: '1.1rem',
                    color: 'black',
                  }}
                />
              </Box>
            )}
          </Row>
          <Typography
            sx={{
              fontSize: '0.7rem !important',
              height: '36px',
              overflow: 'hidden',
            }}
          >
            {data?.properties['relationshipSummary/short'] ||
              data?.properties?.description}
          </Typography>
        </Box>
      </Row>
    )
  )
}

export default TodayPersonTile
