import { Box, Chip, Typography } from '@mui/material'
import OrganizationTile from '../Organizations/OrganizationTile/OrganizationTile'
import { useContext, useMemo } from 'react'
import OpportunityChip from '../OpportunityChip/OpportunityChip'
import ActionSourceChip from '../Actions/ActionSourceChip/ActionSourceChip'
import ActionStatusChip from '../Actions/ActionStatusChip/ActionStatusChip'
import Row from '../Row/Row'
import ContactTile from '../ContactTile/ContactTile'
import { Action } from 'types/graphql'
import ActionTimeframeChip from '../Actions/ActionTimeframeChip/ActionTimeframeChip'
import { dayjs } from 'src/lib/dayjs'
import { useAuth } from 'src/auth'
import { isInternalUser } from 'src/lib/gates'
import { IconCopy } from '@tabler/icons-react'
import { routes } from '@redwoodjs/router'
import { DayContext } from 'src/lib/dayContext'
import { toast } from 'react-hot-toast'

const InboxSidebar = ({
  selectedAction,
  width,
}: {
  selectedAction: Action
  width: number
}) => {
  const { currentUser } = useAuth()
  const { pipelines } = useContext(DayContext)

  const containerStyle = useMemo(
    () => ({
      width: `${width}px`,
      borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
      height: '100%',
      p: 3,
      '& h3': {
        mb: 1,
      },
      '& .action-sidebar-item-container': {
        mb: 4,
      },
      '& .action-sidebar-item-row': {
        mb: 3,
        justifyContent: 'space-between',
        '& h3': {
          width: '128px',
          mb: 0,
          mr: 3,
        },
      },
    }),
    [width]
  )

  const oppsById = useMemo(() => {
    const oppsMap = {}
    for (const pipeline of pipelines) {
      for (const stage of pipeline.stages) {
        for (const opportunity of stage.opportunities) {
          oppsMap[opportunity.id] = opportunity
        }
      }
    }
    return oppsMap
  }, [pipelines])

  return (
    selectedAction && (
      <Box sx={containerStyle}>
        {selectedAction.timeframe?.dueDate && (
          <Row className="action-sidebar-item-row">
            <Typography variant="h3">Due</Typography>
            <ActionTimeframeChip action={selectedAction} />
          </Row>
        )}
        <Row className="action-sidebar-item-row">
          <Typography variant="h3">Status</Typography>
          <ActionStatusChip status={selectedAction.status} />
        </Row>
        <Row className="action-sidebar-item-row">
          <Typography variant="h3">Source</Typography>
          <ActionSourceChip action={selectedAction} />
        </Row>
        {selectedAction.createdAt && (
          <Row className="action-sidebar-item-row">
            <Typography variant="h3">Created</Typography>
            <Row sx={{ width: '100%' }}>
              <Typography
                sx={{ fontSize: '11px', letterSpacing: '-0.3px', ml: '2px' }}
              >
                {dayjs(selectedAction.createdAt).fromNow()}
              </Typography>
            </Row>
          </Row>
        )}
        <Row className="action-sidebar-item-row">
          <Typography variant="h3">Link</Typography>

          {isInternalUser(currentUser) ? (
            <Chip
              icon={<IconCopy size={14} />}
              label="View in Admin"
              size="small"
              variant="outlined"
              sx={{
                width: '100%',
                border: 'none',
                justifyContent: 'flex-start',
                px: '0px',
                '& .MuiChip-icon': {
                  mr: '0px',
                  ml: 0,
                },
              }}
              onClick={() => {
                const url = routes.adminAction({
                  actionId: selectedAction.id,
                  workspaceId: selectedAction.workspaceId,
                })
                window.open(url, '_blank')
              }}
            />
          ) : (
            <Chip
              icon={<IconCopy size={14} />}
              label="Copy link"
              size="small"
              variant="outlined"
              sx={{
                width: '100%',
                border: 'none',
                justifyContent: 'flex-start',
                px: '0px',
                '& .MuiChip-icon': {
                  mr: '0px',
                  ml: 0,
                },
              }}
              onClick={() => {
                const url = routes.actions({
                  id: selectedAction.id,
                  workspaceId: selectedAction.workspaceId,
                })
                navigator.clipboard
                  .writeText(`${process.env.HOST}${url}`)
                  .then(() => {
                    toast.success('Link copied to clipboard')
                  })
                  .catch(() => {
                    toast.error('Failed to copy link')
                  })
              }}
            />
          )}
        </Row>
        {selectedAction.description && (
          <>
            <Typography variant="h3">Description</Typography>
            <Box className="action-sidebar-item-container">
              <Typography variant="body2">
                {selectedAction.description}
              </Typography>
            </Box>
          </>
        )}
        <Typography variant="h3">People</Typography>
        <Box className="action-sidebar-item-container">
          {selectedAction?.people
            ?.filter((person) => person?.email)
            .map((person, index) => (
              <Box
                sx={{ my: 3 }}
                key={`action-sidebar-person-${index}-${person.email}`}
              >
                <ContactTile
                  email={person.email}
                  size={48}
                />
              </Box>
            ))}
        </Box>
        <Typography variant="h3">Organizations</Typography>
        <Box className="action-sidebar-item-container">
          {selectedAction?.organizations?.map(
            (organization) =>
              organization?.domain && (
                <Box sx={{ my: 3 }}>
                  <OrganizationTile domain={organization?.domain} />
                </Box>
              )
          )}
        </Box>
        <Typography variant="h3">Opportunities</Typography>
        <Box className="action-sidebar-item-container">
          {(selectedAction.opportunityIds?.length ?? 0) === 0 && (
            <Typography variant="body2">No opportunities</Typography>
          )}
          {selectedAction.opportunityIds?.map((opportunityId) => (
            <Box sx={{ my: 1 }}>
              <OpportunityChip
                oppId={opportunityId}
                oppsById={oppsById}
              />
            </Box>
          ))}
        </Box>
        <Typography variant="h3">Reasoning</Typography>
        <Box className="action-sidebar-item-container">
          <Typography variant="body2">{selectedAction.reasoning}</Typography>
        </Box>
      </Box>
    )
  )
}

export default InboxSidebar
