import { useState } from 'react'

import { Box } from '@mui/material'
import { RiDraggable } from '@remixicon/react'
import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react'

import Row from 'src/components/Row/Row'
import TiptapSignupCta from 'src/components/Tiptap/TiptapSignupCta/TiptapSignupCta'
import TodayPersonTile from 'src/components/Today/TodayPersonTile/TodayPersonTile'

import { nodeDragHandleSx, nodeViewContainerSx } from '../styles'

const TiptapNodePersonRenderer = (props) => {
  const [ctaOpen, setCtaOpen] = useState(false)

  return (
    <NodeViewWrapper
      as="div"
      className="day-ai-person"
      draggable={props.editor.isEditable}
      data-drag-handle=""
      onClick={() => {
        if (!props.editor.isEditable && !ctaOpen) {
          setCtaOpen(true)
        }
      }}
    >
      <Row
        sx={nodeViewContainerSx}
        className={`day-ai-node  ${
          props.editor.isEditable ? 'editable' : 'public'
        } ${props.selected ? 'selected' : ''}`}
      >
        <Box
          className="drag-handle"
          sx={nodeDragHandleSx}
        >
          <RiDraggable />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            width: '100%',
          }}
        >
          <TodayPersonTile email={props.node.attrs.email} />
        </Box>
      </Row>
      <TiptapSignupCta
        open={ctaOpen}
        setClosed={() => setCtaOpen(false)}
        text={`See more information about ${props.node.attrs.email}`}
        redirectTo="/login"
      />
    </NodeViewWrapper>
  )
}

const TiptapNodePerson = Node.create({
  name: 'person',
  group: 'block',
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      email: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'day-ai-person',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const personAttributes = mergeAttributes(HTMLAttributes, {
      'data-type': 'person',
    })
    return ['day-ai-person', personAttributes]
  },

  addNodeView() {
    return ReactNodeViewRenderer(TiptapNodePersonRenderer)
  },
})

export default TiptapNodePerson
