import { useRef } from 'react'

import { Box, MenuItem, Select, lighten } from '@mui/material'

import Row from '../Row/Row'

export const tabStyles = {
  '& .MuiTab-root': {
    borderRadius: '6px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    fontSize: '0.7rem',
    py: '2px',
    minHeight: '24px',
    color: (theme) => theme.palette.text.secondary,
    ml: 2,
    '&.Mui-selected': {
      color: (theme) => theme.palette.text.primary,
      border: (theme) => `1px solid ${theme.palette.divider}`,
      borderBottom: (theme) => `1px solid ${lighten(theme.palette.divider, 1)}`,
    },
  },
}

const renderOption = (option) => (
  <Row
    sx={{
      '& .remixicon': {
        mr: 1,
      },
    }}
  >
    {option?.icon && option.icon}
    <Box
      component="span"
      sx={{ fontWeight: 500 }}
    >
      {option?.label}
    </Box>
  </Row>
)

const LibraryTabs = ({ tab, setTab, options }) => {
  const selectRef = useRef(null)

  const handleSetTab = (event) => {
    setTab(event.target.value)
    if (selectRef.current) {
      selectRef.current.blur()
    }
  }

  return (
    <Select
      ref={selectRef}
      size="small"
      value={tab}
      sx={{
        minWidth: '256px',
      }}
      onChange={handleSetTab}
      onClose={() => {
        selectRef.current.classList.remove('Mui-focused')
        selectRef.current.previousSibling?.classList.remove('Mui-focused')
      }}
      renderValue={(value) =>
        renderOption(options.find((option) => option.value === value))
      }
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
        >
          {renderOption(option)}
        </MenuItem>
      ))}
    </Select>
  )
}

export default LibraryTabs
