import { useContext } from 'react'

import { Box, Chip, Typography } from '@mui/material'
import { Organization, OrganizationRole } from 'types/graphql'

import { DayContext } from 'src/lib/dayContext'

import ContactTile from '../ContactTile/ContactTile'
import { possibleRoles } from '../Pipeline/OpportunityRolesList/OpportunityRolesList'
import Row from '../Row/Row'

const Role = ({ role }: { role: OrganizationRole }) => {
  const roleLabels = []
  for (const r of role.role) {
    const roleLabel = possibleRoles.find(
      (pr) => pr.key === r && pr.showInChooser
    )?.label
    if (roleLabel) {
      roleLabels.push(roleLabel)
    }
  }
  return (
    <Row sx={{ justifyContent: 'space-between', my: 3 }}>
      <ContactTile
        email={role.email}
        displayName={role.name}
      />
      <Row gap={1}>
        {roleLabels.map((label) => (
          <Chip
            key={label}
            label={label}
            variant="outlined"
          />
        ))}
      </Row>
    </Row>
  )
}

const OrganizationPeople = ({
  organization,
  showHeader = false,
}: {
  organization: Organization
  showHeader?: boolean
}) => {
  const { workspaces } = useContext(DayContext)
  const workspaceMemberEmails = workspaces
    ?.map((workspace) => workspace.members.map((member) => member.email))
    .flat()

  const roles = organization?.roles?.filter((role) => {
    return role.role.some((r) => {
      return (
        ['PRIMARY_CONTACT', 'CHAMPION', 'ECONOMIC_BUYER'].includes(r) &&
        !workspaceMemberEmails?.includes(role.email)
      )
    })
  })
  return roles?.length > 0 ? (
    <Box>
      {showHeader && <Typography variant="h2">Key People</Typography>}
      {organization.relationship?.origin && (
        <Typography
          variant="body1"
          sx={{ my: 2 }}
        >
          {organization.relationship?.origin}
        </Typography>
      )}
      <Box>
        {roles?.map((role, index) => (
          <Role
            key={`key-person-${role.email}-${index}`}
            role={role}
          />
        ))}
      </Box>
    </Box>
  ) : null
}

export default OrganizationPeople
