import { useContext, useEffect, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  IconButton,
  ListItemText,
  ListItemButton,
  List,
  TextField,
  Typography,
  ListItemIcon,
} from '@mui/material'
import { IconBubbleText, IconBulb, IconX } from '@tabler/icons-react'
import toast from 'react-hot-toast'

import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { extractEmailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes, ObjectTypeMetadata } from 'src/lib/objects'

import { BoxResize } from '../BoxResize/BoxResize'
import ObjectFinder from '../ObjectFinder/ObjectFinder'
import Row from '../Row/Row'
import WorkspaceMemberChip from '../WorkspaceMemberChip/WorkspaceMemberChip'

const GET_ORGANIZATION_FOR_CONTEXT_SIDEBAR = gql`
  query GetOrganizationForContextSidebar(
    $workspaceId: String!
    $orgId: String!
  ) {
    workspaceOrganization(workspaceId: $workspaceId, domain: $orgId) {
      id
      name
      domain
      employeeCount
      about {
        description
        aiDescription
        phoneNumbers
        isHiring
        industryType
        founded
        employeesFrom
        employeesTo
        selling
      }
      headquarters {
        address
        city
        state
        country
        postalCode
        latitude
        longitude
      }
      purpose {
        differentiators
        marketingPromises
        missionAndVision
      }
      people {
        fullName
        email
        currentJobTitle
        photoUrl
      }
      roles {
        name
        email
        role
      }
      opportunities {
        id
        workspaceId
        title
        pipelineId
        pipelineTitle
        stage
        updatedAt
      }
      pages {
        id
        title
      }
      photos {
        square
      }
      relationship {
        upcomingEvents
        quotes {
          personEmail
          text
          meetingId
        }
        oneSentenceSummary
        proofOfPayment
        sensitiveToWhom
        sensitiveReasoning
        warmth
        origin
      }
      links {
        facebook
        x
        instagram
        linkedIn
        website
        websiteResolvedUrl
      }
      lifecycle {
        pipelineType
        stageType
      }
    }
  }
`

const UPDATE_CONTEXT = gql`
  mutation UpdateContext(
    $id: String!
    $input: UpdateWorkspaceUserContextInput!
  ) {
    updateWorkspaceUserContext(id: $id, input: $input) {
      id
    }
  }
`

const CREATE_CONTEXT = gql`
  mutation CreateContext($input: CreateWorkspaceUserContextInput!) {
    createWorkspaceUserContext(input: $input) {
      id
    }
  }
`

const ContextEditSidebar = ({
  onClose,
  onInit,
  title = null,
  context = null,
  onUpdate = () => {},
}: {
  onClose: () => void
  onInit: () => void
  onUpdate: () => void
  title?: string | null
  context: any | null
}) => {
  const { currentUser } = useAuth()
  const { orgsByDomain, workspacePeople, selectedWorkspace } =
    useContext(DayContext)
  const [objectType, setObjectType] = useState<string | null>(null)
  const [objectId, setObjectId] = useState<string | null>(null)
  const [objectTitle, setObjectTitle] = useState<string | null>(null)
  const [contextEdit, setContextEdit] = useState<any | null>(null)
  const [hasEdits, setHasEdits] = useState<boolean>(false)

  const { data } = useQuery(GET_ORGANIZATION_FOR_CONTEXT_SIDEBAR, {
    variables: {
      workspaceId: selectedWorkspace,
      orgId: objectId,
    },
    skip: !objectId || objectType !== NativeObjectTypes.Organization,
  })

  const [updateContext] = useMutation(UPDATE_CONTEXT)

  const isCreator = useMemo(() => {
    return currentUser?.id === contextEdit?.userId || !contextEdit?.userId
  }, [currentUser, contextEdit])

  const handleSave = () => {
    const input = {
      plainTextValue: contextEdit.plainTextValue,
      workspaceId: selectedWorkspace,
      parentReferenceKey: contextEdit.parentReferenceKey,
      //referencedObjectIds: contextEdit.referencedObjectIds,
    }
    toast.promise(updateContext({ variables: { id: contextEdit.id, input } }), {
      loading: 'Saving context...',
      success: () => {
        onUpdate()
        onClose()
        return 'Context saved!'
      },
      error: 'Error saving context',
    })
  }

  const [createContext] = useMutation(CREATE_CONTEXT)

  const handleCreate = () => {
    const referencedObjectIds = contextEdit.referencedObjectIds || []
    if (referencedObjectIds.length === 0) {
      if (objectType === NativeObjectTypes.Person) {
        const domain = extractEmailDomain(objectId)
        if (domain) {
          referencedObjectIds.push(
            `${NativeObjectTypes.Organization} : ${domain} : root`
          )
        }
      }
    }
    const input = {
      parentReferenceKey: contextEdit.parentReferenceKey,
      plainTextValue: contextEdit.plainTextValue.trim(),
      referencedObjectIds,
      workspaceId: selectedWorkspace,
    }
    delete input.new
    toast.promise(createContext({ variables: { input } }), {
      loading: 'Creating context...',
      success: () => {
        onUpdate()
        onClose()
        return 'Context created!'
      },
      error: 'Error creating context',
    })
  }

  const organization = useMemo(() => data?.workspaceOrganization, [data])

  const handleSelectObject = (result) => {
    setObjectType(result.objectType)
    setObjectId(result.objectId)
    setContextEdit({
      id: null,
      plainTextValue: '',
      parentReferenceKey: `${result.objectType} : ${result.objectId} : ${
        result.path || 'root'
      }`,
    })
    const title =
      result?.title || result?.properties?.name || result?.properties?.fullName
    setObjectTitle(title)
  }

  useEffect(() => {
    const [passedObjectType, passedObjectId, passedPath] =
      context?.parentReferenceKey
        ? context.parentReferenceKey.split(' : ')
        : [null, null, null]

    const requiresInitialization =
      !contextEdit ||
      (contextEdit?.id && context?.id && context.id !== contextEdit.id)

    if (requiresInitialization) {
      if (context.new) {
        setContextEdit({
          parentReferenceKey: context.parentReferenceKey,
          plainTextValue: '',
        })
        setHasEdits(false)
      } else if (!contextEdit || contextEdit.id !== context.id) {
        setContextEdit(context)
      }

      if (context && context.parentReferenceKey) {
        if (context.id !== contextEdit?.id || context.new) {
          setObjectType(passedObjectType)
          setObjectId(passedObjectId)
          if (title) {
            setObjectTitle(title)
          } else if (
            passedPath &&
            passedPath != 'root' &&
            passedObjectType === NativeObjectTypes.Workspace
          ) {
            const pathKey = Object.keys(
              ObjectTypeMetadata[passedObjectId].paths
            ).find((key) => {
              return (
                ObjectTypeMetadata[passedObjectId].paths[key].key === passedPath
              )
            })
            setObjectTitle(
              ObjectTypeMetadata[passedObjectId].paths[pathKey].label
            )
          } else {
            if (passedObjectType === NativeObjectTypes.Person) {
              const person = workspacePeople.find(
                (p) => p.email === passedObjectId
              )
              setObjectTitle(person?.fullName || person?.email)
            } else if (passedObjectType === NativeObjectTypes.Organization) {
              setObjectTitle(organization?.name || passedObjectId)
            } else if (passedObjectType === NativeObjectTypes.Opportunity) {
              setObjectTitle('Opportunity')
            } else if (passedObjectType === NativeObjectTypes.Action) {
              setObjectTitle('Action')
            } else if (passedObjectType === NativeObjectTypes.Workspace) {
              setObjectTitle(
                ObjectTypeMetadata[passedObjectId].instructionsLabel
              )
            }
          }
        }
      }
    }
  }, [
    context,
    contextEdit,
    objectType,
    objectId,
    title,
    workspacePeople,
    orgsByDomain,
    objectTitle,
    organization,
    hasEdits,
  ])

  logger.dev({ contextEdit, context })

  return (
    contextEdit && (
      <Box
        sx={{
          position: 'fixed',
          zIndex: 1000,
          background: 'white',
          width: 'calc(680px - 72px)',
          height: '100vh',
          overflowY: 'hidden',
          top: 0,
          right: 0,
          p: 0,
          m: 0,
          boxShadow: `
        0 1px 2px rgba(136, 161, 183, 0.07),
        0 2px 4px rgba(136, 161, 183, 0.07),
        0 4px 8px rgba(136, 161, 183, 0.07),
        0 8px 16px rgba(136, 161, 183, 0.07),
        0 16px 32px rgba(136, 161, 183, 0.07),
        0 32px 64px rgba(136, 161, 183, 0.07)`,
          borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: '12px',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          //transition: 'all 0.4s ease',
        }}
      >
        <Row
          sx={{
            justifyContent: 'space-between',
            background: (theme) => theme.palette.background.default,
            p: 3,
            boxShadow: `
                0 1px 2px rgba(136, 161, 183, 0.04),
                0 2px 4px rgba(136, 161, 183, 0.04),
                0 4px 8px rgba(136, 161, 183, 0.04),
                0 8px 16px rgba(136, 161, 183, 0.04),
                0 16px 32px rgba(136, 161, 183, 0.04),
                0 32px 64px rgba(136, 161, 183, 0.04)`,
            height: '72px',
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            width: '100%',
          }}
        >
          <Row sx={{ width: '100%' }}>
            {objectTitle ? (
              <Box sx={{ width: '100%' }}>
                <Typography
                  sx={{
                    opacity: 0.8,
                    fontSize: '14px',
                    fontWeight: 500,
                    letterSpacing: '-0.7px',
                    lineHeight: '140%',
                    WebkitLineClamp: 2,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    textOverflow: 'ellipsis',
                    wordWrap: 'break-word',
                    overflow: 'hidden',
                    width: '100%',
                    maxHeight: '40px',
                    whiteSpace: 'normal',
                  }}
                >
                  {`Add context to ${
                    objectType === NativeObjectTypes.Workspace
                      ? 'your workspace'
                      : ObjectTypeMetadata[objectType].label
                  }`}
                </Typography>

                <Row gap={'4px'}>
                  {objectType && objectId && (
                    <Box
                      sx={{
                        height: '28px',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        pb: '2px',
                        ml: '-4px',
                        flexShrink: 0,
                      }}
                    >
                      {React.createElement(
                        ObjectTypeMetadata[objectType].icon,
                        { size: 20, stroke: 2.5 }
                      )}
                    </Box>
                  )}
                  <Typography
                    variant="h3"
                    sx={{
                      WebkitLineClamp: 1,
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      textOverflow: 'ellipsis',
                      wordWrap: 'break-word',
                      overflow: 'hidden',
                      lineHeight: '110%',
                    }}
                  >
                    {objectTitle}
                  </Typography>
                </Row>
              </Box>
            ) : (
              <Box>
                <Typography variant="h2">{'Add context'}</Typography>
              </Box>
            )}
          </Row>
          <Row gap={2}>
            {contextEdit.id && !isCreator && (
              <Button
                startIcon={<IconBubbleText size={16} />}
                size="small"
                variant="contained"
                onClick={onInit}
                color="secondary"
              >
                Add more context
              </Button>
            )}
            <IconButton onClick={onClose}>
              <IconX />
            </IconButton>
          </Row>
        </Row>
        {objectType && objectId && (
          <Row
            gap={2}
            sx={{ mx: '12px', height: '64px' }}
          >
            <Box sx={{ opacity: 0.8 }}>
              <IconBulb size={24} />
            </Box>
            <Box sx={{ opacity: 0.9 }}>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '130%',
                  letterSpacing: '-0.42px',
                }}
              >
                Day.ai learns & adapts from this context
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontStyle: 'italic',
                  fontWeight: 400,
                  lineHeight: '130%',
                  letterSpacing: '-0.42px',
                }}
              >
                Add insights, instructions, details and corrections{' '}
                {objectTitle ? `to ${objectTitle}` : ''}
              </Typography>
            </Box>
          </Row>
        )}

        {objectType && objectId ? (
          <Box
            sx={{
              height: `calc(100vh - ${72 + 72 + 64}px)`,
              width: '100%',
              px: '12px',
            }}
          >
            <BoxResize>
              {(dimens) => (
                <TextField
                  {...dimens}
                  placeholder="Add context"
                  multiline={true}
                  fullWidth={true}
                  rows={Math.floor((dimens.height + 4) / 21)}
                  disabled={!isCreator}
                  value={contextEdit.plainTextValue}
                  onChange={(e) => {
                    if (e?.nativeEvent?.inputType === 'insertFromPaste') {
                      logger.dev('paste detected')
                    }
                    setHasEdits(true)
                    setContextEdit({
                      ...contextEdit,
                      plainTextValue: e.target.value,
                    })
                  }}
                />
              )}
            </BoxResize>
            <Row
              gap={1}
              sx={{ height: '72px' }}
            >
              {isCreator ? (
                <Button
                  fullWidth={true}
                  size="large"
                  onClick={() => {
                    if (contextEdit.id) {
                      handleSave()
                    } else {
                      handleCreate()
                    }
                  }}
                  variant="contained"
                  disableElevation={true}
                  disabled={!isCreator || !hasEdits}
                >
                  {contextEdit.id ? 'Save' : 'Add'}
                </Button>
              ) : (
                <>
                  <Typography
                    variant="h5"
                    sx={{ flexShrink: 0 }}
                  >
                    Added {dayjs(contextEdit.createdAt).fromNow()} by{' '}
                  </Typography>
                  <WorkspaceMemberChip userId={contextEdit.userId} />
                </>
              )}
            </Row>
          </Box>
        ) : (
          <Box sx={{ mt: 4 }}>
            <Typography
              variant="h3"
              sx={{ px: 3, mb: 2 }}
            >
              ... to a person, organization, or opportunity:
            </Typography>
            <ObjectFinder
              onSelect={handleSelectObject}
              onChangeQuery={(query) => {
                logger.dev({ query })
              }}
              objectTypes={[
                NativeObjectTypes.Organization,
                NativeObjectTypes.Person,
                NativeObjectTypes.Opportunity,
              ]}
              placeholder="Search ..."
            />
            <Typography
              variant="h3"
              sx={{ px: 3, mt: 2 }}
            >
              ... or, add context to your workspace:
            </Typography>
            <List
              sx={{
                mx: 3,
                '& .MuiListItemButton-root': {
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  borderRadius: '8px',
                  mt: 2,
                },
              }}
            >
              <ListItemButton
                onClick={() => {
                  handleSelectObject({
                    objectType: NativeObjectTypes.Workspace,
                    objectId: NativeObjectTypes.Action,
                    title:
                      ObjectTypeMetadata[NativeObjectTypes.Action]
                        .instructionsLabel,
                  })
                }}
              >
                <ListItemIcon sx={{ minWidth: '24px', mr: 1 }}>
                  {React.createElement(
                    ObjectTypeMetadata[NativeObjectTypes.Action].icon,
                    {
                      size: 20,
                    }
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Add instructions for Action (task) assignment"
                  primaryTypographyProps={{
                    fontWeight: 500,
                    fontSize: '14px',
                    letterSpacing: '-0.42px',
                  }}
                />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  handleSelectObject({
                    objectType: NativeObjectTypes.Workspace,
                    objectId: NativeObjectTypes.Opportunity,
                    path: ObjectTypeMetadata[NativeObjectTypes.Opportunity]
                      .paths.CREATION.key,
                    title:
                      ObjectTypeMetadata[NativeObjectTypes.Opportunity].paths
                        .CREATION.label,
                  })
                }}
              >
                <ListItemIcon sx={{ minWidth: '24px', mr: 1 }}>
                  {React.createElement(
                    ObjectTypeMetadata[NativeObjectTypes.Opportunity].icon,
                    {
                      size: 20,
                    }
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Add instructions for Opportunity Creation"
                  primaryTypographyProps={{
                    fontWeight: 500,
                    fontSize: '14px',
                    letterSpacing: '-0.42px',
                  }}
                />
              </ListItemButton>
            </List>
          </Box>
        )}
      </Box>
    )
  )
}

export default ContextEditSidebar
