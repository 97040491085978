import { useContext } from 'react'

import { Box, FormControl, ListItemText, MenuItem, Select } from '@mui/material'

import { DayContext } from 'src/lib/dayContext'

import ContactAvatar from '../../ContactAvatar/ContactAvatar'
import Row from '../../Row/Row'
import { IconUsers } from '@tabler/icons-react'

const itemStyle = {
  fontSize: '12px',
  fontWeight: 500,
  letterSpacing: '-0.3px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
}

const PipelineOwnerFilter = ({ ownerList, onChange, filters }) => {
  const { peopleByEmail } = useContext(DayContext)

  const getDisplayName = (owner: string): string => {
    const person = peopleByEmail?.[owner]
    return person ? `${person?.fullName}` : owner
  }

  return (
    <FormControl
      sx={{
        borderRadius: '4px',
        height: '32px',
        p: 0,
        width: '180px',
        maxWidth: '180px',
        '& .MuiSelect-select': {
          p: 0,
          borderRadius: '4px',
          height: '30px',
          alignItems: 'center',
          border: (theme) => `1px solid ${theme.palette.divider}`,
        },
      }}
    >
      <Select
        id="owner-select"
        value={filters?.owner || 'allOwners'}
        onChange={onChange}
        variant="standard"
        size="small"
        disableUnderline={true}
        IconComponent={null}
        sx={{ width: '180px', maxWidth: '180px' }}
      >
        <MenuItem
          key="allOwners"
          value="allOwners"
          sx={{ p: 0 }}
        >
          <Row sx={{ height: '30px' }}>
            <Box
              sx={{
                height: '20px',
                width: '20px',
                borderRadius: '2px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mx: 1,
              }}
            >
              <IconUsers size={16} />
            </Box>
            <ListItemText
              primary="All owners"
              primaryTypographyProps={itemStyle}
            />
          </Row>
        </MenuItem>
        {ownerList.map((owner, index) => (
          <MenuItem
            key={index}
            value={owner}
            sx={{ p: 0, width: '100%' }}
          >
            <Row sx={{ height: '30px', width: '100%', px: 1 }}>
              <Box sx={{ mr: 1 }}>
                <ContactAvatar
                  size={20}
                  email={owner}
                  borderRadius={20}
                />
              </Box>
              <ListItemText
                primary={getDisplayName(owner)}
                primaryTypographyProps={itemStyle}
              />
            </Row>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default PipelineOwnerFilter
