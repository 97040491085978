import { useState } from 'react'

import { Box } from '@mui/material'
import { RiDraggable } from '@remixicon/react'
import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react'

import PersonTable from 'src/components/People/PersonTable/PersonTable'
import Row from 'src/components/Row/Row'
import TiptapSignupCta from 'src/components/Tiptap/TiptapSignupCta/TiptapSignupCta'

import { nodeDragHandleSx, nodeViewContainerSx } from '../styles'

const TiptapNodePersonTableRenderer = (props) => {
  const [ctaOpen, setCtaOpen] = useState(false)

  const [hover, setHover] = useState(false)

  const stateString = props.node.attrs.stateJsonString
  const tableState = JSON.parse(stateString) || { init: true }
  const setTableState = (newState) => {
    const newStateString = JSON.stringify(newState)
    props.updateAttributes({
      stateJsonString: newStateString,
    })
  }

  let hoverTimeout = null

  const handleStartHover = () => {
    // setHover to true, clear setTimeout from any handleEndHovers that are pending
    setHover(true)
    clearTimeout(hoverTimeout)
  }

  const handleEndHover = () => {
    // setHover to false on a 2500ms setTimeout that handleStartHover can clear:
    hoverTimeout = setTimeout(() => {
      setHover(false)
    }, 100)
  }

  return (
    <NodeViewWrapper
      as="div"
      className="day-ai-person-table"
      onMouseEnter={handleStartHover}
      onMouseLeave={handleEndHover}
      draggable={props.editor.isEditable}
      {...{ 'data-drag-handle': '' }}
      onClick={(e) => {
        if (!props.editor.isEditable) {
          e.stopPropagation()
          e.preventDefault()
          setCtaOpen(true)
        }
      }}
    >
      <Row
        sx={{
          ...nodeViewContainerSx,
          height: props.editor.isEditable ? '700px' : '120px',
          overflowY: 'auto',
          alignItems: 'top',

          '& .MuiDataGrid-toolbarContainer.data-grid-toolbar': {
            height: hover ? '46px !important' : '0px',
            p: 0,
            transition: 'all 0.3s ease-in-out',
            pb: hover ? '18px' : '0px',
            borderBottom: (theme) =>
              `1px solid ${hover ? theme.palette.divider : 'transparent'}`,
            overflowY: hover ? 'visible' : 'hidden',
            opacity: hover ? 1 : 0,
          },
        }}
        className={`day-ai-node ${
          props.editor.isEditable ? 'editable' : 'public'
        } ${props.selected ? 'selected' : ''}`}
      >
        <Box
          className="drag-handle"
          sx={nodeDragHandleSx}
        >
          <RiDraggable />
        </Box>
        <Box
          sx={{
            m: 0,
            width: '100%',
            height: props.editor.isEditable ? '660px' : '100%',
          }}
        >
          {props.editor.isEditable ? (
            <PersonTable
              tableState={tableState}
              setTableState={setTableState}
            />
          ) : (
            <Row
              sx={{
                justifyContent: 'center',
                height: '100%',
                background: (theme) => theme.palette.divider,
                color: (theme) => theme.palette.text.secondary,
                fontSize: '0.8rem',
              }}
            >
              (Contact table not available in public page view, please log in to
              view & edit table)
            </Row>
          )}
        </Box>
      </Row>

      <TiptapSignupCta
        open={ctaOpen}
        setClosed={() => setCtaOpen(false)}
        text={`See & interact with this contact table`}
        redirectTo="/login"
      />
    </NodeViewWrapper>
  )
}

const TiptapNodePersonTable = Node.create({
  name: 'persontable',
  group: 'block',
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      stateJsonString: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'day-ai-person-table',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const personTableAttributes = mergeAttributes(HTMLAttributes, {
      'data-type': 'persontable',
    })
    return ['day-ai-person-table', personTableAttributes]
  },

  addNodeView() {
    return ReactNodeViewRenderer(TiptapNodePersonTableRenderer)
  },
})

export default TiptapNodePersonTable
