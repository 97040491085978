import { Box } from '@mui/material'

import PipelineForecastChart, {
  PipelineSeriesTimePeriods,
} from '../PipelineForecastChart/PipelineForecastChart'

const PipelineForecast = ({ pipeline, filters }) => {
  if (!filters) filters = {}

  if (!filters['forecastTimePeriod'])
    filters['forecastTimePeriod'] = PipelineSeriesTimePeriods.Month

  //const stages = getMockOpportunities(30)

  // create a copy of pipeline so that it and all its children objects are not read-only:
  const pipelineCopy = JSON.parse(JSON.stringify(pipeline))

  const filteredStages = pipelineCopy.stages.map((stage) => {
    stage.opportunities = stage.opportunities.filter((opportunity) => {
      if (
        filters.owner &&
        filters.owner != 'allOwners' &&
        opportunity.ownerEmail !== filters.owner
      )
        return false
      return true
    })
    return stage
  })
  return (
    <Box sx={{ width: '100%', px: 3, mt: '-36px' }}>
      <PipelineForecastChart
        stages={filteredStages}
        timePeriod={filters['forecastTimePeriod']}
      />
    </Box>
  )
}

export default PipelineForecast
