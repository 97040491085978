import { Facebook, GitHub, LinkedIn, Twitter } from '@mui/icons-material'
import { Avatar, Box, Typography } from '@mui/material'

import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import { extractEmailDomain } from 'src/lib/contactFormatting'

function capitalize(str) {
  if (str && typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  return str
}

const ContactChip = ({
  contactObject,
  fallbackName,
  inSidebar = true,
  rawPhoto = false,
}: {
  contactObject: any
  fallbackName?: string
  inSidebar?: boolean
  rawPhoto?: boolean
}) => {
  const socialIconStyle = {
    cursor: 'pointer',
  }

  const getDisplayName = () => {
    if (contactObject?.properties?.fullName)
      return contactObject?.properties?.fullName

    if (contactObject?.properties?.firstName) {
      return `${contactObject?.properties?.firstName || ''} ${
        contactObject?.properties?.lastName || ''
      }`
    }

    if (fallbackName) {
      return fallbackName
    }

    if (contactObject?.properties?.name) return name

    return capitalize(contactObject?.objectId?.split('@')[0])
  }

  const getDisplayTitle = () => {
    if (contactObject?.properties?.title)
      return contactObject?.properties?.title

    if (contactObject?.properties?.companyName)
      return contactObject?.properties?.companyName

    if (contactObject?.properties?.domain)
      return contactObject?.properties?.domain

    return extractEmailDomain(contactObject?.objectId)
  }

  const displayName = getDisplayName()
  const displayTitle = getDisplayTitle()

  return (
    contactObject?.objectId && (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'start',
            justifyContent: 'space-between',
            p: 2,
            pb: inSidebar ? 1 : 2,
            borderBottom: (theme) =>
              inSidebar ? `1px solid ${theme.palette.divider}` : 'none',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'start',
            }}
          >
            <Box
              sx={{
                width: '64px',
                height: '64px',
                borderRadius: 4,
              }}
            >
              {rawPhoto ? (
                <Avatar
                  src={
                    contactObject?.properties?.photoUrl ||
                    contactObject?.properties?.photo
                  }
                  sx={{ width: '64px', height: '64px', borderRadius: 4 }}
                />
              ) : (
                <ContactAvatar
                  email={contactObject?.objectId}
                  size={64}
                  borderRadius={4}
                  showVerification={true}
                  showCompany={false}
                />
              )}
            </Box>
            <Box sx={{ ml: 2 }}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '1.0rem',
                  lineHeight: '14px',
                  pb: '4px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: 'calc(100% - 64px)',
                  height: '16px',
                }}
              >
                {displayName}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  wordBreak: 'break-all',
                  height: '16px',
                  color: (theme) => theme.palette.text.secondary,
                  fontSize: '0.7rem',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  width: '272px',
                }}
              >
                {displayTitle}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'start',
                  justifyContent: 'left',
                  pt: '10px',
                }}
                onClick={(e) => e.stopPropagation()}
                gap={1}
              >
                {contactObject?.properties?.linkedInUrl && (
                  <Box
                    component="a"
                    href={contactObject?.properties?.linkedInUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedIn sx={{ ...socialIconStyle, color: '#0077B5' }} />
                  </Box>
                )}
                {contactObject?.properties?.twitterUrl && (
                  <Box
                    component="a"
                    href={contactObject?.properties?.twitterUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Twitter sx={{ ...socialIconStyle, color: '#1DA1F2' }} />
                  </Box>
                )}
                {contactObject?.properties?.facebookUrl && (
                  <Box
                    component="a"
                    href={contactObject?.properties?.facebookUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Facebook sx={{ ...socialIconStyle, color: '#4267B2' }} />
                  </Box>
                )}
                {contactObject?.properties?.githubUrl && (
                  <Box
                    component="a"
                    href={contactObject?.properties?.githubUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GitHub
                      sx={{
                        ...socialIconStyle,
                        color: 'black',
                        fontSize: '1.4rem',
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  )
}

export default ContactChip
