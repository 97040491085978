import { useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Tooltip,
} from '@mui/material'
import { RiYoutubeLine } from '@remixicon/react'

const TiptapYouTubeAdder = ({
  tooltipDirection,
  buttonSx,
  iconStyle,
  editor,
}) => {
  const [open, setOpen] = useState(false)
  const [url, setUrl] = useState('')

  const handleAddYouTubeBlock = () => {
    if (url) {
      editor.commands.setYoutubeVideo({
        src: url,
        width: 640,
        height: 480,
      })
      setOpen(false)
    }
  }

  return (
    <Tooltip
      title="Add a YouTube video"
      placement={tooltipDirection}
      arrow={true}
    >
      <Box sx={{ display: 'inline-block' }}>
        <Button
          sx={buttonSx}
          size="small"
          onClick={() => setOpen(true)}
        >
          <RiYoutubeLine style={iconStyle} />
        </Button>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          fullWidth={true}
        >
          <DialogContent>
            <TextField
              label="Enter YouTube URL"
              variant="outlined"
              fullWidth={true}
              autoFocus={true}
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              sx={{ my: 4 }}
            />
            <Button
              onClick={handleAddYouTubeBlock}
              color="primary"
              variant="contained"
              disableElevation={true}
            >
              Add YouTube video
            </Button>
          </DialogContent>
        </Dialog>
      </Box>
    </Tooltip>
  )
}

export default TiptapYouTubeAdder
