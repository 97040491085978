import { ViewList, ViewModule, ViewQuilt } from '@mui/icons-material'
import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  darken,
  lighten,
} from '@mui/material'

const defaultOptions = ['grid', 'list']
const ViewmodeToggle = ({
  viewmode,
  setViewmode,
  options = defaultOptions,
}) => {
  return (
    <ToggleButtonGroup
      value={viewmode}
      exclusive
      onChange={(e, v) => setViewmode(v)}
      sx={{
        '& .MuiToggleButton-root': {
          py: '4px',
          height: '37px',
          width: '40px',
          px: 1,
          color: (theme) => darken(theme.palette.divider, 0.4),
          border: '1px solid',
          borderColor: (theme) => darken(theme.palette.divider, 0.2),
          '& .MuiSvgIcon-root': {
            fontSize: '1.2rem',
          },
          '&.Mui-selected .MuiSvgIcon-root': {
            color: (theme) => lighten(theme.palette.text.secondary, 0.3),
          },
        },
      }}
    >
      {options.includes('hybrid') && (
        <Tooltip
          title="Hybrid view"
          arrow={true}
          placement="top"
        >
          <ToggleButton
            value="hybrid"
            size="small"
          >
            <ViewQuilt />
          </ToggleButton>
        </Tooltip>
      )}
      {options.includes('grid') && (
        <Tooltip
          title="Grid view"
          arrow={true}
          placement="top"
        >
          <ToggleButton
            value="grid"
            size="small"
          >
            <ViewModule />
          </ToggleButton>
        </Tooltip>
      )}
      {options.includes('list') && (
        <Tooltip
          title="List view"
          arrow={true}
          placement="top"
        >
          <ToggleButton value="list">
            <ViewList />
          </ToggleButton>
        </Tooltip>
      )}
    </ToggleButtonGroup>
  )
}

export default ViewmodeToggle
