import { useContext, useMemo } from 'react'

import { Box, Skeleton, Typography } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import DomainAvatar from '../../DomainAvatar/DomainAvatar'
import Row from '../../Row/Row'
import { nodeTitleSx } from '../../Tiptap/styles'

const GET_ORG_FOR_TILE = gql`
  query GetOrgForTile($domain: String!, $workspaceId: String!) {
    workspaceOrganization(domain: $domain, workspaceId: $workspaceId) {
      id
      name
      domain
      description
      aiDescription
      photos {
        id
        square
      }
      colors {
        id
        colorVibrant
        colorDarkMuted
        colorLightMuted
        colorLightMuted
        colorDarkVibrant
        colorLightVibrant
        colorDarkMuted
        colorLightMuted
      }
      about {
        id
        aiDescription
        description
      }
      socialFacebook
      socialTwitter
      socialLinkedIn
    }
  }
`

const OrganizationTile = ({ domain, openSidebar = true }) => {
  const { setSidebarObject, selectedWorkspace, orgsByDomain } =
    useContext(DayContext)
  // useQuery:
  const { data, loading, error } = useQuery(GET_ORG_FOR_TILE, {
    variables: {
      domain,
      workspaceId: selectedWorkspace,
    },
    skip: !domain || !selectedWorkspace,
  })

  const organization = useMemo(() => {
    return data?.workspaceOrganization || orgsByDomain[domain]
  }, [data, orgsByDomain])

  return (
    <Box
      onClick={() => {
        if (openSidebar) {
          setSidebarObject({
            objectType: NativeObjectTypes.Organization,
            objectId: domain,
            properties: organization,
          })
        }
      }}
      sx={{
        cursor: openSidebar ? 'pointer' : 'default',
        mb: 0,
      }}
    >
      {loading && !organization ? (
        <Row>
          <Box sx={{ width: '48px', flexShrink: 0 }}>
            <Skeleton
              animation="wave"
              variant="circular"
              width={48}
              height={48}
            />
          </Box>
          <Box sx={{ width: '100%', ml: 2 }}>
            <Skeleton
              animation="wave"
              height={24}
              width={100}
            />
            <Skeleton
              animation="wave"
              height={16}
              width={100}
            />
          </Box>
        </Row>
      ) : (
        <>
          <Row sx={{ alignItems: 'flex-start' }}>
            <DomainAvatar
              domain={domain}
              photoUrl={organization?.photos?.square}
              companyColor={organization?.colors?.colorVibrant}
              size={48}
              borderRadius={50}
            />
            <Box
              sx={{
                width: `calc(100%)`,
                overflow: 'hidden',
                maxHeight: '64px',
                display: 'block',
                alignItems: 'center',
                boxSizing: 'border-box',
                flexShrink: 1,
                textWrap: 'wrap',
                ml: 2,
              }}
            >
              <Typography
                sx={{ ...nodeTitleSx, fontSize: '16px', lineHeight: '120%' }}
              >
                {organization?.name || domain}
              </Typography>
              <Typography
                sx={{
                  fontSize: '11px',
                  letterSpacing: '-0.18px',
                  fontWeight: 400,
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  lineHeight: '130%',
                  maxHeight: '64px',
                  boxSizing: 'border-box',
                  mt: '2px',
                }}
              >
                {organization?.about?.aiDescription ||
                  organization?.about?.description}
              </Typography>
            </Box>
          </Row>
        </>
      )}
    </Box>
  )
}

export default OrganizationTile
