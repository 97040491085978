import { datadogLogs } from '@datadog/browser-logs'

// should replace most or all of this with dynamic config once available
if (process.env.NODE_ENV === 'production') {
  datadogLogs.init({
    clientToken: 'pub73f434d3eb8940fec2ae5297b7be65f6',
    site: 'us5.datadoghq.com',
    service: 'redwood-client',
    env: 'production',
    forwardConsoleLogs: ['error'],
  })
}
