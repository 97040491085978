import { Table, TableHead, TableRow, TableCell } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useMutation, useQuery } from '@redwoodjs/web'
import toast from 'react-hot-toast'
import BodyContainer from 'src/components/BodyContainer/BodyContainer'
import Row from 'src/components/Row/Row'
import { dayjs } from 'src/lib/dayjs'

const WORKSPACE_ADMIN_OPPORTUNITY_ROLES = gql`
  query WorkspaceAdminOpportunityRoles {
    workspaceAdminOpportunityRoles
  }
`

const FIX_ROLES_FOR_OPPORTUNITY = gql`
  mutation FixRolesForOpportunity(
    $workspaceId: String!
    $opportunityId: String!
  ) {
    fixRolesForOpportunity(
      workspaceId: $workspaceId
      opportunityId: $opportunityId
    )
  }
`

const AdminOppRolesPage = () => {
  const { data, refetch } = useQuery(WORKSPACE_ADMIN_OPPORTUNITY_ROLES)

  const [fixRolesForOpportunity] = useMutation(FIX_ROLES_FOR_OPPORTUNITY, {
    onCompleted: () => refetch(),
  })

  return (
    <BodyContainer>
      <Row sx={{ justifyContent: 'space-between' }}>
        <Typography
          variant="h1"
          sx={{ py: 2 }}
        >
          Opportunity Roles {data?.workspaceAdminOpportunityRoles.length}
        </Typography>
        <Button onClick={() => refetch()}>Refresh</Button>
      </Row>
      <Box sx={{ height: 'calc(100vh - 96px)', overflowY: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>Last Good Roles</TableCell>
              <TableCell>Last Good Roles Created At</TableCell>
              <TableCell>Fix</TableCell>
            </TableRow>
          </TableHead>
          {data?.workspaceAdminOpportunityRoles.map((opp) => (
            <TableRow key={opp.objectId}>
              <TableCell>{opp.objectId}</TableCell>
              <TableCell>
                {opp.createdAt ? dayjs(opp.createdAt).format('MM/DD') : 'none'}
              </TableCell>
              <TableCell>
                {opp.updatedAt ? dayjs(opp.updatedAt).format('MM/DD') : 'none'}
              </TableCell>
              <TableCell>{opp.lastGoodRoles?.length}</TableCell>
              <TableCell>
                {opp.lastGoodRolesCreatedAt
                  ? dayjs(opp.lastGoodRolesCreatedAt).format('MM/DD')
                  : 'none'}
              </TableCell>
              <TableCell>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    toast.promise(
                      fixRolesForOpportunity({
                        variables: {
                          workspaceId: opp.workspaceId,
                          opportunityId: opp.objectId,
                        },
                      }),
                      {
                        loading: 'Fixing...',
                        success: () => {
                          refetch()
                          return 'Fixed!'
                        },
                        error: 'Error fixing',
                      }
                    )
                  }
                >
                  Fix
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Box>
    </BodyContainer>
  )
}

export default AdminOppRolesPage
