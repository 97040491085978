import { useContext, useMemo } from 'react'

import { Chip } from '@mui/material'
import { Opportunity } from 'types/graphql'

import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import DomainAvatar from '../DomainAvatar/DomainAvatar'
import { logger } from 'src/lib/logger'

const OpportunityChip = ({
  opportunity,
  oppId,
  oppsById = {},
  size = 'small',
  width = '192px',
}: {
  opportunity?: Opportunity
  oppId?: string
  oppsById?: Record<string, Opportunity>
  size?: 'small' | 'medium'
  width?: string
}) => {
  const { setSidebarObject } = useContext(DayContext)
  const opp = useMemo(() => opportunity || oppsById[oppId], [oppId])
  if (!opp) {
    logger.info(`Opportunity not found for ${oppId}`)
    return null
  }

  const revenue = opp.expectedRevenue
    ? ` - $${opp.expectedRevenue.toLocaleString()}`
    : ''
  const icon = opp.domain ? (
    <DomainAvatar
      domain={opp.domain}
      size={size === 'small' ? 16 : 18}
    />
  ) : null
  return (
    opp && (
      <Chip
        label={`${opp.title}${revenue}`}
        size={size}
        variant="outlined"
        avatar={icon}
        onClick={(e) => {
          e.stopPropagation()
          setSidebarObject({
            objectType: NativeObjectTypes.Opportunity,
            objectId: opp.id,
            properties: opp,
          })
        }}
        sx={{
          maxWidth: '264px',
          width: width,
          fontWeight: 600,
          letterSpacing: '-0.2px',
          justifyContent: 'flex-start',
          pl: '4px',
        }}
      />
    )
  )
}

export default OpportunityChip
