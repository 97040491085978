import { Box, Typography } from '@mui/material'

import ContactTile from '../../ContactTile/ContactTile'

const OrganizationQuotes = ({ org }) => {
  return (
    org?.overview?.['quotes']?.length > 0 && (
      <>
        <Typography variant="h2">In Their Words</Typography>

        <Box sx={{ mt: 1 }}>
          {org?.overview?.['quotes']?.map((item, index) => (
            <Box
              key={`quote_${index}`}
              sx={{
                fontSize: '14px',
                lineHeight: '180%',
                fontWeight: 400,
                mb: 5,
              }}
            >
              <Box
                sx={{
                  p: 3,
                  mb: 2,
                  borderRadius: '8px',
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  background: (theme) => theme.palette.background.paper,
                }}
              >{`${item.text}`}</Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <Box sx={{ width: '264px', pr: 3 }}>
                  <ContactTile email={item.email} />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </>
    )
  )
}

export default OrganizationQuotes
