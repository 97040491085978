import { Box } from '@mui/material'

import SlackChannels from 'src/components/Slack/SlackChannels/SlackChannels'

const PersonSlack = ({
  slack,
  setCount,
}: {
  slack: any
  setCount?: React.Dispatch<React.SetStateAction<number>>
}) => {
  setCount(slack.channels.length)
  return (
    <Box>
      <SlackChannels channels={slack.channels} />
    </Box>
  )
}

export default PersonSlack
