import { useContext } from 'react'

import { Box, Typography } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'

import SidebarActions from '../../Sidebar/SidebarActions/SidebarActions'
import { logger } from 'src/lib/logger'

const GET_ACTIONS_FOR_ORGANIZATION_SIDEBAR = gql`
  query GetActionsForOpportunitySidebar(
    $opportunityId: String!
    $workspaceId: String!
  ) {
    actionsForOpportunity(
      opportunityId: $opportunityId
      workspaceId: $workspaceId
    ) {
      id
      workspaceId
      createdAt
      updatedAt
      title
      description
      reasoning
      type
      priority
      people
      organizations
      opportunityIds

      channel {
        id
        label
        type
        accountId
      }
      status {
        id
        label
        updatedAt
      }
      assignedAt
      owner {
        id
        email
      }
      draft {
        title
        body
      }
      source {
        label
        type
        id
      }
      pipelineType
      timeframe {
        dueDate
        reminderDate
        updatedAt
      }
      opportunity
    }
  }
`

const OpportunityActions = ({
  opportunityId,
  actions,
}: {
  opportunityId: string
  actions: any[]
}) => {
  const { selectedWorkspace } = useContext(DayContext)
  const { data, refetch, loading } = useQuery(
    GET_ACTIONS_FOR_ORGANIZATION_SIDEBAR,
    {
      variables: {
        opportunityId,
        workspaceId: selectedWorkspace,
      },
      skip: !selectedWorkspace || !opportunityId || actions?.length > 0,
    }
  )

  const finalActions = data?.actionsForOpportunity

  logger.dev({ finalActions })

  return Array.isArray(finalActions) && finalActions?.length > 0 ? (
    <Box sx={{ mt: 0 }}>
      <Typography variant="h2">Actions</Typography>
      <SidebarActions
        actions={finalActions}
        onUpdate={refetch}
        opportunityId={opportunityId}
      />
    </Box>
  ) : null
}

export default OpportunityActions
