import { Box, Card, Typography } from '@mui/material'
import Row from 'src/components/Row/Row'
import dayjs from 'dayjs'
import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import { useContext } from 'react'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'
import ContactTile from 'src/components/ContactTile/ContactTile'

const PipelineList = ({ opportunities }) => {
  const { setSidebarObject } = useContext(DayContext)
  const recentUpdates = [...opportunities]
    .sort((a, b) => {
      return dayjs(b.updatedAt).unix() - dayjs(a.updatedAt).unix()
    })
    .slice(0, 100)
    .map((opportunity) => {
      return {
        oppId: opportunity.id,
        oppTitle: opportunity.title,
        oppType: opportunity.type,
        currentStatus: opportunity.currentStatus,
        stageTitle: opportunity.stageTitle,
        stagePosition: opportunity.stagePosition,
        updatedAt: opportunity.updatedAt,
        ownerEmail: opportunity.ownerEmail,
        domain: opportunity.domain,
      }
    })

  const recentUpdatesGroupedByDay = recentUpdates.reduce((acc, update) => {
    const date = dayjs(update.updatedAt).format('YYYY-MM-DD')
    if (!acc[date]) {
      acc[date] = []
    }
    acc[date].push(update)
    return acc
  }, {})

  const openOppInSidebar = (oppId) => {
    const opportunity = opportunities.find((opp) => opp.id === oppId)
    const crmObject = {
      objectType: NativeObjectTypes.Opportunity,
      objectId: opportunity.id,
      properties: opportunity,
    }
    setSidebarObject(crmObject)
  }

  return (
    <Box sx={{ p: 3, pt: 0, width: '100%', height: '100%' }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Row
          sx={{
            fontWeight: 600,
            fontSize: '1.3rem',
            mb: 2,
            height: '64px',
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          Activity feed
        </Row>
        <Box sx={{ height: 'calc(100vh - 128px)', overflowY: 'auto' }}>
          {Object.keys(recentUpdatesGroupedByDay).map((date, dayIndex) => (
            <Box key={`update-day${dayIndex}`}>
              {recentUpdates.map((update, index) => (
                <Row
                  key={`update-${index}`}
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    mb: '6px',
                    mt: index === 0 && dayIndex > 0 ? '64px' : 0,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    openOppInSidebar(update.oppId)
                  }}
                >
                  <Box sx={{ width: '64px', flexShrink: 0, pt: 1 }}>
                    {index === 0 && (
                      <Row
                        sx={{
                          height: '132px',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '0.65rem',
                            width: '100%',
                            textAlign: 'center',
                            height: '4px',
                          }}
                        >
                          {`${dayjs(update.updatedAt).format('ddd')}`}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: '40px',
                            width: '100%',
                            textAlign: 'center',
                            height: '46px',
                          }}
                        >
                          {`${dayjs(update.updatedAt).format('d')}`}
                        </Typography>

                        <Typography
                          sx={{
                            fontWeight: 500,
                            width: '100%',
                            fontSize: '0.75rem',
                            textAlign: 'center',
                            mb: '14px',
                          }}
                        >
                          {`${dayjs(update.updatedAt).format('MMM')} '${dayjs(
                            update.updatedAt
                          ).format('YY')}`}
                        </Typography>
                      </Row>
                    )}
                  </Box>
                  <Row
                    sx={{
                      width: '48px',
                      height: '132px',
                      flexShrink: 0,
                      ml: 3,
                      mr: '-24px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Box
                      sx={{
                        height: '32px',
                        width: '32px',
                        p: '6px',
                        background: (theme) => theme.palette.background.paper,
                        border: (theme) => `1px solid ${theme.palette.divider}`,
                        borderRadius: '3px',
                        zIndex: 1,
                      }}
                    >
                      <DomainAvatar
                        domain={update.domain}
                        size={18}
                        borderRadius={2}
                      />
                    </Box>
                  </Row>
                  <Card
                    sx={{
                      boxShadow: 'none',
                      px: 6,
                      flexGrow: 1,
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <Row
                      sx={{
                        justifyContent: 'space-between',
                        flexGrow: 1,
                        alignItems: 'flex-start',
                      }}
                    >
                      <Box
                        sx={{
                          fontWeight: 600,
                          fontSize: '1.4rem',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          width: '100%',
                        }}
                      >
                        {update.oppTitle}
                      </Box>

                      <Row sx={{}}>
                        <ContactTile
                          size={24}
                          email={update.ownerEmail}
                        />
                      </Row>
                    </Row>

                    <Typography
                      sx={{
                        fontWeight: 500,
                        width: '100%',
                        fontSize: '0.8rem',
                        color: (theme) => theme.palette.action.disabled,
                      }}
                    >
                      {`${dayjs(update.updatedAt).format('h:mm A')}`}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '0.9rem',
                        mt: 1,
                        flexGrow: 0,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        width: '100%',
                      }}
                    >
                      {update.currentStatus}
                    </Typography>
                    {/*<Typography
                  sx={{
                    fontWeight: 400,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    fontSize: '0.8rem',
                    py: '4px',
                    px: 1,
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    color: (theme) => theme.palette.text.secondary,
                    borderRadius: '4px',
                  }}
                >
                  {update.oppType}
                </Typography>
                <Box sx={{ ...getStageStyle(update.stagePosition), mb: 1 }}>
                  {update.stageTitle}
                </Box>*/}
                  </Card>
                </Row>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default PipelineList
