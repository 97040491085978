import { Box, FormControl, ListItemText, MenuItem, Select } from '@mui/material'
import { RiEqualizer2Line } from '@remixicon/react'

import Row from 'src/components/Row/Row'

import { pipelineFilterWidths } from '../Pipeline'

const PipelineOppTypeFilter = ({ oppTypeList, onChange, filters }) => {
  return (
    <FormControl
      sx={{
        width: pipelineFilterWidths,
        mr: 1,
        borderRadius: '4px',
        height: '32px',
        p: 0,
        '& .MuiSelect-select': {
          p: 0,
          borderRadius: '4px',
          height: '30px',
          alignItems: 'center',
          border: (theme) => `1px solid ${theme.palette.divider}`,
        },

        '& .MuiSvgIcon-root': {
          opacity: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 1,
            xl: 1,
          },
        },
      }}
    >
      <Select
        id="oppType-select"
        value={filters?.oppType || 'allOppTypes'}
        onChange={onChange}
        variant="standard"
        size="small"
        disableUnderline={true}
      >
        <MenuItem
          key="allOppTypes"
          value="allOppTypes"
          sx={{ px: 0 }}
        >
          <Row sx={{ height: '30px' }}>
            <Box
              sx={{
                height: '20px',
                width: '20px',
                borderRadius: '2px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mx: 1,
                '& .remixicon': {
                  color: (theme) => theme.palette.text.secondary,
                  height: '16px',
                  width: '16px',
                },
              }}
            >
              <RiEqualizer2Line />
            </Box>
            <ListItemText primary="All opp types" />
          </Row>
        </MenuItem>
        {oppTypeList?.filter(Boolean)?.map((oppType, index) => (
          <MenuItem
            key={index}
            value={oppType}
            sx={{ px: 0 }}
          >
            <Row sx={{ height: '30px' }}>
              <Box
                sx={{
                  height: '20px',
                  width: '20px',
                  borderRadius: '2px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mx: 1,
                  '& .remixicon': {
                    color: (theme) => theme.palette.text.secondary,
                    height: '16px',
                    width: '16px',
                    display: oppType === filters?.oppType ? 'flex' : 'none',
                  },
                }}
              >
                <RiEqualizer2Line />
              </Box>
              <ListItemText
                primary={oppType}
                primaryTypographyProps={{
                  noWrap: true,
                  sx: {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  },
                }}
              />
            </Row>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default PipelineOppTypeFilter
