import { useLocation } from '@redwoodjs/router'

import { ArrowLeftIcon } from '../common/Icons'

const CtaButton = ({ title, url, className, icon }) => {
  const location = useLocation()

  const preDefinedClasses =
    'px-3.5 py-2 sm:py-3 !text-[clamp(14px,3.8961038961vw,16px)] hover:border-blue border border-transparent bg-night-black rounded-3xl flex items-center text-white tracking-sm sm:tracking-md leading-175 font-semibold  transition-all duration-300 ease-linear hover:bg-white hover:text-blue group shadow-[0px_1.5px_0px_0px_#777672] hover:shadow-[0px_0px_30px_-10px_#777672] group'
  return (
    <>
      {url ? (
        <a
          href={`${url}${location.search}`}
          className={`${preDefinedClasses} ${className && className}`}
        >
          {title}
          {icon ? (
            icon
          ) : (
            <ArrowLeftIcon className="-mb-1 ms-2 h-6 w-6 transition-all duration-300 group-hover:translate-x-1" />
          )}
        </a>
      ) : (
        <button className={`${preDefinedClasses} ${className && className}`}>
          {title}
          {icon ? (
            icon
          ) : (
            <ArrowLeftIcon className="-mb-1 ms-2 h-6 w-6 transition-all duration-300 group-hover:translate-x-1" />
          )}
        </button>
      )}
    </>
  )
}

export default CtaButton
