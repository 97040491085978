import { useContext } from 'react'

import { Box, Typography, Chip } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import Row from 'src/components/Row/Row'
import SlackChannels from 'src/components/Slack/SlackChannels/SlackChannels'
import SlackLogo from 'src/components/Slack/SlackLogo/SlackLogo'
import { DayContext } from 'src/lib/dayContext'

const GET_SLACK_CHANNELS_FOR_SIDEBAR = gql`
  query GetSlackChannelsForSidebar($workspaceId: String!, $orgId: String!) {
    slackChannels(workspaceId: $workspaceId, orgId: $orgId) {
      id
      name
      summary
      slackChannelId
      slackIntegration {
        slackTeamId
      }
    }
  }
`

const SidebarSlack = ({ orgId, sx = {} }) => {
  const { selectedWorkspace, workAccounts, workspacePeople } =
    useContext(DayContext)

  const { data: slackChannelsData, loading } = useQuery(
    GET_SLACK_CHANNELS_FOR_SIDEBAR,
    {
      variables: {
        workspaceId: selectedWorkspace,
        orgId,
      },
      skip: !selectedWorkspace || !orgId,
    }
  )
  const slackChannels = slackChannelsData?.slackChannels

  return (
    slackChannels &&
    slackChannels?.length > 0 && (
      <Box sx={{ mb: 2, ...sx }}>
        <Row sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h2">Slack</Typography>

          {slackChannels?.length === 1 ? (
            <Chip
              variant="filled"
              key={slackChannels?.[0].id}
              icon={
                <Row sx={{ justifyContent: 'right', width: '20px' }}>
                  <SlackLogo size={14} />
                </Row>
              }
              label={`#${slackChannels?.[0].name}`}
              onClick={() => {
                const url = `slack://channel?id=${slackChannels?.[0].slackChannelId}&team=${slackChannels?.[0]?.slackIntegration?.slackTeamId}`
                window.open(url, '_blank')
              }}
              sx={{
                background: '#703259',
                color: 'white',
                fontWeight: '600',
                fontSize: '12px',
                letterSpacing: '-0.2px',
              }}
            />
          ) : null}
        </Row>
        <SlackChannels
          channels={slackChannels}
          includeTitle={false}
        />
      </Box>
    )
  )
}

export default SidebarSlack
