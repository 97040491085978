import { Button, Tabs } from '@mui/material'
import { Tab } from '@mui/material'
import Row from '../Row/Row'
import { useState } from 'react'
import { GridState } from '@mui/x-data-grid-premium'

interface View {
  id: string
  label: string
  state: GridState
}

const ViewsMenu = ({
  viewsKey,
  defaults,
  onChange,
}: {
  viewsKey: string
  defaults: View[]
  onChange: (view: View) => void
}) => {
  const [selectedView, setSelectedView] = useState('all')
  return (
    <Row
      sx={{
        height: '38px',
        justifyContent: 'space-between',
      }}
    >
      <Tabs
        value={selectedView}
        onChange={(_, value) => {
          setSelectedView(value)
          onChange(defaults.find((view) => view.id === value))
        }}
      >
        {defaults.map((view) => (
          <Tab
            key={`${viewsKey}-${view.id}`}
            value={view.id}
            label={view.label}
          />
        ))}
      </Tabs>
      <Row>
        <Button>Save View</Button>
      </Row>
    </Row>
  )
}

export default ViewsMenu
