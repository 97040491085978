import { useContext, useState } from 'react'

import { Avatar, Box, Card, Chip, Popover, lighten } from '@mui/material'
import { RiAccountCircleLine } from '@remixicon/react'
import { Node, mergeAttributes } from '@tiptap/core'
import { PluginKey } from '@tiptap/pm/state'
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react'
import { SuggestionOptions } from '@tiptap/suggestion'

import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import OrganizationChip from 'src/components/Organizations/OrganizationChip/OrganizationChip'
import RelationshipTypeChip from 'src/components/Relationships/RelationshipTypeChip/RelationshipTypeChip'
import ContactChip from 'src/components/Sidebar/ContactChip/ContactChip'
import {
  NativeObjectFormatters,
  extractEmailDomain,
  isFreemailDomain,
} from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

export interface ObjectChipNodeAttrs {
  objectType: string | null
  objectId: string | null
  displayName: string | null
}

export type ObjectChipOptions<
  SuggestionItem = any,
  Attrs extends Record<string, any> = ObjectChipNodeAttrs,
> = {
  HTMLAttributes: Record<string, any>
  suggestion: Omit<SuggestionOptions<SuggestionItem, Attrs>, 'editor'>
  renderHTML: (props: {
    options: ObjectChipOptions<SuggestionItem, Attrs>
    node: ProseMirrorNode
  }) => DOMOutputSpec
  renderText: (props: {
    options: ObjectChipOptions<SuggestionItem, Attrs>
    node: ProseMirrorNode
  }) => string
}

const GET_CONTACT_BY_EMAIL_FOR_CHIP = gql`
  query GetContactByEmailForChip($contactEmail: String!, $ownerEmail: String!) {
    getContactByEmail(contactEmail: $contactEmail, ownerEmail: $ownerEmail) {
      objectId
      objectType
      properties
    }
  }
`

const renderPlainParents = ['heading', 'title']

const getStyle = ({ type, level }) => {
  const style = {
    height: '30px',
    fontWeight: 600,
    borderRadius: '0px',
    border: 'none',
    borderBottom: (theme) => `1px dashed ${theme.palette.divider}`,
    '& .MuiChip-label': {
      pr: '2px !important',
    },
    '&:hover': {
      background: lighten('#B4D7FF', 0.8),
      borderBottom: (theme) => `1px solid ${theme.palette.text.secondary}`,
    },
    '&.selected': {
      background: lighten('#B4D7FF', 0.8),
      border: `1px solid #B4D7FF !important`,
      color: '#2867B2',
    },
  }

  return style
}

const ObjectChipComponent = (props) => {
  const { node } = props
  const resolvedPos = props.editor.state.doc.resolve(props.getPos())
  const parent = resolvedPos.parent

  const { currentUser } = useAuth()
  const { setSidebarObject } = useContext(DayContext)
  const { objectType, objectId, displayName, photoUrl } = node.attrs

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout | null>(null)
  const [openTimeout, setOpenTimeout] = useState<NodeJS.Timeout | null>(null)

  const rawDomain =
    objectType === NativeObjectTypes.Contact
      ? extractEmailDomain(objectId)
      : objectType === NativeObjectTypes.Organization
        ? objectId
        : null
  const domain = !isFreemailDomain(rawDomain) ? rawDomain : null

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (!props.editor.isEditable) return

    if (closeTimeout) {
      clearTimeout(closeTimeout)
      setCloseTimeout(null)
    }

    if (!openTimeout) {
      const currentTarget = event.currentTarget
      logger.dev({ currentTargetBefore: currentTarget })
      setOpenTimeout(
        setTimeout(() => {
          logger.dev('opening popover')
          logger.dev({ currentTargetAfter: currentTarget })
          setAnchorEl(currentTarget)
        }, 300) // 500ms delay before opening the Popover
      )
    }
  }

  const handlePopoverClose = () => {
    if (!props.editor.isEditable) return

    logger.dev('handlePopoverClose')
    if (openTimeout) {
      clearTimeout(openTimeout)
      setOpenTimeout(null)
    }

    setCloseTimeout(
      setTimeout(() => {
        setAnchorEl(null)
      }, 500)
    ) // 500ms delay before closing the Popover
  }

  const { data: personData, loading: loadingPerson } = useQuery(
    GET_CONTACT_BY_EMAIL_FOR_CHIP,
    {
      variables: {
        contactEmail: objectId,
        ownerEmail: currentUser?.email,
      },
      skip:
        !objectId ||
        !currentUser?.email ||
        objectType != NativeObjectTypes.Contact,
    }
  )

  let icon
  switch (objectType) {
    case NativeObjectTypes.Contact:
      icon =
        photoUrl || personData?.getContactByEmail?.properties?.photoUrl ? (
          <Avatar
            src={
              personData?.getContactByEmail?.properties?.photoUrl || photoUrl
            }
            sx={{
              height: '18px',
              width: '18px',
            }}
          />
        ) : (
          <RiAccountCircleLine size={20} />
        )
      break
    /*case NativeObjectTypes.Organization:
      icon = <BusinessIcon />
      break
    case NativeObjectTypes.Opportunity:
      icon = <AttachMoneyIcon />
      break
    case NativeObjectTypes.Task:
      icon = <AssignmentIcon />
      break
    case NativeObjectTypes.Event:
      icon = <EventIcon />
      break
    case NativeObjectTypes.Project:
      icon = <WorkIcon />
      break*/
    default:
      icon = null
  }

  const selectedStyle = getStyle({
    type: parent.type.name,
    level: parent.attrs?.level,
  })

  const useChip = !renderPlainParents.includes(parent.type.name)
  const loading = loadingPerson // || loadingCompany etc in the future
  const crmObject = personData?.getContactByEmail || {}
  const label =
    loading || !crmObject?.objectId
      ? displayName
      : NativeObjectFormatters[objectType].label(crmObject)

  return (
    <NodeViewWrapper
      as="span"
      style={{ display: useChip ? 'inline-block' : 'inline' }}
      draggable={props.editor.isEditable}
    >
      <>
        {useChip ? (
          <Chip
            icon={icon}
            label={label}
            variant="outlined"
            color="primary"
            clickable={true}
            className={`day-ai-node day-ai-object-chip ${
              props.editor.isEditable ? 'editable' : 'public'
            } ${props.selected ? 'selected' : ''}`}
            sx={selectedStyle}
            onClick={() => {
              if (props.editor.isEditable)
                setSidebarObject(
                  personData?.getContactByEmail || {
                    objectType,
                    objectId,
                    properties: {},
                  }
                )
            }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          />
        ) : (
          <Box
            component="span"
            sx={{
              color: (theme) =>
                props.selected ? '#2867B2' : theme.palette.secondary.dark,
              cursor: 'pointer',
              wordBreak: 'break-word',
              '&:hover': {
                color: (theme) => theme.palette.secondary.main,
              },
              background: props.selected && lighten('#B4D7FF', 0.8),
            }}
            onClick={() => {
              if (props.editor.isEditable)
                setSidebarObject(
                  personData?.getContactByEmail || {
                    objectType,
                    objectId,
                    properties: {},
                  }
                )
            }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            {label}
          </Box>
        )}

        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={Boolean(anchorEl) && !loading}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          disableRestoreFocus
        >
          <Card
            sx={{ p: 0, opacity: loading ? 0.0 : 1.0 }}
            onMouseOver={() => {
              if (closeTimeout) {
                clearTimeout(closeTimeout)
                setCloseTimeout(null)
              }
            }}
            onMouseOut={handlePopoverClose}
          >
            {objectType === NativeObjectTypes.Contact && (
              <ContactChip contactObject={personData?.getContactByEmail} />
            )}
            {personData?.getContactByEmail?.properties?.[
              'relationshipSummary/type'
            ] && (
              <Box sx={{ p: 1 }}>
                <RelationshipTypeChip
                  type={
                    personData?.getContactByEmail?.properties?.[
                      'relationshipSummary/type'
                    ]
                  }
                  addSelectedType={() => {}}
                />
              </Box>
            )}
            {[
              NativeObjectTypes.Organization,
              NativeObjectTypes.Contact,
            ].includes(objectType) &&
              domain && (
                <Box sx={{ p: 1 }}>
                  <OrganizationChip domain={domain} />
                </Box>
              )}
          </Card>
        </Popover>
      </>
    </NodeViewWrapper>
  )
}

export const ObjectChipPluginKey = new PluginKey('objectChip')

export const TiptapObjectChip = Node.create<ObjectChipOptions>({
  name: 'objectChip',
  inline: true,
  group: 'inline',
  selectable: true,

  addAttributes() {
    return {
      objectType: {
        default: null,
      },
      objectId: {
        default: null,
      },
      displayName: {
        default: null,
      },
      photoUrl: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        { 'data-type': this.name },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      node.attrs.displayName,
    ]
  },

  renderText({ node }) {
    return node.attrs.displayName
  },

  addNodeView() {
    return ReactNodeViewRenderer(ObjectChipComponent)
  },
})
