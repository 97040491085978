import { Box, ListItemText, Typography } from '@mui/material'
import { Action, Person } from 'types/graphql'
import { useInbox } from './InboxContext'
import { ListItemButton } from '@mui/material'
import Row from '../Row/Row'
import PersonChip from '../People/PersonChip/PersonChip'
import { dayjs } from 'src/lib/dayjs'
import ActionPriorityChip from '../Actions/ActionPriorityChip/ActionPriorityChip'
import OrganizationChip from '../Organizations/OrganizationChip/OrganizationChip'
import PeopleChip from '../People/PeopleChip/PeopleChip'

const InboxListItem = ({ action }: { action: Action }) => {
  const { selectedAction, setSelectedAction } = useInbox()
  return (
    <ListItemButton
      selected={selectedAction?.id === action.id}
      key={action.id}
      onClick={() => setSelectedAction(action)}
      className="action-item"
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        p: '12px',
        pl: '10px',
        height: '88px',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Row
          gap={1}
          sx={{ justifyContent: 'space-between', width: '100%' }}
        >
          <Row
            sx={{
              width: '224px',
              overflow: 'hidden',
            }}
          >
            {action.people?.length === 1 && (
              <PersonChip
                email={action.people?.[0]?.email}
                variant="text"
              />
            )}
            {action.people?.length > 1 && (
              <PeopleChip people={action.people as Person[]} />
            )}
            {action.people?.length === 0 && (
              <>
                {action.organizations?.length === 1 ? (
                  <OrganizationChip
                    domain={action.organizations?.[0].domain}
                    variant="filled"
                    size="small"
                  />
                ) : action.organizations?.length > 1 ? (
                  <>orgs</>
                ) : null}
              </>
            )}
          </Row>

          <Row gap={0}>
            <Typography
              sx={{
                fontSize: '11px',
                letterSpacing: '-0.3px',
                lineHeight: '12px',
                opacity: 0.8,
              }}
            >
              {dayjs(action.createdAt).fromNow(true)}
            </Typography>
            <ActionPriorityChip
              action={action}
              variant="icon"
            />
          </Row>
        </Row>
      </Box>
      <ListItemText
        primary={''}
        primaryTypographyProps={{}}
        secondary={action.title}
        secondaryTypographyProps={{
          fontWeight: action.status?.id === 'UNREAD' ? 600 : 400,
          noWrap: false,
          sx: {
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            fontSize: '12px',
            letterSpacing: '-0.3px',
            lineHeight: '16px',
            opacity:
              action.status?.id === 'UNREAD'
                ? 1
                : ['COMPLETED', 'DISMISSED', 'REDUNDANT'].includes(
                      action.status?.id
                    )
                  ? 0.5
                  : 0.8,
          },
        }}
      />
    </ListItemButton>
  )
}

export default InboxListItem
