import { useContext, useMemo, useState } from 'react'

import ReactJson from '@microlink/react-json-view'
import { Box, Chip, CircularProgress } from '@mui/material'
import { IconButton } from '@mui/material'
import { Typography } from '@mui/material'
import {
  RiArrowDropDownLine,
  RiArrowDropUpLine,
  RiCalendarLine,
  RiChat1Line,
  RiPlayFill,
  RiRefreshLine,
  RiVideoLine,
} from '@remixicon/react'
import { RiSettings4Line } from '@remixicon/react'
import { IconBubbleText } from '@tabler/icons-react'

import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { extractEmailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { dayjs } from 'src/lib/dayjs'
import { isInternalUser } from 'src/lib/gates'
import { logger } from 'src/lib/logger'

import ContactTile from '../ContactTile/ContactTile'
import MeetingRecordingDialog from '../MeetingRecordingDialog/MeetingRecordingDialog'
import { buildTimelineItems } from '../Organizations/organizations'
import PersonChip from '../People/PersonChip/PersonChip'
import Row from '../Row/Row'
import ContextEditSidebar from '../ContextEditSidebar/ContextEditSidebar'

const GET_ORGANIZATION_HISTORY_FOR_INTERACTIONS_TIMELINE = gql`
  query getOrganizationHistoryForInteractionsTimeline(
    $workspaceId: String!
    $orgId: String!
  ) {
    organizationHistory(orgId: $orgId, workspaceId: $workspaceId) {
      domain
      domainGmailThreadSummaries
      meetingRecordings
      events
      notes {
        id
        content
        createdAt
        context {
          id
          parentReferenceKey
          createdAt
          updatedAt
          userId
          workspaceId
          plainTextValue
          referencedObjectIds
        }
        person {
          firstName
          lastName
          email
          photo
        }
      }
    }
  }
`

const EntryTypes = {
  note: { icon: <IconBubbleText />, label: 'Context' },
  meetingRecording: { icon: <RiVideoLine />, label: 'Meeting Recording' },
  event: { icon: <RiCalendarLine />, label: 'Event' },
  email: {
    icon: (
      <Box
        component="img"
        src="/gmail-icon.svg"
        sx={{ width: '24px', height: '24px' }}
      />
    ),
    label: 'Email',
  },
  slackMessage: { icon: <RiChat1Line />, label: 'Slack Message' },
}

const isWorkspaceMember = (
  email: string,
  workspaceMembers: any[],
  internalDomains: string[]
) => {
  const domain = extractEmailDomain(email)
  if (internalDomains.includes(domain)) {
    return true
  }

  return workspaceMembers?.some((member) => member.email === email)
}

const TimelineEntry = ({
  entry,
  setRecordingToWatch,
  showDay = true,
  openContext,
}) => {
  const { internalDomains, workspaces, selectedWorkspace } =
    useContext(DayContext)
  const [expanded, setExpanded] = useState(false)

  const workspaceMembers = useMemo(() => {
    return workspaces.find((workspace) => workspace.id === selectedWorkspace)
      .members
  }, [workspaces, selectedWorkspace])

  const people = useMemo(() => {
    const allPeople = Array.isArray(entry.people)
      ? [...new Set<string>(entry.people)]
      : []

    return allPeople.sort((a, b) => {
      const aIsInternal = isWorkspaceMember(
        a,
        workspaceMembers,
        internalDomains
      )
      const bIsInternal = isWorkspaceMember(
        b,
        workspaceMembers,
        internalDomains
      )
      if (aIsInternal && !bIsInternal) {
        return -1
      } else if (!aIsInternal && bIsInternal) {
        return 1
      }
      return 0
    })
  }, [entry.people, workspaceMembers, internalDomains])

  return (
    <>
      {showDay && (
        <Typography
          variant="h3"
          sx={{ textAlign: 'center', lineHeight: '160%', mt: '12px' }}
        >
          {dayjs(entry.timestamp).format('dddd, MMMM D')}
        </Typography>
      )}
      <Row sx={{ justifyContent: 'center' }}>
        <Box
          sx={{
            width: '1px',
            height: '20px',
            my: '4px',
            backgroundColor: '#000000',
            opacity: 0.4,
          }}
        />
      </Row>
      <Row sx={{ justifyContent: 'center', mb: '-8px' }}>
        <Row
          sx={{
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            border: (theme) => `1px solid ${theme.palette.divider}`,
            backgroundColor: (theme) => theme.palette.background.paper,
            justifyContent: 'center',
          }}
        >
          {EntryTypes[entry.type].icon}
        </Row>
      </Row>
      <Box
        sx={{
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: 2,
          px: 2,
        }}
      >
        <Box
          sx={{
            height: '64px',
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          {entry.actor ? (
            <Row
              sx={{
                justifyContent: 'space-between',
                height: '100%',
                mb: 1,
              }}
            >
              <>
                <ContactTile
                  email={entry.actor}
                  size={36}
                />
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '130%',
                    letterSpacing: '-0.36px',
                    color: (theme) => theme.palette.text.primary,
                    opacity: 0.7,
                    flexShrink: 0,
                  }}
                >
                  {dayjs(entry.timestamp).format('h:mm A')}
                </Typography>
              </>
            </Row>
          ) : (
            <>
              <Row
                sx={{
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 1,
                }}
              >
                <>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      lineHeight: '160%',
                      fontWeight: 600,
                      letterSpacing: '-0.8px',
                    }}
                  >
                    {entry.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 600,
                      lineHeight: '130%',
                      letterSpacing: '-0.36px',
                      color: (theme) => theme.palette.text.primary,
                      opacity: 0.7,
                    }}
                  >
                    {dayjs(entry.timestamp).format('h:mm A')}
                  </Typography>
                </>
              </Row>

              <Row
                gap={1}
                sx={{}}
              >
                {entry.type === 'meetingRecording' && (
                  <Chip
                    label="Watch recording"
                    size="small"
                    color="secondary"
                    variant="filled"
                    icon={<RiPlayFill size={16} />}
                    onClick={() => {
                      setRecordingToWatch(entry.id)
                    }}
                  />
                )}
                {people.slice(0, 3).map((person, index) => (
                  <PersonChip
                    key={`person-${index}-${entry.id}-${entry.timestamp}-${entry.type}-${person}`}
                    email={person}
                  />
                ))}
                {people.length > 3 && (
                  <Chip
                    label={`+ ${people.length - 3} more`}
                    size="small"
                    variant="outlined"
                  />
                )}
              </Row>
            </>
          )}
        </Box>
        <Box sx={{ py: 2 }}>
          <Typography
            sx={{
              fontSize: '14px',
              opacity: 0.7,
              lineHeight: '140%',
              letterSpacing: '-0.42px',
              fontWeight: 400,
              cursor: entry.type === 'note' ? 'pointer' : 'default',
            }}
            onClick={() => {
              if (entry.type === 'note') {
                openContext(entry.id)
              }
            }}
          >
            {expanded ? entry.expandedText : entry.label}
          </Typography>

          {entry.type !== 'note' && (
            <Row
              onClick={() => {
                setExpanded((prev) => !prev)
              }}
              sx={{
                cursor: 'pointer',
                mt: 2,
                display: entry.expandedText ? 'flex' : 'none',
              }}
            >
              <Box
                component={'span'}
                sx={{ fontSize: '14px', fontWeight: 600 }}
              >
                {expanded ? 'Less' : 'More'}
              </Box>
              {expanded ? (
                <RiArrowDropUpLine
                  size={24}
                  style={{ flexShrink: 0 }}
                />
              ) : (
                <RiArrowDropDownLine
                  size={24}
                  style={{ flexShrink: 0 }}
                />
              )}
            </Row>
          )}
        </Box>
      </Box>
    </>
  )
}

const InteractionsTimeline = ({ orgId, showHeader }) => {
  const { currentUser } = useAuth()
  const { selectedWorkspace } = useContext(DayContext)
  const [adminDebug, setAdminDebug] = useState(false)
  const [recordingToWatch, setRecordingToWatch] = useState(null)

  const [contextOpen, setContextOpen] = useState(null)

  const { data, loading, refetch } = useQuery(
    GET_ORGANIZATION_HISTORY_FOR_INTERACTIONS_TIMELINE,
    {
      variables: {
        orgId,
        workspaceId: selectedWorkspace,
      },
      skip: !selectedWorkspace || !orgId,
    }
  )

  const handleOpenContext = (id) => {
    logger.dev({ id, notes: data?.organizationHistory?.notes })
    const context = data?.organizationHistory?.notes?.find(
      (note) => note.id === id
    )?.context
    logger.dev({ context })
    setContextOpen(context)
  }

  logger.dev({ data })

  const timeline = useMemo(() => {
    if (data?.organizationHistory) {
      return buildTimelineItems(data?.organizationHistory)
    }
    return []
  }, [data])

  logger.dev({ timeline })

  return data ? (
    <>
      {showHeader && (
        <Row sx={{ justifyContent: 'space-between' }}>
          <Typography variant="h2">Timeline</Typography>
          {isInternalUser(currentUser) && (
            <Row>
              <IconButton
                onClick={() => {
                  setAdminDebug((prev) => !prev)
                }}
              >
                <RiSettings4Line />
              </IconButton>
              <IconButton
                onClick={() => {
                  refetch()
                }}
              >
                <RiRefreshLine />
              </IconButton>
            </Row>
          )}
        </Row>
      )}
      {adminDebug && (
        <Box>
          <Typography>Timeline Debug</Typography>
          <ReactJson
            src={{ organizationHistory: data?.organizationHistory, timeline }}
            collapsed={1}
          />
        </Box>
      )}
      <Box>
        {timeline.map((entry, index) => (
          <TimelineEntry
            key={`entry-${entry.id}-${entry.timestamp}-${entry.type}`}
            entry={entry}
            setRecordingToWatch={setRecordingToWatch}
            openContext={handleOpenContext}
            showDay={
              index === 0 ||
              timeline[index - 1].timestamp.split('T')[0] !==
                entry.timestamp.split('T')[0]
            }
          />
        ))}
      </Box>
      {timeline?.length === 0 && (
        <Row sx={{ p: 3, justifyContent: 'center' }}>
          <Typography>No history found for {orgId}</Typography>
        </Row>
      )}

      {recordingToWatch && (
        <MeetingRecordingDialog
          id={recordingToWatch}
          open={!!recordingToWatch}
          onClose={() => setRecordingToWatch(null)}
        />
      )}

      {contextOpen && (
        <ContextEditSidebar
          context={contextOpen}
          onClose={() => setContextOpen(null)}
          onInit={() => {}}
          onUpdate={() => {
            refetch()
          }}
        />
      )}
    </>
  ) : (
    <Box sx={{ p: 3 }}>
      <CircularProgress />
    </Box>
  )
}

export default InteractionsTimeline
