import { useState } from 'react'

import { Box, Button, IconButton, Tooltip } from '@mui/material'
import {
  RiArrowLeftSLine,
  RiH1,
  RiH2,
  RiH3,
  RiHeading,
  RiLink,
  RiListCheck3,
  RiListIndefinite,
  RiListOrdered2,
  RiListUnordered,
  RiSearchLine,
  RiSeparator,
  RiTableLine,
  RiTeamLine,
} from '@remixicon/react'
import { FloatingMenu } from '@tiptap/react'

import Row from 'src/components/Row/Row'
import TiptapLinkEditor from 'src/components/Tiptap/TiptapLinkEditor/TiptapLinkEditor'
import TiptapYouTubeAdder from 'src/components/Tiptap/TiptapYouTubeAdder/TiptapYouTubeAdder'
import TiptapBlockBySearch from 'src/components/Tiptap/TiptapBlockBySearch/TiptapBlockBySearch'

import { tiptapButtonSx, tiptapFloaterSx } from '../styles'

const remixIconStyle = {
  height: '16px',
  width: '16px',
}

const tooltipDirection = 'top'

const TiptapFloatingMenu = ({ editor }) => {
  const [focus, setFocus] = useState<
    'link' | 'table' | 'header' | 'block' | 'list' | null
  >(null)

  const handleChooseLink = () => {
    setFocus('link')
  }

  const BackButton = () => (
    <IconButton
      sx={{
        p: 1,
        borderRadius: '0px',
      }}
      onClick={() => setFocus(null)}
    >
      <RiArrowLeftSLine style={remixIconStyle} />
    </IconButton>
  )

  return (
    editor && (
      <FloatingMenu
        className="floating-menu"
        tippyOptions={{ duration: 100, placement: 'bottom-end', zIndex: 10 }}
        editor={editor}
      >
        {!focus && (
          <Box sx={tiptapFloaterSx}>
            <Row>
              <Tooltip
                title="Add a heading"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={() => {
                    setFocus('header')
                  }}
                  className={editor.isActive('heading') ? 'is-active' : ''}
                >
                  <RiHeading style={remixIconStyle} />
                </Button>
              </Tooltip>

              <Tooltip
                title="Add a list"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={() => setFocus('list')}
                >
                  <RiListIndefinite style={remixIconStyle} />
                </Button>
              </Tooltip>

              <Tooltip
                title="Add a table"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={() => {
                    setFocus('table')
                  }}
                >
                  <RiTableLine style={remixIconStyle} />
                </Button>
              </Tooltip>
              <Tooltip
                title="Insert a horizontal rule"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={() =>
                    editor.chain().focus().setHorizontalRule().run()
                  }
                >
                  <RiSeparator style={remixIconStyle} />
                </Button>
              </Tooltip>

              <Tooltip
                title="Add a link"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={handleChooseLink}
                >
                  <RiLink style={remixIconStyle} />
                </Button>
              </Tooltip>
              <TiptapYouTubeAdder
                buttonSx={tiptapButtonSx}
                iconStyle={remixIconStyle}
                editor={editor}
                tooltipDirection={tooltipDirection}
              />
            </Row>
            <Row sx={{ p: 2, pt: 1 }}>
              <Button
                variant="outlined"
                onClick={() => setFocus('block')}
                size="small"
                fullWidth={true}
                startIcon={<RiSearchLine size={16} />}
              >
                Search & Add
              </Button>
            </Row>
          </Box>
        )}
        {editor.isEditable && focus && (
          <Box sx={tiptapFloaterSx}>
            {focus === 'header' && (
              <Row>
                <BackButton />
                <Tooltip
                  title="Write an H1 heading"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() => {
                      editor.chain().focus().toggleHeading({ level: 1 }).run()
                    }}
                    className={
                      editor.isActive('heading', { level: 1 })
                        ? 'is-active'
                        : ''
                    }
                  >
                    <RiH1 style={remixIconStyle} />
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Write an H2 heading"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() =>
                      editor.chain().focus().toggleHeading({ level: 2 }).run()
                    }
                    className={
                      editor.isActive('heading', { level: 2 })
                        ? 'is-active'
                        : ''
                    }
                  >
                    <RiH2 style={remixIconStyle} />
                  </Button>
                </Tooltip>

                <Tooltip
                  title="Write an H3 heading"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() =>
                      editor.chain().focus().toggleHeading({ level: 3 }).run()
                    }
                    className={
                      editor.isActive('heading', { level: 3 })
                        ? 'is-active'
                        : ''
                    }
                  >
                    <RiH3 style={remixIconStyle} />
                  </Button>
                </Tooltip>
              </Row>
            )}
            {focus === 'list' && (
              <Row>
                <BackButton />
                <Tooltip
                  title="Add a bullet list"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() =>
                      editor.chain().focus().toggleBulletList().run()
                    }
                    className={editor.isActive('bulletList') ? 'is-active' : ''}
                  >
                    <RiListUnordered style={remixIconStyle} />
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Add a numbered list"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() =>
                      editor.chain().focus().toggleOrderedList().run()
                    }
                    className={
                      editor.isActive('orderedList') ? 'is-active' : ''
                    }
                  >
                    <RiListOrdered2 style={remixIconStyle} />
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Add a task list"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() =>
                      editor.chain().focus().toggleTaskList().run()
                    }
                    className={editor.isActive('taskList') ? 'is-active' : ''}
                  >
                    <RiListCheck3 style={remixIconStyle} />
                  </Button>
                </Tooltip>
              </Row>
            )}
            {focus === 'link' && (
              <TiptapLinkEditor
                editor={editor}
                setFocus={setFocus}
              />
            )}
            {focus === 'block' && (
              <TiptapBlockBySearch
                open={focus === 'block'}
                onClose={() => setFocus(null)}
                editor={editor}
              />
            )}
            {focus === 'table' && (
              <Row>
                <BackButton />
                <Tooltip
                  title="Add a table"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() => {
                      editor.commands.insertContent([
                        {
                          type: 'day-ai-table-text',
                          attrs: {
                            class: 'day-ai-table-text',
                          },
                          content: [
                            {
                              type: 'tableRow',
                              content: [
                                {
                                  type: 'tableHeader',
                                  attrs: {
                                    colspan: 1,
                                    rowspan: 1,
                                    colwidth: null,
                                  },
                                  content: [
                                    {
                                      type: 'paragraph',
                                    },
                                  ],
                                },
                                {
                                  type: 'tableHeader',
                                  attrs: {
                                    colspan: 1,
                                    rowspan: 1,
                                    colwidth: null,
                                  },
                                  content: [
                                    {
                                      type: 'paragraph',
                                    },
                                  ],
                                },
                                {
                                  type: 'tableHeader',
                                  attrs: {
                                    colspan: 1,
                                    rowspan: 1,
                                    colwidth: null,
                                  },
                                  content: [
                                    {
                                      type: 'paragraph',
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              type: 'tableRow',
                              content: [
                                {
                                  type: 'tableCell',
                                  attrs: {
                                    colspan: 1,
                                    rowspan: 1,
                                    colwidth: null,
                                  },
                                  content: [
                                    {
                                      type: 'paragraph',
                                    },
                                  ],
                                },
                                {
                                  type: 'tableCell',
                                  attrs: {
                                    colspan: 1,
                                    rowspan: 1,
                                    colwidth: null,
                                  },
                                  content: [
                                    {
                                      type: 'paragraph',
                                    },
                                  ],
                                },
                                {
                                  type: 'tableCell',
                                  attrs: {
                                    colspan: 1,
                                    rowspan: 1,
                                    colwidth: null,
                                  },
                                  content: [
                                    {
                                      type: 'paragraph',
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                        },
                      ])
                      editor.commands.focus()
                    }}
                  >
                    <RiTableLine style={remixIconStyle} />
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Add a Person table"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() => {
                      editor.commands.insertContent([
                        {
                          type: 'persontable',
                          attrs: {
                            stateJsonString: JSON.stringify({ init: true }),
                          },
                        },
                        {
                          type: 'paragraph',
                        },
                      ])
                      editor.commands.focus()
                    }}
                  >
                    <RiTeamLine style={remixIconStyle} />
                  </Button>
                </Tooltip>
              </Row>
            )}
          </Box>
        )}
      </FloatingMenu>
    )
  )
}

export default TiptapFloatingMenu
