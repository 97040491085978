import { isWorkAccountHealthy } from 'src/components/WorkAccount/workAccountUtil'
import { logger } from './logger'

const isCoreContactComplete = (coreContact) => {
  return !!(coreContact.firstName && coreContact.lastName)
}

export const isWorkAccountReady = ({ workAccount }) => {
  if (!workAccount) return false
  return isWorkAccountHealthy({ workAccount })
}

const isWorkspaceReady = (workspace) => {
  return workspace?.status === 'ACTIVE' && !workspace?.isDefault
}

const hasEmailSharingSettings = (
  workAccountWorkspaceConnections,
  workspace
) => {
  if (
    !workAccountWorkspaceConnections ||
    workAccountWorkspaceConnections?.length === 0 ||
    !workspace
  ) {
    logger.warn('no workAccountWorkspaceConnections')
    return false
  }

  return workAccountWorkspaceConnections?.some((connectedWorkAccount) => {
    return connectedWorkAccount.connectedWorkspaces?.find(
      (connectedWorkspace) => {
        // todo: check the workspace id matches target workspace and the rules are sufficient

        return (
          connectedWorkspace.workspaceId === workspace.id &&
          connectedWorkspace.sharingRules?.length >= 6
        )
      }
    )
  })
}

const hasRecordingSettings = (workAccounts) => {
  const enabled = workAccounts.filter((workAccount) => {
    return workAccount?.calendarAutoRecordSettings?.mode != 'NONE'
  })

  return enabled.length === workAccounts.length
}

export const onboardingStateDebug = ({
  coreContact,
  workAccounts,
  workspace,
  workAccountWorkspaceConnections,
}) => {
  return {
    isCoreContactComplete: isCoreContactComplete(coreContact),
    hasEmailSharingSettings: hasEmailSharingSettings(
      workAccountWorkspaceConnections,
      workspace
    ),
    hasRecordingSettings: hasRecordingSettings(workAccounts),
    isWorkspaceReady: isWorkspaceReady(workspace),
    isWorkAccountReady: isWorkAccountReady({ workAccount: workAccounts?.[0] }),
    workspaceId: workspace?.id,
    connectionsCount: workAccountWorkspaceConnections?.length,
  }
}

export const getOnboardingStatusDebugMessage = ({
  coreContact,
  workAccounts,
  workspace,
  workAccountWorkspaceConnections,
  selectedWorkspace,
  targetWorkspace,
}) => {
  const onboardingState = onboardingStateDebug({
    coreContact,
    workAccounts,
    workspace,
    workAccountWorkspaceConnections,
  })

  return `I could use some help onboarding to Day.ai. Here's my status:

    User profile ready: ${onboardingState.isCoreContactComplete}
    Email sharing settings: ${onboardingState.hasEmailSharingSettings}
    Recording settings: ${onboardingState.hasRecordingSettings}
    Workspace ready: ${onboardingState.isWorkspaceReady}
    Work account ready: ${onboardingState.isWorkAccountReady}
    Workspace to onboard: ${targetWorkspace?.id || 'N/A'}
    LS Workspace: ${selectedWorkspace || 'N/A'}
    Default?: ${targetWorkspace?.isDefault}
    hasEmailSharingSettings: ${hasEmailSharingSettings(
      workAccountWorkspaceConnections,
      workspace
    )}

  `
}

export const onboardingStatus = ({
  coreContact,
  workAccounts,
  workspace,
  workAccountWorkspaceConnections,
  declinedToRecord = false,
}) => {
  const log = false
  if (log) {
    logger.info('Checking onboarding status', {
      hasContact: !!coreContact,
      workAccountsCount: workAccounts?.length,
      workspaceId: workspace?.id,
      connectionsCount: workAccountWorkspaceConnections?.length,
      declinedToRecord,
    })
  }

  const workAccountsToSkip = workAccounts.filter((workAccount) => {
    return [
      'taylor.lint@gmail.com',
      'taylor@swantide.com',
      'ryan@swantide.com',
      'andrew@swantide.com',
      'matthew@swantide.com',
      'khakie@swantide.com',
      'megan@circle.co',
      'johnsherer12@gmail.com',
      'aaron@singularity.vc',
      'erik@gospelsf.com',
    ].includes(workAccount.email)
  })

  if (workAccountsToSkip.length > 0) {
    if (log) {
      logger.info('Found skip accounts, bypassing onboarding', {
        skippedEmails: workAccountsToSkip.map((wa) => wa.email),
      })
    }
    return {
      onboardingStep: 5,
      isComplete: true,
    }
  }

  let farthestStep = 0
  if (coreContact && isCoreContactComplete(coreContact)) {
    if (log) {
      logger.info('Core contact is complete, proceeding to step 1')
    }
    farthestStep = 1

    if (hasEmailSharingSettings(workAccountWorkspaceConnections, workspace)) {
      if (log) {
        logger.info('Email sharing settings configured, advancing to step 4')
      }
      farthestStep = 4

      if (hasRecordingSettings(workAccounts) || declinedToRecord) {
        if (log) {
          logger.info(
            'Recording settings configured or declined, completing onboarding'
          )
        }
        farthestStep = 5
      }
    } else {
      if (isWorkspaceReady(workspace)) {
        if (log) {
          logger.info('Workspace is ready, advancing to step 3')
        }
        farthestStep = 3
      } else if (
        isWorkAccountReady({
          workAccount: workAccounts?.[0],
        })
      ) {
        if (log) {
          logger.info('Work account is ready, advancing to step 2')
        }
        farthestStep = 2
      }
    }
  } else {
    if (log) {
      logger.info('Core contact incomplete, remaining at step 0')
    }
    farthestStep = 0
  }

  const result = {
    onboardingStep: farthestStep,
    isComplete: farthestStep > 3,
  }
  if (log) {
    logger.info('Onboarding status determined', result)
  }
  return result
}
