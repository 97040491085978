import { useContext, useState } from 'react'

import { Alert, Box } from '@mui/material'
import { RiBarChartFill, RiDraggable } from '@remixicon/react'
import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react'

import Pipeline from 'src/components/Pipeline/Pipeline'
import Row from 'src/components/Row/Row'
import TiptapSignupCta from 'src/components/Tiptap/TiptapSignupCta/TiptapSignupCta'
import { DayContext } from 'src/lib/dayContext'

import { nodeViewContainerSx, nodeDragHandleSx } from '../styles'

const tag = 'day-ai-pipeline'

const TiptapNodePipelineRenderer = (props) => {
  const { selectedWorkspace } = useContext(DayContext)
  const [ctaOpen, setCtaOpen] = useState(false)
  const [hover, setHover] = useState(false)

  const handleFilterChange = (filters) => {
    if (filters) props.updateAttributes({ ...filters })
  }

  let hoverTimeout
  const handleStartHover = () => {
    // setHover to true, clear setTimeout from any handleEndHovers that are pending
    setHover(true)
    clearTimeout(hoverTimeout)
  }

  const handleEndHover = () => {
    // setHover to false on a 2500ms setTimeout that handleStartHover can clear:
    hoverTimeout = setTimeout(() => {
      setHover(false)
    }, 100)
  }

  return (
    <NodeViewWrapper
      as="div"
      className="day-ai-pipeline"
      draggable={props.editor.isEditable}
      {...{ 'data-drag-handle': '' }}
      onClick={() => {
        if (!props.editor.isEditable) {
          setCtaOpen(true)
        }
      }}
      onMouseEnter={handleStartHover}
      onMouseLeave={handleEndHover}
    >
      <Row
        sx={{ ...nodeViewContainerSx, height: '640px', pb: 6, pt: 0, px: 0 }}
        className={`day-ai-node ${
          props.editor.isEditable ? 'editable' : 'public'
        } ${props.selected ? 'selected' : ''}`}
      >
        <Box
          className="drag-handle"
          sx={{ ...nodeDragHandleSx, pr: 0, pt: 2 }}
        >
          <RiDraggable />
        </Box>
        <Box
          sx={{
            width: '100%',
            ml: '-14px',
            '& .pipelineControls': {
              opacity: hover ? 1 : 0,
              transition: 'all 0.3s ease-in-out',
            },
          }}
        >
          {props.editor.isEditable &&
          (props.node.attrs.workspaceId || selectedWorkspace) ? (
            <Pipeline
              id={props.node.attrs.id}
              workspaceId={props.node.attrs.workspaceId || selectedWorkspace}
              onFilterUpdate={handleFilterChange}
              savedFilters={{ ...props.node.attrs }}
            />
          ) : (
            <Alert
              icon={<RiBarChartFill />}
              severity="info"
            >
              Pipeline not viewable on public page
            </Alert>
          )}
        </Box>
      </Row>
      <TiptapSignupCta
        open={ctaOpen}
        setClosed={() => setCtaOpen(false)}
        text={`See more information about ${props.node.attrs.email}`}
        redirectTo="/login"
      />
    </NodeViewWrapper>
  )
}

const TiptapNodePipeline = Node.create({
  name: 'pipeline',
  group: 'block',
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
      owner: {
        default: null,
      },
      view: {
        default: null,
      },
      forecastTimePeriod: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag,
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const pipelineAttributes = mergeAttributes(HTMLAttributes, {
      'data-type': 'pipeline',
    })
    return ['day-ai-pipeline', pipelineAttributes]
  },

  addNodeView() {
    return ReactNodeViewRenderer(TiptapNodePipelineRenderer)
  },
})

export default TiptapNodePipeline
