import { Box } from '@mui/material'

const Row = ({ sx = {}, gap = null, children, ...otherProps }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...sx,
      }}
      gap={gap}
      {...otherProps}
    >
      {children}
    </Box>
  )
}

export default Row
