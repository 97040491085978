import { ThumbDown } from '@mui/icons-material'
import { Chip } from '@mui/material'
import {
  IconCheck,
  IconClock,
  IconLabel,
  IconLabelFilled,
} from '@tabler/icons-react'

export const actionStatusMap = {
  COMPLETED: {
    label: 'Completed',
    color: 'success',
    icon: <IconCheck />,
  },
  READ: {
    label: 'In Progress',
    color: 'info',
    icon: <IconLabel />,
  },
  DISMISSED: {
    label: 'Dismissed',
    color: 'error',
    icon: <ThumbDown />,
  },
  REDUNDANT: {
    label: 'Redundant',
    color: 'warning',
    icon: <IconCheck />,
  },
  SNOOZED: {
    label: 'Snoozed',
    color: 'info',
    icon: <IconClock />,
  },
  UNREAD: {
    label: 'Unread',
    color: 'info',
    icon: <IconLabelFilled />,
  },
}

const ActionStatusChip = ({ status }) => {
  if (!status) return null
  const statusObject = actionStatusMap[status.id]
  return (
    <Chip
      size="small"
      icon={statusObject.icon}
      label={statusObject.label}
      variant="outlined"
      sx={{
        border: 'none',
        justifyContent: 'flex-start',
        width: '100%',
        fontSize: '11px',
        fontWeight: 600,
        letterSpacing: '-0.2px',
        '& .MuiChip-icon': {
          color: (theme) => theme.palette[statusObject.color].main,
          height: '12px',
          width: '12px',
          m: 0,
        },
      }}
    />
  )
}

export default ActionStatusChip
