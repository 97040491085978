import { Box } from '@mui/material'

const BodyContainer = ({ children }) => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        overflow: 'hidden',
        px: 3,
        background: (theme) => theme.palette.background.default,
      }}
    >
      {children}
    </Box>
  )
}

export default BodyContainer
