import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import Row from '../Row/Row'
import ActionActions from '../Actions/ActionActions/ActionActions'
import { IconCopy, IconInbox, IconSend, IconStar } from '@tabler/icons-react'
import { useContext, useEffect, useRef, useState } from 'react'
import PersonChip from '../People/PersonChip/PersonChip'
import ActionPriorityChip from '../Actions/ActionPriorityChip/ActionPriorityChip'
import { typeLabels } from './InboxActions'
import ReactJson from '@microlink/react-json-view'
import { ActionChannelType } from 'types/graphql'
import { DayContext } from 'src/lib/dayContext'
import { buildGmailNewDraftUrl } from 'src/lib/gmail'
import SlackLogo from '../Slack/SlackLogo/SlackLogo'
import { toast } from 'react-hot-toast'
import {
  buildUserContextReferenceKey,
  NativeObjectTypes,
} from 'src/lib/objects'
import InboxActionContext from './InboxActionContext'
import ActionDraftTiptap from '../Actions/ActionDraftTiptap/ActionDraftTiptap'

const InboxActionDetail = ({
  selectedAction,
  refetch,
  addContext,
  type,
  updateAction,
  onHide = (actionId: string) => {},
}) => {
  const { peopleByEmail } = useContext(DayContext)
  const [draft, setDraft] = useState<{
    body: string
    title: string
    type: ActionChannelType | null
    recipients: string[]
  }>()
  const [newContext, setNewContext] = useState('')
  const [editingTitle, setEditingTitle] = useState(false)
  const [title, setTitle] = useState(selectedAction?.title || '')

  const contextUpdatedAt = useRef(0)

  useEffect(() => {
    const actionDraft =
      selectedAction?.draft?.body && selectedAction?.channel?.type
        ? {
            body: selectedAction?.draft?.body || '',
            title: selectedAction?.draft?.title || '',
            type: selectedAction?.channel?.type,
            recipients: (selectedAction?.people ?? [])
              .map((p) => p.email)
              .filter(Boolean),
          }
        : null
    setDraft(actionDraft)
    setTitle(selectedAction?.title || '')
    setEditingTitle(false)
  }, [selectedAction])

  const handleUpdateAction = async () => {
    if (!selectedAction) return
    setEditingTitle(false)

    await updateAction({
      id: selectedAction.id,
      title,
      workspaceId: selectedAction.workspaceId,
    })
  }

  const handleAddContext = async () => {
    const referencedObjectIds = []
    for (const person of (selectedAction?.people ?? []).filter(Boolean)) {
      referencedObjectIds.push(
        buildUserContextReferenceKey(NativeObjectTypes.Person, person.email)
      )
    }
    for (const org of (selectedAction?.organizations ?? []).filter(Boolean)) {
      referencedObjectIds.push(
        buildUserContextReferenceKey(NativeObjectTypes.Organization, org.domain)
      )
    }

    const input = {
      parentReferenceKey: buildUserContextReferenceKey(
        NativeObjectTypes.Action,
        selectedAction.id
      ),
      plainTextValue: newContext,
      referencedObjectIds,
      workspaceId: selectedAction.workspaceId,
    }

    await addContext(input)
    setNewContext('')
    contextUpdatedAt.current += 1
    refetch()
  }

  return selectedAction ? (
    <Box
      sx={{
        width: '100%',
        '& .MuiTextField-root': {},
      }}
    >
      <Row sx={{ height: '56px', justifyContent: 'space-between' }}>
        <ActionActions
          action={selectedAction}
          showButtons={true}
          onUpdate={() => {
            refetch()
          }}
          onHide={(actionId) => {
            onHide(actionId)
          }}
        />
        <Row gap={1}>
          <ActionPriorityChip action={selectedAction} />
          {selectedAction.owner?.email && (
            <PersonChip email={selectedAction.owner.email} />
          )}
          {process.env.HOST.includes('localhost') && (
            <Tooltip
              title="Mark as important"
              arrow={true}
              placement="bottom"
            >
              <IconButton sx={{ p: '4px', borderRadius: '2px' }}>
                <IconStar size={16} />
              </IconButton>
            </Tooltip>
          )}
        </Row>
      </Row>

      {editingTitle ? (
        <Box>
          <TextField
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth={true}
            variant="standard"
            multiline={true}
            rows={2}
            inputProps={{
              style: {
                fontSize: '24px',
                fontWeight: 600,
                letterSpacing: '-1.2px',
                lineHeight: '30px',
              },
            }}
          />
          <Row sx={{ justifyContent: 'flex-end' }}>
            <Button onClick={handleUpdateAction}>Save</Button>
          </Row>
        </Box>
      ) : (
        <Typography
          variant="h2"
          sx={{ mt: 1, mb: 4 }}
          onClick={() => setEditingTitle(true)}
        >
          {title}
        </Typography>
      )}
      {draft && (
        <Box
          sx={{
            my: 3,
            background: (theme) => theme.palette.background.paper,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: '6px',
            overflow: 'hidden',
          }}
        >
          {draft?.type === 'GMAIL' && (
            <>
              <Row
                sx={{
                  justifyContent: 'space-between',
                  background: '#c2e7ff',
                  px: '12px',
                  height: '32px',
                  mb: '12px',
                }}
              >
                <Row gap={1}>
                  <Box
                    component="img"
                    src={'/gmail-icon.svg'}
                    sx={{
                      height: '18px',
                      width: '18px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      letterSpacing: '-0.3px',
                      fontWeight: 500,
                    }}
                  >
                    Gmail
                  </Typography>
                </Row>
                <Button
                  variant="text"
                  size="small"
                  startIcon={<IconSend size={12} />}
                  onClick={() => {
                    const url = buildGmailNewDraftUrl({
                      to: draft?.recipients.join(','),
                      subject: draft?.title,
                      body: draft?.body,
                    })
                    window.open(url, '_blank')
                  }}
                >
                  Create Draft
                </Button>
              </Row>
              <Box sx={{ px: '12px' }}>
                <Autocomplete
                  value={draft?.recipients}
                  onChange={(e, value) =>
                    setDraft({ ...draft, recipients: value })
                  }
                  options={Object.values(peopleByEmail).map((p) => p.email)}
                  renderInput={(params) => <TextField {...params} />}
                  multiple={true}
                  freeSolo={true}
                  sx={{ mb: '12px' }}
                />
              </Box>
            </>
          )}
          {draft?.type === 'SLACK' && (
            <>
              <Row
                sx={{
                  justifyContent: 'space-between',
                  background: '#703259',
                  px: '12px',
                  height: '32px',
                  mb: '12px',
                }}
              >
                <Row gap={1}>
                  <SlackLogo size={18} />
                  {selectedAction.channel.label && (
                    <Typography
                      sx={{
                        fontSize: '14px',
                        letterSpacing: '-0.3px',
                        fontWeight: 500,
                        color: '#FFFFFF',
                      }}
                    >
                      {`#${selectedAction.channel.label}`}
                    </Typography>
                  )}
                </Row>
                <Button
                  variant="text"
                  size="small"
                  startIcon={<IconCopy size={12} />}
                  sx={{ color: '#FFFFFF' }}
                  onClick={() => {
                    navigator.clipboard
                      .writeText(draft?.body || '')
                      .then(() => {
                        toast.success(
                          'Message copied to clipboard. Opening Slack channel ...'
                        )

                        setTimeout(() => {
                          window.open(
                            `slack://channel?id=${selectedAction.channel.id}&team=${selectedAction.channel.accountId}`,
                            '_blank'
                          )
                        }, 2000)
                      })
                      .catch(() => {
                        toast.error('Failed to copy message')
                      })
                  }}
                >
                  Copy & Open
                </Button>
              </Row>
            </>
          )}
          {['GMAIL'].includes(draft?.type) && (
            <Box sx={{ px: '12px' }}>
              <TextField
                value={draft?.title}
                onChange={(e) => {
                  if (
                    e.nativeEvent instanceof InputEvent &&
                    (e.nativeEvent.inputType?.startsWith('insert') ||
                      e.nativeEvent.inputType?.startsWith('delete'))
                  ) {
                    setDraft({ ...draft, title: e.target.value })
                  }
                }}
                placeholder="Title"
                fullWidth={true}
                variant="outlined"
                sx={{ mb: '12px' }}
              />
            </Box>
          )}
          {['GMAIL', 'SLACK'].includes(draft?.type) && (
            <Box sx={{ px: '12px' }}>
              <TextField
                value={draft?.body}
                onChange={(e) => {
                  if (
                    e.nativeEvent instanceof InputEvent &&
                    (e.nativeEvent.inputType?.startsWith('insert') ||
                      e.nativeEvent.inputType?.startsWith('delete'))
                  ) {
                    setDraft({ ...draft, body: e.target.value })
                  }
                }}
                placeholder="Message"
                fullWidth={true}
                variant="outlined"
                multiline={true}
                rows={draft?.body?.length / 30 || 8}
                sx={{ mb: '12px' }}
              />
            </Box>
          )}
          {['PAGE', 'NOTE', 'MEETING'].includes(draft?.type) && (
            <ActionDraftTiptap
              actionId={selectedAction.id}
              workspaceId={selectedAction.workspaceId}
            />
          )}
        </Box>
      )}

      <Box
        sx={{
          width: '100%',
          backgroundColor: (theme) => theme.palette.background.paper,
          py: '12px',
          borderRadius: '4px',
          mt: '12px',
        }}
      >
        <Box sx={{ mb: 2 }}>
          <TextField
            value={newContext}
            onChange={(e) => setNewContext(e.target.value)}
            placeholder="Add context & instructions"
            multiline={true}
            rows={3}
            fullWidth={true}
            variant="outlined"
          />
          <Row sx={{ justifyContent: 'flex-end' }}>
            <Button
              variant="outlined"
              size="small"
              sx={{
                mt: '-44px',
                mr: '6px',
              }}
              color="primary"
              onClick={handleAddContext}
              disableElevation={true}
            >
              Add
            </Button>
          </Row>
        </Box>
        <InboxActionContext
          key={`${selectedAction.id}-${contextUpdatedAt.current}`}
          action={selectedAction}
        />
      </Box>
      {false && process.env.HOST.includes('localhost') && (
        <ReactJson
          src={selectedAction}
          collapsed={0}
        />
      )}
    </Box>
  ) : (
    <Row sx={{ height: '90%', justifyContent: 'center', opacity: 0.5 }}>
      <Row
        sx={{ flexDirection: 'column', alignItems: 'center' }}
        gap={1}
      >
        <IconInbox size={72} />
        <Typography variant="h3">{typeLabels[type].label} Inbox</Typography>
      </Row>
    </Row>
  )
}

export default InboxActionDetail
