import { datadogLogs } from '@datadog/browser-logs'
import { createClient } from '@supabase/supabase-js'

import { createAuth } from '@redwoodjs/auth-supabase-web'

const supabaseClient = createClient(
  process.env.SUPABASE_URL || '',
  process.env.SUPABASE_KEY || ''
)

if (process.env.NODE_ENV === 'production') {
  supabaseClient.auth.onAuthStateChange((event, session) => {
    if (event === 'SIGNED_OUT') {
      datadogLogs.clearUser()
    } else {
      datadogLogs.setUser({
        id: session?.user?.id,
        email: session?.user?.email,
      })
    }
  })
}

export const { AuthProvider, useAuth } = createAuth(supabaseClient)
