import { Box } from '@mui/material'
import { ReactNode } from 'react'

interface RowSplitProps {
  left: ReactNode
  right: ReactNode
  sx?: any
  onClick?: () => void
}

const RowSplit = ({
  left,
  right,
  sx = {},
  onClick = () => {},
}: RowSplitProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...sx,
      }}
      onClick={onClick}
    >
      {left}
      {right}
    </Box>
  )
}

export default RowSplit
