import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { RiFileLine } from '@remixicon/react'
import dayjs from 'dayjs'

import { navigate, routes } from '@redwoodjs/router'

import PageActions from '../../Page/PageActions/PageActions'

const SidebarPages = ({ pages, setOpen }) => {
  return (
    pages?.length > 0 && (
      <List
        sx={{
          p: 0,
          '& .MuiListItem-root': {
            p: 0,
            borderRadius: '8px',
            '&:hover': {
              background: (theme) => theme.palette.action.hover,
            },
          },
        }}
      >
        {pages.map((page) => (
          <ListItem
            key={`sidebarPage_${page.id}`}
            disableGutters={true}
            secondaryAction={
              <PageActions
                page={page}
                orientation="vertical"
              />
            }
          >
            <ListItemButton
              onClick={() => {
                navigate(routes.pageDetail({ id: page.id }))
                setOpen(false)
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: '24px',
                  width: '24px',
                  justifyContent: 'center',
                  mr: 2,
                }}
              >
                {page.emoji ? (
                  <Typography sx={{ fontSize: '1.4rem' }}>
                    {page.emoji}
                  </Typography>
                ) : (
                  <RiFileLine size={32} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={page.title}
                secondary={
                  page.updatedAt
                    ? dayjs(page.updatedAt).format('MMM D, YYYY')
                    : ''
                }
                primaryTypographyProps={{
                  noWrap: true,
                  variant: 'subtitle2',
                }}
                secondaryTypographyProps={{
                  noWrap: true,
                  variant: 'caption',
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    )
  )
}

export default SidebarPages
