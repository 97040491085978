import { memo } from 'react'

import { AvatarGroup, Box } from '@mui/material'

import ContactAvatar from '../ContactAvatar/ContactAvatar'

const AvatarRow = memo(
  ({
    emails,
    max = 4,
    size = 24,
    borderRadius = 5,
    showVerification = false,
    showSidebar = false,
    margin,
    sx = {},
    containerSx = {},
  }: {
    emails: string[]
    max?: number
    size?: number
    borderRadius?: number
    showVerification?: boolean
    showSidebar?: boolean
    margin?: string
    sx?: any
    containerSx?: any
  }) => {
    emails = [...new Set(emails)]

    const reverseMargin =
      margin && margin.includes('-') && margin.includes('px')
        ? (parseInt(margin.replace('px', '')) * -1).toString() + 'px'
        : margin
    return (
      <Box sx={containerSx}>
        <AvatarGroup
          max={max}
          sx={{
            ml: reverseMargin ? `calc(${reverseMargin}px + 4px)` : '6px',
            '& .personAvatarBox': {
              width: `${size}px`,
              height: `${size}px`,
              fontSize: 12,
              ml: margin || '-6px',
            },
            ...sx,
            '& > .MuiAvatarGroup-avatar': {
              width: `${size}px`,
              height: `${size}px`,
              ml: margin || '-6px',
              background: '#EBECEE',
            },
          }}
        >
          {emails.map((email, index) => (
            <ContactAvatar
              key={`avatar_${index}`}
              email={email}
              size={size}
              border={2}
              borderRadius={borderRadius}
              showVerification={showVerification}
              showSidebar={showSidebar}
            />
          ))}
        </AvatarGroup>
      </Box>
    )
  },
  (previous, next) =>
    previous?.emails?.join('') === next?.emails?.join('') &&
    previous.max === next.max
)

export default AvatarRow
