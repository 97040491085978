import { useState } from 'react'

import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { RiArrowLeftSFill } from '@remixicon/react'
import { Organization } from 'types/graphql'

import Row from 'src/components/Row/Row'
import { sidebarBodyTextStyle } from 'src/components/Sidebar/Sidebar'

import OrganizationNextMeetingChip from '../OrganizationNextMeetingChip/OrganizationNextMeetingChip'

const OrganizationOneSentence = ({ org }: { org: Organization }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    org?.relationship?.oneSentenceSummary && (
      <Box sx={{ mb: 5 }}>
        <Row sx={{ justifyContent: 'space-between', pb: 1 }}>
          <Typography variant="h2">Status</Typography>
          <Row gap={1}>
            <OrganizationNextMeetingChip org={org} />
            {org?.relationship?.highLevelSummary && (
              <IconButton
                onClick={() => setExpanded(!expanded)}
                sx={{ p: '0px', mr: '-10px', borderRadius: '4px' }}
              >
                <RiArrowLeftSFill
                  style={{
                    transition: 'all 0.3s ease-in-out',
                    transform: expanded ? 'rotate(-90deg)' : 'rotate(0deg)',
                  }}
                  size={30}
                />
              </IconButton>
            )}
          </Row>
        </Row>
        {expanded ? (
          <List
            sx={{ p: 0 }}
            disablePadding={true}
          >
            {org?.relationship?.highLevelSummary?.map((item, index) => (
              <ListItem
                key={`summaryItem_${index}`}
                sx={{
                  py: 1,
                  px: 0,
                  '&:last-child': {
                    borderBottom: 'none',
                  },
                }}
                disableGutters={true}
              >
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography sx={sidebarBodyTextStyle}>
            {org?.relationship?.oneSentenceSummary}
          </Typography>
        )}
      </Box>
    )
  )
}

export default OrganizationOneSentence
