import { useEffect, useState } from 'react'

import { Box, IconButton, TextField, Tooltip } from '@mui/material'
import {
  RiCheckLine,
  RiCloseLine,
  RiGlobeLine,
  RiLink,
  RiStickyNote2Line,
} from '@remixicon/react'

import PageFinder from '../../Page/PageFinder/PageFinder'
import Row from '../../Row/Row'
import { tiptapBubbleSx } from '../styles'
const TiptapLinkEditor = ({ editor, setFocus }) => {
  const [linkUrl, setLinkUrl] = useState<string>('')
  const [linkText, setLinkText] = useState<string>('')
  const [isNewElement, setIsNewElement] = useState<boolean>(false)
  const [isPageMode, setIsPageMode] = useState<boolean>(true)

  const isUrl = (str) => {
    try {
      new URL(str)
      return true
    } catch (err) {
      return false
    }
    return false
  }

  const updateLinkUrl = (event) => {
    let rawUrl = event.target.value.trim()
    if (rawUrl.includes('.') && !rawUrl.includes('http')) {
      rawUrl = `https://${rawUrl}`
    }
    setLinkUrl(rawUrl)
  }

  // set class so selection continues looking selected even if focus moves to the link editor textfield(s):

  const handleSetLink = () => {
    const url = new URL(linkUrl)
    const linkObj = { href: url.href }

    if (isNewElement) {
      editor
        .chain()
        .focus()
        .insertContent({
          type: 'text',
          text: linkText,
          marks: [
            {
              type: 'link',
              attrs: linkObj,
            },
          ],
        })
        .run()
    } else {
      editor.chain().focus().setLink(linkObj).run()
    }
    setFocus(null)
    setLinkUrl('')
  }

  const handleChoosePage = (page) => {
    if (page?.id) {
      editor
        .chain()
        .focus()
        .insertContent({
          type: 'text',
          text: linkText || page.label,
          marks: [
            {
              type: 'link',
              attrs: {
                href: `${process.env.HOST}/page/${page.id}`,
                pageId: page.id,
              },
            },
          ],
        })
        .run()
      setFocus(null)
    }
  }

  useEffect(() => {
    if (editor.state.selection.empty && !(linkUrl?.length > 0)) {
      setIsNewElement(true)
      setLinkText('')
    } else if (!isNewElement && linkUrl?.length > 0 && linkText?.length === 0) {
      setLinkText(
        editor.state.doc.textBetween(
          editor.state.selection.from,
          editor.state.selection.to
        )
      )
    }
  }, [editor, linkUrl, linkText])

  return (
    <Box
      sx={{
        ...tiptapBubbleSx,
        py: 1,
        px: 2,
        flexDirection: 'column',
        width: '344px',
      }}
    >
      <>
        <Row sx={{ justifyContent: 'space-between', width: '100%' }}>
          <Row
            sx={{
              fontSize: '0.9rem',
              fontWeight: 600,
              textAlign: 'left',
              width: '100%',
            }}
          >
            <RiLink
              size={18}
              style={{ marginRight: '6px' }}
            />
            {`${isPageMode ? 'Link to Page' : 'Web Link'}`}
          </Row>
          <Row
            sx={{
              justifyContent: 'flex-end',
              '& .MuiIconButton-root': {
                borderRadius: '2px',
                p: '2px',
                '&.selected': {
                  backgroundColor: (theme) => theme.palette.secondary.main,
                  color: (theme) => theme.palette.secondary.contrastText,
                },
              },
            }}
          >
            <Tooltip
              title="Link to a Day.ai Page"
              arrow={true}
              placement="top"
            >
              <IconButton
                size="small"
                className={isPageMode ? 'selected' : ''}
                onClick={() => setIsPageMode(true)}
              >
                <RiStickyNote2Line size={18} />
              </IconButton>
            </Tooltip>

            <Tooltip
              title="Add an internet link"
              arrow={true}
              placement="top"
            >
              <IconButton
                size="small"
                className={!isPageMode ? 'selected' : ''}
                onClick={() => setIsPageMode(false)}
              >
                <RiGlobeLine size={18} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Cancel & return to editor"
              arrow={true}
              placement="top"
            >
              <IconButton
                size="small"
                onClick={() => setFocus(null)}
              >
                <RiCloseLine size={20} />
              </IconButton>
            </Tooltip>
          </Row>
        </Row>

        {!isPageMode && isUrl(linkUrl) && (
          <TextField
            label="Link text"
            variant="standard"
            size="small"
            fullWidth={true}
            sx={{ mb: 2, mt: 1 }}
            value={linkText}
            onChange={(e) => setLinkText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                if (isUrl(linkUrl)) handleSetLink()
                else document.getElementById('linkUrl')?.focus()
              }
            }}
          />
        )}
      </>
      <Row sx={{ width: '100%' }}>
        {isPageMode ? (
          <Box sx={{ mb: 1, width: '100%' }}>
            <PageFinder onSelect={handleChoosePage} />
          </Box>
        ) : (
          <>
            <TextField
              id="linkUrl"
              label="Destination URL"
              variant="standard"
              size="small"
              fullWidth={true}
              sx={{ mb: 1 }}
              value={linkUrl}
              onChange={updateLinkUrl}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSetLink()
                  e.preventDefault() // Prevent form submission
                }
              }}
            />
            <IconButton
              size="small"
              onClick={handleSetLink}
              disabled={!isUrl(linkUrl) || (isNewElement && !linkText)}
            >
              <RiCheckLine size={18} />
            </IconButton>
          </>
        )}
        {!isNewElement && (
          <IconButton
            size="small"
            onClick={() => setFocus(null)}
          >
            <RiCloseLine size={18} />
          </IconButton>
        )}
      </Row>
    </Box>
  )
}

export default TiptapLinkEditor
