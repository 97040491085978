import { routes } from '@redwoodjs/router'

export const statusCategories = {
  PENDING: 'pending',
  FAILED: 'failed',
  READY: 'ready',
}

export const statusMetadata = {
  JOINING_CALL: {
    label: 'Joining meeting',
    shortLabel: 'Joining',
    severity: 'info',
    category: statusCategories.PENDING,
    explanation: 'The Day.ai Assistant is joining the meeting.',
  },
  IN_WAITING_ROOM: {
    label: 'In waiting room',
    shortLabel: 'Waiting room',
    severity: 'info',
    category: statusCategories.PENDING,
    explanation:
      "The Day.ai Assistant is in the meeting's waiting room. An attendee with permission to manage the waiting room needs to allow it in.",
  },
  IN_CALL_NOT_RECORDING: {
    label: 'In meeting, not recording',
    shortLabel: 'Not recording',
    severity: 'warning',
    category: statusCategories.PENDING,
    explanation:
      'The Day.ai Assistant is in the meeting but has not started recording yet. It might be waiting for the meeting host to grant it permission to record.',
  },
  RECORDING_PERMISSION_ALLOWED: {
    label: 'Host allowed recording',
    shortLabel: 'Host allowed',
    severity: 'success',
    category: statusCategories.PENDING,
    explanation:
      'The host granted the Day.ai Assistant permission to start recording.',
  },
  RECORDING_PERMISSION_DENIED: {
    label: 'Host denied recording',
    shortLabel: 'Host denied',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The host denied the Day.ai Assistant permission to record the meeting. No recording will be made.',
  },
  IN_CALL_RECORDING: {
    label: 'In meeting, recording',
    shortLabel: 'Recording',
    severity: 'success',
    category: statusCategories.PENDING,
    explanation: 'Currently recording.',
  },
  PREPARING_RECORDING: {
    label: 'Preparing recording',
    shortLabel: 'Preparing',
    severity: 'info',
    category: statusCategories.PENDING,
    explanation:
      'The recording of the meeting has ended and is now being prepared for review.',
  },
  CALL_ENDED_WITHOUT_RECORDING: {
    label: 'Meeting ended without recording',
    shortLabel: "Didn't record",
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The meeting has ended, but no recording was made.\n\nThis could be due to permission issues, or the assistant not being allowed in from the waiting room.',
  },
  RECORDING_ERRORED: {
    label: 'Recording errored',
    shortLabel: 'Error',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation: 'Something went wrong while trying to record.',
  },
  PREPARING_TRANSCRIPT: {
    label: 'Preparing transcript',
    shortLabel: 'Preparing transcript',
    severity: 'info',
    category: statusCategories.PENDING,
    explanation: 'Day.ai is preparing the transcript for this recording.',
  },
  TRANSCRIPT_ERRORED: {
    label: 'Error creating transcript',
    shortLabel: 'Transcript error',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'An error occurred while preparing the transcript for this recording.',
  },
  PREPARING_SUMMARY: {
    label: 'Preparing meeting notes & summary',
    shortLabel: 'Preparing summary',
    severity: 'info',
    category: statusCategories.PENDING,
    explanation: 'Day.ai is preparing the summary for this recording.',
  },
  SUMMARY_ERRORED: {
    label: 'Error creating notes & summary',
    shortLabel: 'Summary errored',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'An error occurred while preparing the summary for this recording.',
  },
  READY: {
    label: 'Ready',
    shortLabel: 'Ready',
    severity: 'success',
    category: statusCategories.READY,
    explanation: 'Ready for review.',
  },
  HOST_DENIED_PERMISSION: {
    label: 'Host denied permission',
    shortLabel: 'Host denied',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The meeting host denied the Day.ai Assistant permission to record.',
  },
  HOST_DID_NOT_GRANT_PERMISSION: {
    label: 'Host did not grant permission',
    shortLabel: "Host didn't allow",
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      "The meeting host never granted the Day.ai assistant permission to record.\n\nThis might be because they weren't present in the meeting, or didn't respond to the assistant's request.",
  },
  FAILED_TO_START_RECORDING: {
    label: 'Something went wrong',
    shortLabel: 'Failed',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'Recording was permitted but failed to start due to technical issues or other unknown reasons.',
  },
  CALL_ENDED_BY_PLATFORM_WAITING_ROOM_TIMEOUT: {
    label: 'Not allowed into the meeting',
    shortLabel: 'Not allowed in',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The Day.ai Assistant waited in the waiting room but was never let in.',
  },
  BOT_KICKED_FROM_WAITING_ROOM: {
    label: 'Removed from waiting room',
    shortLabel: 'Removed',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The Day.ai Assistant was removed from the waiting room by an attendee.',
  },
  UNSPECIFIED_ISSUE: {
    label: 'Unspecified issue',
    shortLabel: 'Unspecified',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation: 'The meeting ended without recording.',
  },
  ZOOM_SDK_APP_NOT_PUBLISHED: {
    label: 'SDK app not published',
    shortLabel: 'SDK not published',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The Zoom SDK app required for the recording bot is not published, preventing the assistant from functioning properly.',
  },
  ZOOM_LOCAL_RECORDING_REQUEST_DENIED_BY_HOST: {
    label: 'Host denied permission',
    shortLabel: 'Host denied',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The meeting host denied the Day.ai Assistant permission to record.',
  },
  TIMEOUT_EXCEEDED_EVERYONE_LEFT: {
    label: 'Host did not grant permission',
    shortLabel: "Host didn't allow",
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      "The meeting host never granted the Day.ai assistant permission to record.\n\nThis might be because they weren't present in the meeting, or didn't respond to the assistant's request.",
  },
  ZOOM_LOCAL_RECORDING_GRANT_NOT_SUPPORTED: {
    label: "Recording permission not supported on host's device",
    shortLabel: 'Not supported',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      "The meeting host joined from a device that doesn't support recording permission, so the Day.ai Assistant couldn't record.",
  },
  TIMEOUT_EXCEEDED_RECORDING_PERMISSION_DENIED: {
    label: 'Host denied permission',
    shortLabel: 'Host denied',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The meeting host denied the Day.ai Assistant permission to record.',
  },
  TIMEOUT_EXCEEDED_IN_CALL_NOT_RECORDING: {
    label: 'Host did not grant permission',
    shortLabel: "Host didn't allow",
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      "The meeting host never granted the Day.ai assistant permission to record.\n\nThis might be because they weren't present in the meeting, or didn't respond to the assistant's request.",
  },
  ZOOM_LOCAL_RECORDING_DISABLED: {
    label: 'Host has recording disabled',
    shortLabel: 'Host disabled',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      "The meeting host has recording disabled in their account settings, so the Day.ai Assistant couldn't record.",
  },
  ZOOM_LOCAL_RECORDING_REQUEST_DISABLED: {
    label: 'Host has recording disabled',
    shortLabel: 'Host disabled',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      "The meeting host has recording disabled in their account settings, so the Day.ai Assistant couldn't record.",
  },
  ZOOM_HOST_NOT_PRESENT: {
    label: "Couldn't request permission",
    shortLabel: "Couldn't request",
    severity: 'warning',
    category: statusCategories.FAILED,
    explanation:
      'The Day.ai Assistant was unable to request permission to record, this is usually because the meeting host left just as it was trying to ask for permission.',
  },
  TIMEOUT_EXCEEDED_ONLY_BOTS_DETECTED_USING_PARTICIPANT_EVENTS: {
    label: 'Host did not grant permission',
    shortLabel: "Host didn't allow",
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      "The meeting host never granted the Day.ai assistant permission to record.\n\nThis might be because they weren't present in the meeting, or didn't respond to the assistant's request.",
  },
  TIMEOUT_EXCEEDED_WAITING_ROOM: {
    label: 'Not allowed into the meeting',
    shortLabel: 'Not allowed in',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation:
      'The Day.ai Assistant waited in the waiting room but was never let in.',
  },
  BOT_RECEIVED_LEAVE_CALL: {
    label: 'Removed by an attendee',
    shortLabel: 'Removed',
    severity: 'error',
    category: statusCategories.FAILED,
    explanation: 'The Day.ai Assistant was removed by an attendee.',
  },
}

export const addMeetingRecordingStatus = ({ meetingRecordings }) => {
  return meetingRecordings.map((recording) => {
    const finalState = computeFinalMetaState({ meetingRecording: recording })
    return {
      status: finalState,
      tab: statusMetadata[finalState].category,
      severity: statusMetadata[finalState].severity,
      ...recording,
    }
  })
}

export const getMeetingRecordingStatus = ({ meetingRecording }) => {
  const state = computeFinalMetaState({ meetingRecording })
  return statusMetadata[state]
}

export const computeFinalMetaState = ({ meetingRecording }) => {
  const statusHistory =
    meetingRecording?.statusHistory?.length > 0
      ? [...meetingRecording.statusHistory]
      : []

  if (statusHistory.length === 0) {
    return 'UNSPECIFIED_ISSUE'
  }
  // Sort the statusHistory array by createdAt timestamp in descending order to get the most recent event first
  statusHistory.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )

  // Check the most recent status
  const mostRecentStatus = statusHistory[0].status
  const mostRecentEvent = statusHistory[0]

  if (
    [
      'CALL_ENDED_BY_PLATFORM_WAITING_ROOM_TIMEOUT',
      'ZOOM_SDK_APP_NOT_PUBLISHED',
      'ZOOM_LOCAL_RECORDING_REQUEST_DENIED_BY_HOST',
      'TIMEOUT_EXCEEDED_EVERYONE_LEFT',
      'ZOOM_LOCAL_RECORDING_GRANT_NOT_SUPPORTED',
      'TIMEOUT_EXCEEDED_RECORDING_PERMISSION_DENIED',
      'TIMEOUT_EXCEEDED_IN_CALL_NOT_RECORDING',
      'TIMEOUT_EXCEEDED_WAITING_ROOM',
      'ZOOM_LOCAL_RECORDING_DISABLED',
      'ZOOM_LOCAL_RECORDING_REQUEST_DISABLED',
      'ZOOM_HOST_NOT_PRESENT',
      'TIMEOUT_EXCEEDED_ONLY_BOTS_DETECTED_USING_PARTICIPANT_EVENTS',
      'BOT_KICKED_FROM_WAITING_ROOM',
      'BOT_RECEIVED_LEAVE_CALL',
    ].includes(mostRecentEvent?.reason) &&
    mostRecentStatus != 'PREPARING_RECORDING'
  ) {
    return mostRecentEvent?.reason
  }

  if (mostRecentStatus === 'PREPARING_RECORDING') {
    if (meetingRecording?.transcript?.status) {
      if (meetingRecording?.transcript?.status === 'PREPARING') {
        return 'PREPARING_TRANSCRIPT'
      }
      if (meetingRecording?.transcript?.status === 'ERRORED') {
        return 'TRANSCRIPT_ERRORED'
      }
      if (meetingRecording?.summary?.status === 'READY') {
        if (meetingRecording?.summary?.status === 'PREPARING') {
          return 'PREPARING_SUMMARY'
        }
        if (meetingRecording?.summary?.status === 'ERRORED') {
          return 'SUMMARY_ERRORED'
        }
        // summaryStatus === 'READY' should mean overall status is READY
      }
    }
  }

  // If the most recent status is not 'CALL_ENDED_WITHOUT_RECORDING', return it directly
  // this includes 'PREPARING_RECORDING' and 'READY'
  if (mostRecentStatus !== 'CALL_ENDED_WITHOUT_RECORDING') {
    return mostRecentStatus
  }

  // If the call ended without recording, determine the appropriate meta state
  // Check for specific conditions in the history
  const permissionDenied = statusHistory.some(
    (event) => event.status === 'RECORDING_PERMISSION_DENIED'
  )
  const permissionAllowed = statusHistory.some(
    (event) => event.status === 'RECORDING_PERMISSION_ALLOWED'
  )
  const inCallNotRecording = statusHistory.some(
    (event) => event.status === 'IN_CALL_NOT_RECORDING'
  )
  const inCallRecording = statusHistory.some(
    (event) => event.status === 'IN_CALL_RECORDING'
  )

  // Determine meta state based on conditions
  if (permissionDenied) {
    return 'HOST_DENIED_PERMISSION'
  } else if (!permissionAllowed && inCallNotRecording) {
    return 'HOST_DID_NOT_GRANT_PERMISSION'
  } else if (permissionAllowed && !inCallRecording) {
    return 'FAILED_TO_START_RECORDING'
  } else {
    // Other specific logic or default meta state can be added here
    return 'UNSPECIFIED_ISSUE' // A fallback state, adjust as necessary
  }
}

export const videoTitle = (meetingRecording) => {
  const finalState = computeFinalMetaState({ meetingRecording })
  if (statusMetadata[finalState].category === statusCategories.READY) {
    return (
      meetingRecording.calendarEvents[0]?.GoogleEvent?.title ||
      meetingRecording.title
    )
  } else {
    return `"${meetingRecording.title}" - ${statusMetadata[finalState].label}`
  }
}

export const videoSubtitle = (meetingRecording) => {
  const finalState = computeFinalMetaState({ meetingRecording })
  if (statusMetadata[finalState].category === statusCategories.READY)
    return (
      meetingRecording.summary?.output?.Suggested_Title ||
      meetingRecording.title
    )
  else return statusMetadata[finalState].explanation
}

export const recordingLink = ({ workspaceId = null, meetingRecording }) => {
  const finalState = computeFinalMetaState({ meetingRecording })
  if (finalState === 'READY') {
    if (workspaceId) {
      return routes.workspaceMeetingRecording({
        workspaceId,
        recordingId: meetingRecording.id,
      })
    } else {
      return routes.reviewMeeting({ id: meetingRecording.id })
    }
  } else {
    if (workspaceId) {
      return routes.workspaceMeetingRecordingAudit({
        workspaceId,
        recordingId: meetingRecording.id,
      })
    } else {
      return routes.recordingAudit({ id: meetingRecording.id })
    }
  }
}
