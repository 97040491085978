import { useState } from 'react'

import { Dialog, DialogContent } from '@mui/material'

import ObjectFinder from '../../ObjectFinder/ObjectFinder'
import ObjectFinderActions from '../../ObjectFinderActions/ObjectFinderActions'

const TiptapBlockBySearch = ({ open, editor, onClose }) => {
  const [selection, setSelection] = useState<any>(null)

  const handleAddToPage = (block) => {
    const transaction = editor.state.tr.insert(
      editor.state.selection.from,
      editor.schema.nodeFromJSON(block)
    )
    editor.view.dispatch(transaction)

    const endPosition = transaction.selection.$anchor.end()
    editor.chain().focus().setTextSelection(endPosition).run()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogContent
        sx={{
          width: '600px',
          maxWidth: '600px',
          height: '480px',
          maxHeight: '480px',
          p: 0,
          pt: '12px',
        }}
      >
        <>
          {selection ? (
            <ObjectFinderActions
              resultObject={selection}
              onDeselect={() => setSelection(null)}
              forceDisplayMode={'blocks'}
              addToPage={handleAddToPage}
            />
          ) : (
            <ObjectFinder
              onSelect={(resultObject) => setSelection(resultObject)}
              onChangeQuery={() => {}}
            />
          )}
        </>
      </DialogContent>
    </Dialog>
  )
}

export default TiptapBlockBySearch
