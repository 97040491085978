import Blockquote from '@tiptap/extension-blockquote'
import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import Code from '@tiptap/extension-code'
import CodeBlock from '@tiptap/extension-code-block'
import Document from '@tiptap/extension-document'
import Dropcursor from '@tiptap/extension-dropcursor'
import Gapcursor from '@tiptap/extension-gapcursor'
import HardBreak from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import History from '@tiptap/extension-history'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Image from '@tiptap/extension-image'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
import Strike from '@tiptap/extension-strike'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Text from '@tiptap/extension-text'
import Youtube from '@tiptap/extension-youtube'
import StarterKit from '@tiptap/starter-kit'

import TiptapNodeClip from 'src/components/Tiptap/TiptapNodeClip/TiptapNodeClip'
import TiptapNodeMeetingRecording from 'src/components/Tiptap/TiptapNodeMeetingRecording/TiptapNodeMeetingRecording'
import TiptapNodeMeetingRecordingActionItems from 'src/components/Tiptap/TiptapNodeMeetingRecordingActionItems/TiptapNodeMeetingRecordingActionItems'
import TiptapNodeOrganization from 'src/components/Tiptap/TiptapNodeOrganization/TiptapNodeOrganization'
import TiptapNodePerson from 'src/components/Tiptap/TiptapNodePerson/TiptapNodePerson'
import TiptapNodePersonTable from 'src/components/Tiptap/TiptapNodePersonTable/TiptapNodePersonTable'
import TiptapNodePipeline from 'src/components/Tiptap/TiptapNodePipeline/TiptapNodePipeline'
import TiptapNodeTableBasic from 'src/components/Tiptap/TiptapNodeTableBasic/TiptapNodeTableBasic'
import { TiptapObjectChip } from 'src/components/Tiptap/TiptapObjectChip/TiptapObjectChip'
import TiptapTitle from 'src/components/Tiptap/TiptapTitle/TiptapTitle'

const DocumentWithRequiredTitle = Document.extend({
  content: 'title block*',
})

export const extensions = [
  DocumentWithRequiredTitle,
  Bold,
  Code,
  Italic,
  Strike,
  Gapcursor,
  History,
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading.configure({ levels: [1, 2, 3, 4, 5, 6] }),
  HorizontalRule,
  Image,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Text,
  TaskList,
  TaskItem.configure({
    nested: true,
  }),
  TiptapNodePerson,
  TiptapNodePersonTable,
  TiptapNodeClip,
  TiptapNodeMeetingRecording,
  TiptapNodeOrganization,
  TiptapObjectChip,
  TiptapTitle,
  TiptapNodeMeetingRecordingActionItems,
  TiptapNodeTableBasic,
  TiptapNodePipeline,
  TableCell,
  TableHeader,
  TableRow,
  Dropcursor,
  Placeholder.configure({
    placeholder: ({ node }) => {
      if (node.type.name === 'title') {
        return 'Enter a title...'
      }
      if (node.type.name === 'paragraph') {
        return 'Begin typing here...'
      }
      return 'Start writing...'
    },
  }),
  Youtube.configure({
    modestBranding: true,
    origin: process.env.HOST.includes('localhost')
      ? 'localhost:8910'
      : 'day.ai',
  }),
]

export const extensionsObjectSearchResult = [
  Document,
  Bold,
  Code,
  Italic,
  Strike,
  Gapcursor,
  History,
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading.configure({ levels: [1, 2, 3, 4, 5, 6] }),
  HorizontalRule,
  Image,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Text,
  TaskList,
  TaskItem.configure({
    nested: true,
  }),
  TiptapNodePerson,
  TiptapNodePersonTable,
  TiptapNodeClip,
  TiptapNodeMeetingRecording,
  TiptapNodeOrganization,
  TiptapObjectChip,
  TiptapNodeMeetingRecordingActionItems,
  TiptapNodeTableBasic,
  TiptapNodePipeline,
  TableCell,
  TableHeader,
  TableRow,
  Dropcursor,
  Youtube.configure({
    modestBranding: true,
    origin: process.env.HOST.includes('localhost')
      ? 'localhost:8910'
      : 'day.ai',
  }),
]

export const extensionsMeetingRecordingActionItems = [
  StarterKit,
  TiptapObjectChip,
  TaskList,
  TaskItem.configure({
    nested: true,
  }),
]
