export const buildGmailNewDraftUrl = ({
  to,
  body,
  subject,
  account,
}: {
  to: string
  body: string
  subject: string
  account?: string
}) => {
  const toEncoded = encodeURIComponent(to)
  const bodyEncoded = encodeURIComponent(body)
  const subjectEncoded = encodeURIComponent(subject)
  const accountEncoded = account
    ? `&authuser=${encodeURIComponent(account)}`
    : ''
  return `https://mail.google.com/mail/?view=cm&fs=1&to=${toEncoded}&body=${bodyEncoded}&su=${subjectEncoded}${accountEncoded}`
}
