import { useContext, useMemo, useState } from 'react'

import { Box, Chip, darken, Tooltip } from '@mui/material'
import { RiAddLine, RiBarChartFill } from '@remixicon/react'
import { Organization } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import Row from 'src/components/Row/Row'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'
import {
  NativeSuggestedPipelineStages,
  NativeSuggestedPipelineTypes,
} from 'src/lib/relationshipSummary'

import OpportunityCreateDialog from '../OpportunityCreateDialog/OpportunityCreateDialog'
import { getStageColor, getStageStyle } from '../Pipeline'

const SHOW_SUGGESTED_OPP = false

const OpportunityDomainChip = ({
  organization,
  size = 'medium',
  direction = 'row',
  openSidebar = false,
}: {
  organization: Organization
  size?: 'small' | 'medium'
  direction?: 'row' | 'column'
  openSidebar?: boolean
}) => {
  const { setSidebarObject, pipelines } = useContext(DayContext)
  const [oppToCreate, setOppToCreate] = useState<Organization | null>(null)

  const suggestedStage = organization?.overview?.['status/recommendedStage']
  const existingOpportunities = useMemo(() => {
    if (!pipelines && !organization?.domain) return []

    const opps = []
    for (const pipeline of pipelines.filter(Boolean) || []) {
      for (const stage of pipeline.stages.filter(Boolean) || []) {
        for (const opportunity of stage.opportunities.filter(Boolean) || []) {
          if (opportunity?.domain === organization?.domain) {
            opps.push({
              id: opportunity.id,
              title: opportunity.title,
              type: opportunity.type,
              pipelineTitle: pipeline.title,
              pipelineId: pipeline.id,
              stagePosition: stage.position,
              stageTitle: stage.title,
              workspaceId: pipeline.workspaceId,
              crmObject: {
                objectId: opportunity.id,
                objectType: NativeObjectTypes.Opportunity,
                properties: {
                  ...opportunity,
                  pipelineId: pipeline.id,
                  hasRevenue: pipeline.hasRevenue,
                },
              },
            })
          }
        }
      }
    }

    return opps
  }, [pipelines, organization])

  const OpportunityChip = ({
    opportunity,
    index,
  }: {
    opportunity: any
    index: number
  }) => {
    return (
      <Box className="opportunity-chip">
        <Tooltip
          title={`${opportunity.type} opp in the "${opportunity.stageTitle}" stage of the "${opportunity.pipelineTitle}" pipeline`}
          arrow={true}
        >
          <Chip
            clickable={true}
            onClick={() => {
              if (openSidebar) {
                setSidebarObject(opportunity.crmObject)
              } else {
                navigate(
                  routes.objectDetail({
                    objectId: opportunity.pipelineId,
                    objectTypeSlug: 'pipelines',
                    workspaceId: opportunity.workspaceId,
                  })
                )
              }
            }}
            size={size}
            icon={
              <RiBarChartFill
                size={14}
                style={{
                  color: darken(getStageColor(opportunity.stagePosition), 0.1),
                  marginRight: '0px',
                  flexShrink: 0,
                }}
              />
            }
            key={`opportunity_${index}`}
            label={`${opportunity.pipelineTitle} in ${opportunity.stageTitle}`}
            sx={{
              pl: '0px',
              fontWeight: 500,
              fontSize: '0.7rem',
              width: '164px',
              minWidth: '164px',
              maxWidth: '164px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              ...getStageStyle(opportunity.stagePosition),
            }}
          />
        </Tooltip>
      </Box>
    )
  }

  return (
    <>
      {existingOpportunities?.length > 0 ? (
        <Row
          sx={{
            width: '100%',
            flexDirection: direction,
            justifyContent: 'flex-end',
            pl: direction === 'row' ? '140px' : '0px',
            '.opportunity-chip': {
              ml: direction === 'row' ? '-140px' : '0px',
              transition: 'all 0.2s ease-in-out',
            },
            '&:hover': {
              '.opportunity-chip': {
                ml: direction === 'row' ? '8px' : '0px',
              },
            },
            transition: 'all 0.2s ease-in-out',
          }}
        >
          {existingOpportunities?.map((opportunity, index) => (
            <OpportunityChip
              opportunity={opportunity}
              index={index}
              key={`opportunity_${index}`}
            />
          ))}
          <Chip
            size={size}
            variant="outlined"
            icon={<RiAddLine size={16} />}
            onClick={() => {
              setOppToCreate(organization)
            }}
            label="New Opp"
            sx={{ ml: 1, pl: '4px', fontSize: '0.7rem', fontWeight: 500 }}
          />
        </Row>
      ) : (
        <>
          {SHOW_SUGGESTED_OPP && suggestedStage ? (
            <Chip
              clickable={true}
              onClick={() => {}}
              size={size}
              icon={
                <RiBarChartFill
                  size={14}
                  style={{
                    color: getStageColor(suggestedStage.stageNumber, true),
                    marginRight: '2px',
                  }}
                />
              }
              key={`suggestedOpportunity`}
              label={`${
                NativeSuggestedPipelineTypes?.[suggestedStage.pipelineType]
                  .label
              } (${
                NativeSuggestedPipelineStages?.[suggestedStage.stage].label
              })`}
              sx={{
                pl: '4px',
                fontWeight: 500,
                fontSize: '0.7rem',
                ...getStageStyle(suggestedStage.stageNumber, true),
              }}
            />
          ) : (
            organization && (
              <Chip
                size={size}
                variant="filled"
                color="secondary"
                icon={<RiAddLine size={16} />}
                onClick={() => {
                  setOppToCreate(organization)
                }}
                label="Opportunity"
                sx={{ pl: '4px', fontSize: '0.7rem', fontWeight: 500 }}
              />
            )
          )}
        </>
      )}
      {oppToCreate && (
        <OpportunityCreateDialog
          organization={oppToCreate}
          onClose={() => {
            setOppToCreate(null)
          }}
        />
      )}
    </>
  )
}

export default OpportunityDomainChip
