import { useEffect, useState } from 'react'

import { Box, Button, Tooltip } from '@mui/material'
import {
  RiBold,
  RiH1,
  RiH2,
  RiH3,
  RiIndentDecrease,
  RiIndentIncrease,
  RiItalic,
  RiLink,
  RiListCheck,
  RiListCheck3,
  RiListOrdered2,
  RiSeparator,
  RiSingleQuotesL,
  RiStrikethrough,
} from '@remixicon/react'
import { BubbleMenu, isTextSelection } from '@tiptap/react'

import TiptapLinkEditor from 'src/components/Tiptap/TiptapLinkEditor/TiptapLinkEditor'
import { logger } from 'src/lib/logger'

import { tiptapBubbleSx, tiptapButtonSx } from '../styles'
import TiptapTableActions from '../TiptapTableActions/TiptapTableActions'

const tooltipDirection = 'top'

const TiptapBubbleMenu = ({ editor }) => {
  const [selectedText, setSelectedText] = useState<boolean>(false)
  const [contextMenuTriggered, setContextMenuTriggered] =
    useState<boolean>(false)
  const [showLinkChooser, setShowLinkChooser] = useState<boolean>(false)
  const [inTable, setInTable] = useState<boolean>(false)

  const handleChooseLink = () => {
    setShowLinkChooser(true)
  }

  const closeLinkChooser = () => {
    setShowLinkChooser(false)
  }

  const handleContextMenu = (event) => {
    const { button, ctrlKey, metaKey } = event
    logger.dev({ button, ctrlKey, metaKey })
    if (
      button === 2 ||
      (metaKey && button === 0) ||
      (ctrlKey && button === 0)
    ) {
      logger.dev({ contextMenuTriggered })
      event.preventDefault()
      // Logic to show BubbleMenu
      setContextMenuTriggered(true)
    } else {
      setContextMenuTriggered(false)
    }
  }

  useEffect(() => {
    if (false) {
      window.addEventListener('contextmenu', handleContextMenu)
      return () => {
        window.removeEventListener('contextmenu', handleContextMenu)
      }
    }
  }, [])

  return (
    editor && (
      <BubbleMenu
        className="bubble-menu"
        tippyOptions={{ duration: 100, zIndex: 10, maxWidth: 400 }}
        editor={editor}
        shouldShow={({ editor, view, state, from, to }) => {
          logger.dev('shouldShow running')
          if (contextMenuTriggered) return true

          const textSelectedDetector = () => {
            if (!view.hasFocus()) return false
            const { doc, selection } = state
            const isText = isTextSelection(selection)
            if (!isText) return false
            const isEmpty =
              selection.empty ||
              (isText && doc.textBetween(from, to).length === 0)
            if (isEmpty) return false
            if (editor.isActive('day-ai-person')) return false
            return true
          }

          const textSelected = textSelectedDetector()
          setSelectedText(textSelected)
          const isInTable = editor.isActive('day-ai-table-text')
          setInTable(isInTable)
          const shouldShow = textSelected //|| isInTable
          return shouldShow
        }}
      >
        {!showLinkChooser ? (
          <Box sx={{ ...tiptapBubbleSx }}>
            <Box>
              <Tooltip
                title="Write an H1 heading"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={() => {
                    editor.chain().focus().toggleHeading({ level: 1 }).run()
                  }}
                  className={
                    editor.isActive('heading', { level: 1 }) ? 'is-active' : ''
                  }
                >
                  <RiH1 />
                </Button>
              </Tooltip>

              <Tooltip
                title="Write an H2 heading"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={() =>
                    editor.chain().focus().toggleHeading({ level: 2 }).run()
                  }
                  className={
                    editor.isActive('heading', { level: 2 }) ? 'is-active' : ''
                  }
                >
                  <RiH2 />
                </Button>
              </Tooltip>

              <Tooltip
                title="Write an H3 heading"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={() =>
                    editor.chain().focus().toggleHeading({ level: 3 }).run()
                  }
                  className={
                    editor.isActive('heading', { level: 3 }) ? 'is-active' : ''
                  }
                >
                  <RiH3 />
                </Button>
              </Tooltip>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: selectedText ? 'inline-flex' : 'none',
                }}
                size="small"
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={editor.isActive('bold') ? 'is-active' : ''}
              >
                <RiBold />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: selectedText ? 'inline-flex' : 'none',
                }}
                size="small"
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={editor.isActive('italic') ? 'is-active' : ''}
              >
                <RiItalic />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: selectedText ? 'inline-flex' : 'none',
                }}
                size="small"
                onClick={() => editor.chain().focus().toggleStrike().run()}
                className={editor.isActive('strike') ? 'is-active' : ''}
              >
                <RiStrikethrough />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: selectedText ? 'inline-flex' : 'none',
                }}
                size="small"
                onClick={
                  editor.isActive('link')
                    ? () => editor.chain().focus().unsetLink().run()
                    : handleChooseLink
                }
                className={editor.isActive('link') ? 'is-active' : ''}
              >
                <RiLink />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: inTable ? 'none' : 'inline-flex',
                }}
                size="small"
                onClick={() => editor.chain().focus().toggleTaskList().run()}
                className={editor.isActive('taskList') ? 'is-active' : ''}
              >
                <RiListCheck3 />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: inTable ? 'none' : 'inline-flex',
                }}
                size="small"
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'is-active' : ''}
              >
                <RiListCheck />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: inTable ? 'none' : 'inline-flex',
                }}
                size="small"
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'is-active' : ''}
              >
                <RiListOrdered2 />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: inTable ? 'none' : 'inline-flex',
                }}
                size="small"
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'is-active' : ''}
              >
                <RiSingleQuotesL />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: inTable ? 'none' : 'inline-flex',
                }}
                size="small"
                onClick={() => editor.chain().focus().setHorizontalRule().run()}
              >
                <RiSeparator />
              </Button>
              {inTable && <TiptapTableActions editor={editor} />}

              {editor.isActive('orderedList') ||
                (editor.isActive('bulletList') && (
                  <>
                    <Button
                      sx={tiptapButtonSx}
                      size="small"
                      onClick={() =>
                        editor.chain().focus().sinkListItem('listItem').run()
                      }
                      disabled={!editor.can().sinkListItem('listItem')}
                    >
                      <RiIndentIncrease />
                    </Button>
                    <Button
                      sx={tiptapButtonSx}
                      size="small"
                      onClick={() =>
                        editor.chain().focus().liftListItem('listItem').run()
                      }
                      disabled={!editor.can().liftListItem('listItem')}
                    >
                      <RiIndentDecrease />
                    </Button>
                  </>
                ))}
            </Box>
          </Box>
        ) : (
          <TiptapLinkEditor
            editor={editor}
            setFocus={(_) => closeLinkChooser()}
          />
        )}

        {/*
  - <more> expander
  - history pattern undo/redo
  - make link
  - make image
  - make table
  - make board
  - make code block
  - make heading
    */}
      </BubbleMenu>
    )
  )
}

export default TiptapBubbleMenu
