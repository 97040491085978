import { useContext } from 'react'

import { Avatar, Box, Chip } from '@mui/material'

import { DayContext } from 'src/lib/dayContext'
import { useQuery } from '@redwoodjs/web'

const GET_WORKSPACE_MEMBER_BY_ID = gql`
  query GetWorkspaceMemberById($workspaceId: String!, $userId: String!) {
    workspaceMemberById(workspaceId: $workspaceId, userId: $userId)
  }
`

const WorkspaceMemberChip = ({
  userId,
  label = null,
  workspaceId,
}: {
  userId: string
  label?: string
  workspaceId?: string
}) => {
  const { selectedWorkspace, workspaces } = useContext(DayContext)
  const workspace = workspaces.find((w) => w.id === selectedWorkspace)
  const member = workspace?.members.find((m) => m.id === userId)

  const { data } = useQuery(GET_WORKSPACE_MEMBER_BY_ID, {
    variables: {
      workspaceId,
      userId,
    },
    skip: !workspaceId || !userId || !!member,
  })

  const workspaceMember = data?.workspaceMemberById

  const name =
    workspaceMember?.fullName ||
    `${member?.coreContact?.firstName} ${member?.coreContact?.lastName}` ||
    ''

  const photoUrl = workspaceMember?.photoUrl || member?.coreContact?.photo || ''

  return (
    (name || photoUrl) && (
      <Chip
        label={
          <>
            <Box component="span">{name}</Box>
            {label && (
              <Box
                component="span"
                sx={{ fontWeight: 600, ml: 1 }}
              >
                {label}
              </Box>
            )}
          </>
        }
        size="small"
        avatar={<Avatar src={photoUrl} />}
        variant="outlined"
        sx={{
          width: '100%',
          border: 'none',
          justifyContent: 'flex-start',
          px: '0px',
          '& .MuiChip-avatar': {
            ml: 0,
            mr: '-2px',
            height: '14px',
            width: '14px',
          },
        }}
      />
    )
  )
}

export default WorkspaceMemberChip
