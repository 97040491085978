import { useContext, useEffect, useMemo, useState } from 'react'

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material'
import toast from 'react-hot-toast'
import {
  Action,
  CreateWorkspaceUserContextInput,
  Opportunity,
  UpdateActionInput,
} from 'types/graphql'

import { DayContext } from 'src/lib/dayContext'

import AvatarRow from '../../AvatarRow/AvatarRow'
import ContactTile from '../../ContactTile/ContactTile'
import OpportunityChip from '../../OpportunityChip/OpportunityChip'
import OrganizationTile from '../../Organizations/OrganizationTile/OrganizationTile'
import Row from '../../Row/Row'
import ActionActions from '../ActionActions/ActionActions'
import ActionChannelChip, {
  ActionChannelType,
} from '../ActionChannelChip/ActionChannelChip'
import ActionStatusChip from '../ActionStatusChip/ActionStatusChip'
import ActionTypeChip from '../ActionTypeChip/ActionTypeChip'
import InboxActionDetail from 'src/components/Inbox/InboxActionDetail'
import InboxSidebar from 'src/components/Inbox/InboxSidebar'
import { useMutation } from '@redwoodjs/web'
import { useAuth } from 'src/auth'

const UPDATE_ACTION_FROM_DIALOG = gql`
  mutation UpdateActionFromDialog($input: UpdateActionInput!) {
    updateActionByUser(input: $input)
  }
`

const ADD_CONTEXT_TO_ACTION_FROM_DIALOG = gql`
  mutation AddContextToActionFromDialog(
    $input: CreateWorkspaceUserContextInput!
  ) {
    createWorkspaceUserContext(input: $input) {
      id
    }
  }
`

const ActionDialog = ({
  action,
  opportunities,
  onUpdate,
  onClose,
}: {
  action: Action
  opportunities?: Opportunity[]
  onUpdate: () => void
  onClose: () => void
}) => {
  const { currentUser } = useAuth()
  const { internalDomains } = useContext(DayContext)
  const [draftOpen, setDraftOpen] = useState<boolean | null>(null)

  const handleCopyDraftToClipboard = () => {
    toast.success('Copied to clipboard')
    navigator.clipboard.writeText(action.draft.body)
  }

  const [updateAction] = useMutation(UPDATE_ACTION_FROM_DIALOG)
  const [addContextToAction] = useMutation(ADD_CONTEXT_TO_ACTION_FROM_DIALOG)

  const handleUpdateAction = (input: UpdateActionInput) => {
    updateAction({ variables: { input } })
    onUpdate()
  }

  const handleAddContextToAction = (input: CreateWorkspaceUserContextInput) => {
    addContextToAction({ variables: { input } })
  }

  const hasSlackDraft = useMemo(() => {
    if (!action) return false
    return (
      action.channel?.type === ActionChannelType.SLACK &&
      action.draft?.body &&
      action.channel?.id &&
      action.channel?.accountId
    )
  }, [action])

  useEffect(() => {
    if (hasSlackDraft && draftOpen === null) {
      setDraftOpen(true)
    }
  }, [action, hasSlackDraft, draftOpen])

  const useNew = true

  return (
    action && (
      <Dialog
        open={!!action}
        onClose={onClose}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogContent sx={{ py: useNew ? 0 : 3 }}>
          {useNew ? (
            <Row
              sx={{ height: '100%', width: '100%', alignItems: 'flex-start' }}
              gap={3}
            >
              <InboxActionDetail
                selectedAction={action}
                refetch={() => {
                  onUpdate()
                }}
                addContext={handleAddContextToAction}
                type={action?.type}
                updateAction={handleUpdateAction}
                onHide={onClose}
              />
              <InboxSidebar
                selectedAction={action}
                width={272}
              />
            </Row>
          ) : (
            <>
              {!draftOpen && (
                <>
                  <Typography
                    variant="h3"
                    sx={{ mb: 2 }}
                  >
                    {action?.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mb: 4 }}
                  >
                    {action?.reasoning}
                  </Typography>
                  <Row
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'top',
                      mt: 3,
                    }}
                    gap={3}
                  >
                    <Box>
                      {action.people?.length > 0 && (
                        <>
                          {action.people.length < 4 ? (
                            <>
                              {action.people.map((person) => {
                                const email =
                                  typeof person === 'string'
                                    ? person
                                    : person.email
                                return (
                                  <Box
                                    key={`person-${email}-action-${action.id}`}
                                    sx={{ mb: 3 }}
                                  >
                                    <ContactTile
                                      key={`person-${email}-action-${action.id}`}
                                      email={email}
                                      size={48}
                                    />
                                  </Box>
                                )
                              })}
                            </>
                          ) : (
                            <AvatarRow
                              emails={action.people?.map(
                                (person) => person.email
                              )}
                              size={48}
                            />
                          )}
                        </>
                      )}
                      {action.organizations
                        ?.filter(
                          (org) =>
                            !!org?.domain &&
                            !internalDomains.includes(org.domain)
                        )
                        .map((organization) => (
                          <Box
                            key={`organization-${organization.domain}-action-${action.id}`}
                            sx={{ mb: 3 }}
                          >
                            <OrganizationTile
                              domain={organization.domain}
                              openSidebar={false}
                            />
                          </Box>
                        ))}
                    </Box>
                    <Row
                      sx={{
                        justifyContent: 'flex-start',
                        alignItems: 'top',
                        flexDirection: 'column',
                        width: '212px',
                        flexShrink: 0,
                      }}
                      gap={2}
                    >
                      <ActionActions
                        action={action}
                        showButtons={true}
                        onUpdate={onUpdate}
                      />
                      <ActionTypeChip type={action?.type} />
                      <ActionStatusChip status={action?.status} />
                      {opportunities?.map((opportunity) => (
                        <OpportunityChip
                          key={`opportunity-${opportunity.id}-action-${action.id}`}
                          opportunity={opportunity}
                        />
                      ))}

                      <ActionChannelChip
                        action={action}
                        width="100%"
                      />
                      {hasSlackDraft && (
                        <Chip
                          size="small"
                          color="secondary"
                          label="Open Draft Message"
                          onClick={() => setDraftOpen(true)}
                        />
                      )}
                    </Row>
                  </Row>
                </>
              )}
              {draftOpen && hasSlackDraft && (
                <>
                  <Row sx={{ justifyContent: 'space-between' }}>
                    <Typography variant="h3">Draft message</Typography>
                    <Button onClick={() => setDraftOpen(false)}>Dismiss</Button>
                  </Row>
                  <TextField
                    value={action.draft.body}
                    multiline={true}
                    fullWidth={true}
                    sx={{ mb: 2 }}
                    onClick={handleCopyDraftToClipboard}
                  />
                  <Row gap={2}>
                    <Button
                      variant="outlined"
                      onClick={handleCopyDraftToClipboard}
                      fullWidth={true}
                    >
                      Copy to Clipboard
                    </Button>
                    <Button
                      onClick={() => {
                        window.open(
                          `slack://channel?id=${action.channel.id}&team=${action.channel.accountId}`
                        )
                      }}
                      variant="outlined"
                      fullWidth={true}
                    >
                      Open #{action.channel.label}
                    </Button>
                  </Row>
                </>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    )
  )
}

export default ActionDialog
