import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { Box } from '@mui/material'
import MuxPlayer from '@mux/mux-player-react/lazy'

import { logger } from 'src/lib/logger'

interface PlayerContainerProps {
  playbackId: string
  tokens?: {
    video?: string
    thumbnail?: string
    storyboard?: string
  }
  seekTo?: number
  onPlayheadChange?: (number) => void
  sendPlayerEvent?: (any) => void
  metadata?: {
    [k: string]: any
  }
  style?: React.CSSProperties
  spacebarPlayPause?: boolean
}

interface PlayerContainerRef {
  play: () => void
  pause: () => void
}

const PlayerContainer = forwardRef<PlayerContainerRef, PlayerContainerProps>(
  (
    {
      playbackId,
      tokens,
      seekTo,
      onPlayheadChange = (seekTime) => {},
      sendPlayerEvent = (event) => {},
      metadata,
      style,
      spacebarPlayPause = true,
    },
    ref
  ) => {
    // track state of whether user mouse is down:
    const [isMouseDown, setIsMouseDown] = useState(false)

    useEffect(() => {
      const handleKeyDown = (event) => {
        // Check if the pressed key is the space bar and no input/form field is focused
        if (
          spacebarPlayPause &&
          event.code === 'Space' &&
          !['INPUT', 'TEXTAREA', 'SELECT'].includes(
            document.activeElement.tagName
          ) &&
          !document.activeElement.classList.contains('tiptap')
        ) {
          event.preventDefault() // Prevent the default action to avoid scrolling the page
          if (playerRef.current) {
            const isPaused = playerRef.current.paused
            if (isPaused) {
              playerRef.current.play()
            } else {
              playerRef.current.pause()
            }
          }
        }
      }

      // Add the event listener to the document
      document.addEventListener('keydown', handleKeyDown)

      // Remove the event listener on cleanup
      return () => {
        document.removeEventListener('keydown', handleKeyDown)
      }
    }, [])

    const playerRef = useRef(null)

    useImperativeHandle(ref, () => ({
      play: () => {
        if (playerRef.current) {
          playerRef.current.play()
        }
      },
      pause: () => {
        if (playerRef.current) {
          playerRef.current.pause()
        }
      },
      // add the .paused boolean value
      paused: () => {
        if (playerRef.current) {
          return playerRef.current.paused
        }
      },
    }))

    useEffect(() => {
      if (seekTo) {
        logger.dev(`Player seeks to ${seekTo}`)
      }
    }, [seekTo])

    return (
      <Box
        onMouseDown={() => setIsMouseDown(true)}
        onMouseUp={() => setIsMouseDown(false)}
      >
        <MuxPlayer
          ref={playerRef}
          streamType="on-demand"
          currentTime={seekTo}
          onTimeUpdate={
            onPlayheadChange || sendPlayerEvent
              ? (e) => {
                  const videoElement = e.target as HTMLVideoElement
                  if (onPlayheadChange)
                    onPlayheadChange(videoElement.currentTime)
                  if (sendPlayerEvent)
                    sendPlayerEvent({
                      type: 'timeUpdate',
                      currentTime: videoElement.currentTime,
                    })
                }
              : undefined
          }
          onSeeking={(e) => {
            const videoElement = e.target as HTMLVideoElement
            if (isMouseDown) {
              sendPlayerEvent({
                type: 'userSeeking',
                currentTime: videoElement.currentTime,
              })
            }
          }}
          onPlay={(e) => {
            const videoElement = e.target as HTMLVideoElement
            sendPlayerEvent({
              type: 'play',
              currentTime: videoElement.currentTime,
            })
          }}
          onPause={(e) => {
            const videoElement = e.target as HTMLVideoElement
            sendPlayerEvent({
              type: 'pause',
              currentTime: videoElement.currentTime,
            })
          }}
          onEnded={(e) => {
            const videoElement = e.target as HTMLVideoElement
            sendPlayerEvent({
              type: 'ended',
              currentTime: videoElement.currentTime,
            })
          }}
          playbackId={playbackId}
          tokens={{
            playback: tokens?.video,
            thumbnail: tokens?.thumbnail,
            storyboard: tokens?.storyboard,
          }}
          metadata={metadata}
          style={style}
        />
      </Box>
    )
  }
)

export default PlayerContainer
