import { useEffect, useState } from 'react'

import { Box } from '@mui/material'
import { generateJSON } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { escape } from 'lodash'
import { marked } from 'marked'

import { isBrowser } from '@redwoodjs/prerender/browserUtils'

import AddToPage from 'src/components/Page/AddToPage/AddToPage'
import Row from 'src/components/Row/Row'

import SidebarAdminHeader from '../SidebarAdminHeader/SidebarAdminHeader'
import SidebarSectionExpand from '../SidebarSectionExpand/SidebarSectionExpand'

const SidebarLayoutMeetingRecording = ({ crmObject, setOpen }) => {
  const [playerModule, setPlayerModule] = useState<{
    default: typeof PlayerContainer
  }>(null)

  useEffect(() => {
    if (isBrowser) {
      import('src/components/Video/PlayerContainer/PlayerContainer').then(
        (module) => {
          setPlayerModule(module)
        }
      )
    }
  }, [isBrowser])

  const PlayerContainer = playerModule?.default

  const blocks = [
    {
      type: 'title',
      attrs: {
        level: 1,
      },
      content: [
        {
          text: crmObject?.properties?.Suggested_Title || 'Meeting Notes',
          type: 'text',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: crmObject?.properties?.Summary_Short,
        },
      ],
    },
    {
      type: 'meetingrecording',
      attrs: {
        id: crmObject?.objectId,
      },
    },
    generateJSON(
      marked.parse(crmObject?.properties?.output?.tabs[0]?.content || ''),
      [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3, 4, 5, 6],
          },
        }),
      ]
    ),
  ]

  return (
    <>
      <SidebarAdminHeader
        crmObject={crmObject}
        setOpen={setOpen}
      />
      {PlayerContainer && (
        <PlayerContainer
          playbackId={crmObject?.properties?.video?.private?.playbackId}
          tokens={crmObject?.properties?.video?.private?.tokens}
        />
      )}

      <SidebarSectionExpand
        title="Summary"
        isOpen={true}
        plainText={crmObject?.properties?.Summary_Short}
        blocks={blocks}
      />
      {crmObject?.properties?.tabs &&
        crmObject?.properties?.tabs[0]?.content && (
          <SidebarSectionExpand
            title="Meeting Notes"
            isOpen={false}
            plainText={crmObject?.properties?.tabs[0]?.content}
            blocks={blocks}
            children={
              <Box
                sx={{
                  fontSize: '0.7rem',
                  pl: 2,
                  '& h2': {
                    fontWeight: 500,
                    fontSize: '0.8rem',
                    mt: 1,
                    mb: '4px',
                  },
                  '& ul': {
                    mb: 2,
                    p: 0,
                    listStyle: 'inside',
                    '& li': {
                      mb: '4px',
                    },
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: marked.parse(
                    escape(crmObject?.properties?.tabs[0]?.content)
                  ),
                }}
              />
            }
          />
        )}

      <SidebarSectionExpand
        title="Quotes"
        isOpen={true}
        count={
          (crmObject?.properties?.quotes || []).flatMap(
            (quote) => quote.speakerQuotes || []
          ).length
        }
      >
        {crmObject?.properties?.quotes?.length > 0 ? (
          <>
            {crmObject?.properties?.quotes?.map((quote, index) => (
              <Box
                key={`quoteSpeaker_${index}`}
                sx={{
                  mb: 2,
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
              >
                <Box
                  sx={{
                    fontSize: '0.8rem',
                    fontWeight: 500,
                    color: 'text.primary',
                    mb: 1,
                  }}
                >
                  {quote.speaker}
                </Box>
                {quote.speakerQuotes.map((quoteText, index) => (
                  <Row
                    key={`quote_${index}`}
                    sx={{ mb: 3 }}
                  >
                    <Box
                      key={`quote_${index}`}
                      sx={{
                        fontSize: '0.8rem',
                        fontWeight: 400,
                        color: 'text.primary',
                        pl: 1,
                        ml: 2,
                        pr: 1,
                        borderLeft: (theme) =>
                          `2px solid ${theme.palette.divider}`,
                      }}
                    >
                      "{quoteText}"
                    </Box>
                    <Box
                      sx={{
                        width: '32px',
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                      }}
                    >
                      <AddToPage
                        blocks={[
                          {
                            type: 'blockquote',
                            content: [{ type: 'text', text: quoteText }],
                          },
                          {
                            type: 'paragraph',
                            content: [{ type: 'text', text: quote.speaker }],
                          },
                        ]}
                      />
                    </Box>
                  </Row>
                ))}
              </Box>
            ))}
          </>
        ) : (
          <>No quotes for this meetings</>
        )}
      </SidebarSectionExpand>
    </>
  )
}

export default SidebarLayoutMeetingRecording
