import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  lighten,
  TextField,
  Typography,
} from '@mui/material'
import {
  IconAlertSquareFilled,
  IconBrain,
  IconCheck,
  IconChevronLeft,
  IconThumbUpFilled,
} from '@tabler/icons-react'
import toast from 'react-hot-toast'

import { navigate, routes, useParams } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { logger } from 'src/lib/logger'
import { mergeObjects } from 'src/lib/objects'
import {
  NativeSuggestedPipelineTypes,
  ValidPipelineSetupStages,
} from 'src/lib/relationshipSummary'

import Row from '../../Row/Row'

const CREATE_NEW_PIPELINE_INTERACTIVE = gql`
  mutation CreateNewPipelineInteractive($input: PipelineCreateInput!) {
    createPipeline(input: $input) {
      id
      type
      title
      setupSteps
    }
  }
`

const UPDATE_PIPELINE_INTERACTIVE = gql`
  mutation UpdatePipelineInteractive(
    $id: String!
    $input: PipelineUpdateInput!
  ) {
    updatePipeline(id: $id, input: $input) {
      id
      type
      title
      setupSteps
    }
  }
`

const SETUP_PIPELINE_INTERACTIVE = gql`
  mutation SetupPipelineInteractive(
    $id: String!
    $workspaceId: String!
    $setupSteps: [JSON!]!
  ) {
    setupPipeline(id: $id, workspaceId: $workspaceId, setupSteps: $setupSteps) {
      key
      confidence
      missingInformation
    }
  }
`

const GET_PIPELINE_INTERACTIVE = gql`
  query GetPipelineInteractive($id: String!, $workspaceId: String!) {
    pipeline(id: $id, workspaceId: $workspaceId) {
      id
      type
      title
      setupSteps
      icp {
        organization
        metadata
        people
      }
    }
  }
`

interface EffectOnPipeline {
  stageType: ValidPipelineSetupStages
  outputFields: (
    | 'title'
    | 'entranceCriteria'
    | 'icpPeople'
    | 'icpOrganization'
    | 'icpMetadata'
    | 'description'
  )[]
}

interface Step {
  id: string
  goal: string
  title: string
  prompt: string
  examplePrompts: string[]
  userResponse?: string
  sufficient?: boolean
  confidence?: number
  notesToUser?: string
  examplesOpen?: boolean
  effectOnPipeline?: EffectOnPipeline[]
  changed?: boolean
}

const testResponses = [
  {
    id: 'firmographics',
    response:
      'B2B Technology or Professional Services, North America, 100-1000 employees',
  },
  {
    id: 'roles',
    response:
      'Ceo, founder, co-founder, COO, Chief of Staff, sales manager, marketing manager',
  },
  {
    id: 'discovery',
    response: `We need to learn if the prospect has an offering in the market that they are actively selling\n
      Important to know ... do they have a CRM they use today, and if so is there a full-time person using it? If they do have a full time CRM person, they are not likely a fit for us\n
      Also: how defined is their sales process?`,
  },
  {
    id: 'evaluation',
    response: `We will provide a very brief demo and then focus on onboarding during the first meeting\n
      Before the meeting we will send instructions on how to set up their workspace and begin syncing their Gmail account, as well as set up meeting recording default settings\n
      Once the demo is complete and at least one user is active in the workspace, and their data is processed and their pipeline is set up with at least four active opportunities, we consider them in an "Active Trial".\n
      This phase is where the prospect will do most of the evaluating of our product, leading to a willingness to commit and pay.`,
  },
  {
    id: 'making-the-decision',
    response: `Once the prospect's team is in the workspace and generally active for a week or so, we need to reach out to them and schedule another meeting to collect feedback. If we see that the prospect and their team is relying on our CRM (as evidenced by them having no backup/alternate opportunity-management system), we will ask them if they are ready to move forward and become a paying customer.\n
    Once a prospect says they are ready to pay for the product, we need to send them a quote and payment link.`,
  },
  {
    id: 'closed-won',
    response:
      'We only consider a deal closed-won once we have received payment via Stripe Billing.',
  },
]

const salesSteps: Step[] = [
  {
    id: 'firmographics',
    goal: 'Targeting Accounts',
    title: 'Firmographics',
    prompt:
      'What makes an organization a fit for your product or service? Size of company, industry, region/location, etc',
    examplePrompts: [
      'Our target is companies with 100-1000 employees who sell B2B SaaS',
      'Ideal customer profile: companies in the US that are in the healthcare industry',
    ],
    effectOnPipeline: [
      {
        stageType: null,
        outputFields: ['icpOrganization', 'icpMetadata'],
      },
    ],
  },
  {
    id: 'connection',
    goal: 'Finding a champion',
    title: 'Person-level ICP',
    prompt:
      "What is your ideal prospect's seniority, function/department, and how do you start the relationship (email reply, book intro call, etc)?",
    examplePrompts: [
      'We find Marketing Managers, Director of Marketing, etc and reach out to them on LinkedIn or email',
      'We try to get email replies from cold emails to a VP of Sales, Director of Sales, Senior Sales Manager, etc',
    ],
    effectOnPipeline: [
      {
        stageType: null,
        outputFields: ['icpPeople'],
      },
      {
        stageType: ValidPipelineSetupStages.CONNECTION,
        outputFields: ['title', 'description', 'entranceCriteria'],
      },
      {
        stageType: ValidPipelineSetupStages.AWARENESS,
        outputFields: ['title', 'description'],
      },
    ],
  },
  {
    id: 'discovery',
    goal: 'Tailoring the deal',
    title: 'Discovery & Qualification',
    prompt:
      'How do you learn what your prospects really need? What steps do you take to understand their problems before offering solutions?',
    examplePrompts: [
      'If the prospect is looking for a new video security system, then we book a discovery call with the prospect to determine if their budget is under $1,000 and their timeline is within 30 days',
      'If the prospect already has a video security system, we need to learn if they are happy with their current provider and if they are likely to switch',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.NEEDS_IDENTIFICATION,
        outputFields: ['title', 'description', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'evaluation',
    goal: 'Kicking the tires',
    title: 'Evaluation',
    prompt:
      'How do prospects evaluate your product? Do people get a demo (or several), enter a free trial, or conduct a paid pilot, a kickoff service engagement ... or something else?',
    examplePrompts: [
      'We need to learn if the prospect has a need to generate leads, and if they do we consider them qualified and worth giving a demo.',
      'If the prospect already has a video security system, we need to learn if they are happy with their current provider and if they are likely to switch.',
      'Prospects enter a free trial themselves when we give them access, post-qualification',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.EVALUATION,
        outputFields: ['entranceCriteria', 'title', 'description'],
      },
    ],
  },
  {
    id: 'making-the-decision',
    goal: 'Getting to yes',
    title: 'Negotiation & Decision',
    prompt:
      'What does a prospect receive that they can say yes to? Do they get a quote, a payment link, or something else?',
    examplePrompts: [
      'We send a quote and when we get a verbal commit, we send a payment link to the prospect and they click through and pay from there',
      'People view our pricing page and then buy with a credit card when they are ready to go',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CONSIDERATION_NEGOTIATION,
        outputFields: ['title', 'description', 'entranceCriteria'],
      },
    ],
  },
  {
    id: 'closed-won',
    goal: 'Ringing the bell',
    title: 'Closing Won',
    prompt: 'What is the signal that a deal is closed and won?',
    examplePrompts: [
      'We see a payment in Stripe',
      'Once the contract is signed, we know the deal is for sure won',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CLOSED_WON,
        outputFields: ['entranceCriteria', 'description'],
      },
      {
        stageType: ValidPipelineSetupStages.CLOSED_LOST,
        outputFields: ['description', 'entranceCriteria'],
      },
    ],
  },
]

// Fundraising Pipeline Steps
const fundraisingSteps: Step[] = [
  {
    id: 'investor-targeting',
    goal: 'Targeting Investors',
    title: 'Investor Fit',
    prompt:
      'What makes an investor a good fit for your round? Investment stage, sector focus, check size, geography, etc.',
    examplePrompts: [
      'We are targeting Seed-stage VCs who invest $1-3M in B2B SaaS companies',
      'Looking for Series A investors with healthcare expertise in the US East Coast',
    ],
    effectOnPipeline: [
      {
        stageType: null,
        outputFields: ['icpOrganization', 'icpMetadata', 'icpPeople'],
      },
    ],
  },
  {
    id: 'warm-intro',
    goal: 'Getting Introductions',
    title: 'Warm Introduction',
    prompt:
      'How do you get introduced to investors? Through network, accelerator, investment platform?',
    examplePrompts: [
      'Our angel investors send an email introducing us to their VC connections',
      'Our accelerator program sends direct emails introducing us to their partner funds',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CONNECTION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
      {
        stageType: ValidPipelineSetupStages.AWARENESS,
        outputFields: ['title', 'description'],
      },
    ],
  },
  {
    id: 'first-meeting',
    goal: 'Initial Partner Meeting',
    title: 'First Partner Meeting',
    prompt: 'What needs to happen in the first meeting to move forward?',
    examplePrompts: [
      'We book a call with the investing partner and share our company vision and traction metrics',
      'We send a deck and demo our product to demonstrate product-market fit and growth trajectory',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.NEEDS_IDENTIFICATION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
    ],
  },
  {
    id: 'due-diligence',
    goal: 'Due Diligence',
    title: 'Due Diligence',
    prompt: 'What materials and meetings are needed for due diligence?',
    examplePrompts: [
      'We share a data folder with financial statements, contracts, and metrics through email and offer to schedule a call to discuss',
      'We introduce the founding team and our customer references to the investing partner through email',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.EVALUATION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
    ],
  },
  {
    id: 'term-sheet',
    goal: 'Term Sheet Negotiation',
    title: 'Term Sheet',
    prompt: 'What are the key terms to negotiate and agree upon?',
    examplePrompts: [
      'We negotiate valuation, investment amount, and key terms with the lead investor through emails and meetings',
      'We review and agree on governance rights and board composition with the lead investor through emails and meetings',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CONSIDERATION_NEGOTIATION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
    ],
  },
  {
    id: 'closing',
    goal: 'Legal Closing',
    title: 'Closing',
    prompt: 'What defines a successfully closed investment?',
    examplePrompts: [
      'All documents are signed by both parties and money is transferred to our company account',
      'The cap table is updated and shares are issued to the new investors',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CLOSED_WON,
        outputFields: ['entranceCriteria', 'description'],
      },
      {
        stageType: ValidPipelineSetupStages.CLOSED_LOST,
        outputFields: ['description', 'entranceCriteria'],
      },
    ],
  },
]

// Upsell Pipeline Steps
const upsellSteps: Step[] = [
  {
    id: 'champion-alignment',
    goal: 'Champion Engagement',
    title: 'Champion Alignment',
    prompt:
      'How do you engage with existing champions for expansion opportunities?',
    examplePrompts: [
      'We send a quarterly business review showing value and growth opportunities through email',
      'We send a product roadmap showing how it aligns with customer needs through email',
    ],
    effectOnPipeline: [
      {
        stageType: null,
        outputFields: ['icpPeople', 'icpOrganization', 'icpMetadata'],
      },
      {
        stageType: ValidPipelineSetupStages.CONNECTION,
        outputFields: ['title', 'entranceCriteria'],
      },
      {
        stageType: ValidPipelineSetupStages.AWARENESS,
        outputFields: ['title', 'description'],
      },
    ],
  },
  {
    id: 'expansion-discovery',
    goal: 'Expansion Opportunity',
    title: 'Expansion Discovery',
    prompt:
      'How do you identify specific expansion opportunities within the account?',
    examplePrompts: [
      'We book a meeting and document use cases for additional departments or teams',
      'We send an email with a list of questions to map current limitations and how they impact business goals',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.NEEDS_IDENTIFICATION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
    ],
  },
  {
    id: 'solution-validation',
    goal: 'Solution Validation',
    title: 'Value Demonstration',
    prompt: 'How do you validate the expanded solution with stakeholders?',
    examplePrompts: [
      'We perform an ROI analysis for upgraded features or increased capacity and send it through email',
      'We book a meeting with the stakeholder to discuss running a pilot program with a new department or team',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.EVALUATION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
    ],
  },
  {
    id: 'expansion-proposal',
    goal: 'Proposal & Approval',
    title: 'Expansion Proposal',
    prompt:
      'What needs to be included in the expansion proposal? How do you send the propsal to the right stakeholders?',
    examplePrompts: [
      'We send an email with our pricing and terms for expanded usage',
      'We send an implementation plan for new features or users through email',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CONSIDERATION_NEGOTIATION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
    ],
  },
  {
    id: 'expansion-closed',
    goal: 'Expansion Confirmed',
    title: 'Expansion Closed',
    prompt: 'What signals that the expansion is confirmed?',
    examplePrompts: [
      'The contract amendment is signed by both parties on Docusign',
      'A project kickoff call is booked to discuss the implementation plan',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CLOSED_WON,
        outputFields: ['entranceCriteria'],
      },
    ],
  },
]

// Partnership Pipeline Steps
const partnershipSteps: Step[] = [
  {
    id: 'partner-outreach',
    goal: 'Partner Identification',
    title: 'Firmographics',
    prompt:
      'How do you identify who you want to partner with? What kind of partner are you looking for?',
    examplePrompts: [
      'We partner with companies that are in tech and sell to b2b companies with fewer than 100 employees.',
      'We partner with consultants who run their own businesses and specialize in creating custom database software.',
    ],
    effectOnPipeline: [
      {
        stageType: null,
        outputFields: ['icpOrganization', 'icpMetadata', 'icpPeople'],
      },
    ],
  },
  {
    id: 'connection',
    goal: 'Initial Engagement',
    title: 'Connection',
    prompt:
      'How does the relationship with a potential partner start (email reply, book intro call, etc)?',
    examplePrompts: [
      'We find a VP of Sales at a company and reach out to them on LinkedIn or email',
      'A potential partner books a call from our website',
      'We send an email to one of the founders of a company proposing a partnership to discuss joint go-to-market strategies',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CONNECTION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
      {
        stageType: ValidPipelineSetupStages.AWARENESS,
        outputFields: ['title', 'description'],
      },
    ],
  },

  {
    id: 'partnership-discovery',
    goal: 'Partnership Scope',
    title: 'Discovery',
    prompt:
      'How do you determine how the partnership will work? Is there something specific one of you does or do you jointly determine a strategy? Is there a meeting or email or any other activity that needs to happen?',
    examplePrompts: [
      'We book a call with the partner to identify opportunities and discuss a potential pilot program',
      'The partner and our organization jointly determine that there is a mutual fit and we will jointly pursue a go-to-market strategy',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.NEEDS_IDENTIFICATION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
    ],
  },
  {
    id: 'partnership-validation',
    goal: 'Technical & Business Validation',
    title: 'Validation',
    prompt:
      'What steps do you take to determine if a partnership will work? Do you rely on technical evaluations, customer feedback, or formal proposals? Who typically handles these tasks?',
    examplePrompts: [
      'We perform a technical proof of concept for integration and finalize it on a call',
      'We perform a market analysis of the combined solution value and send it to the partner through email',
      'We send a partnership proposal or deck to the partner through email',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.EVALUATION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
    ],
  },
  {
    id: 'partnership-terms',
    goal: 'Partnership Agreement',
    title: 'Terms',
    prompt:
      'How do you come to an agreement with the partner? Do you have standard agreements or are they custom per partnership? What needs to be agreed upon?',
    examplePrompts: [
      'We agree on a final revenue sharing model and go-to-market strategy through emails and meetings',
      'We finalize the technical integration roadmap and resource commitments through an email',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CONSIDERATION_NEGOTIATION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
    ],
  },
  {
    id: 'partnership-launch',
    goal: 'Partnership Launch',
    title: 'Launch',
    prompt:
      'How do you know when a partnership has become active? How do you finalize the partnership?',
    examplePrompts: [
      'The partnership agreement is signed and the implementation plan is approved',
      'Joint marketing materials and sales enablement are finished',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CLOSED_WON,
        outputFields: ['entranceCriteria', 'description'],
      },
      {
        stageType: ValidPipelineSetupStages.CLOSED_LOST,
        outputFields: ['description', 'entranceCriteria'],
      },
    ],
  },
]

const vcInvestmentSteps: Step[] = [
  {
    id: 'deal-sourcing',
    goal: 'Building Deal Flow',
    title: 'Investment Thesis',
    prompt:
      'What specific criteria define an investable company for your fund? Include stage, sector, metrics, location, and any unique requirements.',
    examplePrompts: [
      'We invest $2-5M in B2B SaaS companies at Series A with >$2M ARR, 100%+ YoY growth, and strong net revenue retention',
      'We focus on AI/ML startups in North America at seed stage, typically $1-2M checks where technical founders have PhD or significant industry experience',
      'Looking for European climate tech companies raising $3-8M with working prototypes and pilot customers',
    ],
    effectOnPipeline: [
      {
        stageType: null,
        outputFields: ['icpOrganization', 'icpMetadata'],
      },
    ],
  },
  {
    id: 'deal-flow-channels',
    goal: 'Sourcing Strategy',
    title: 'Deal Flow Channels',
    prompt:
      'How do you source potential investments? Include both inbound and outbound strategies.',
    examplePrompts: [
      'Primary sources are warm intros from our existing portfolio founders and angel network',
      'We actively track companies through accelerators like Y Combinator and maintain relationships with seed funds',
      'Our team attends key industry conferences and maintains an active thesis-driven outbound process',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CONNECTION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
      {
        stageType: ValidPipelineSetupStages.AWARENESS,
        outputFields: ['title', 'description'],
      },
    ],
  },
  {
    id: 'initial-screening',
    goal: 'Initial Screening',
    title: 'Screening Process',
    prompt:
      'What is your process for initial company evaluation? What materials do you review and what are your key screening criteria?',
    examplePrompts: [
      'Review pitch deck, cap table, and basic metrics. Looking for clear product-market fit signals and strong team background',
      'Analyze competitive landscape, market size, and initial customer feedback. Must see evidence of product differentiation',
      'Check founder references and evaluate technical architecture if deep tech',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.NEEDS_IDENTIFICATION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
    ],
  },
  {
    id: 'partner-meetings',
    goal: 'Partner Engagement',
    title: 'Partner Meetings',
    prompt:
      'What needs to happen in meetings with the founding team before moving to due diligence?',
    examplePrompts: [
      'Multiple partner meetings to assess team dynamics, vision alignment, and growth strategy',
      'Deep dive into product roadmap, go-to-market strategy, and unit economics',
      'Understand capital needs, use of funds, and alignment on board governance expectations',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.EVALUATION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
    ],
  },
  {
    id: 'due-diligence',
    goal: 'Deep Dive',
    title: 'Due Diligence',
    prompt:
      'What is your due diligence process? Include key areas of investigation and required documentation.',
    examplePrompts: [
      'Comprehensive review of financial models, customer contracts, and technical architecture',
      'Customer references (min 5), background checks, and IP/legal review',
      'Market analysis including competitor interviews and industry expert consultations',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.EVALUATION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
    ],
  },
  {
    id: 'investment-committee',
    goal: 'Investment Decision',
    title: 'Investment Committee',
    prompt:
      'What is your investment approval process? Detail the steps from IC memo to final decision.',
    examplePrompts: [
      'Full IC memo with market analysis, competition, risks, and return modeling. Requires unanimous partner approval',
      'Present detailed valuation analysis, ownership strategy, and portfolio fit thesis',
      'Review co-investor syndicate and discuss follow-on investment strategy',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CONSIDERATION_NEGOTIATION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
    ],
  },
  {
    id: 'term-sheet',
    goal: 'Deal Terms',
    title: 'Term Sheet',
    prompt: 'What are your key term sheet components and negotiation process?',
    examplePrompts: [
      'Standard terms include pro-rata rights, board seat, protective provisions, and information rights',
      'Negotiate valuation, option pool refresh, and liquidation preferences',
      'Align on governance structure and major investor rights',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CONSIDERATION_NEGOTIATION,
        outputFields: ['title', 'entranceCriteria', 'description'],
      },
    ],
  },
  {
    id: 'closing',
    goal: 'Deal Execution',
    title: 'Closing',
    prompt:
      'What defines a successfully closed investment? Include all required documentation and post-close steps.',
    examplePrompts: [
      'Definitive documents signed, wire transfer completed, and cap table updated',
      'Board appointment executed and post-closing requirements met (D&O insurance, employment agreements)',
      'Portfolio onboarding initiated including systems access and reporting requirements',
    ],
    effectOnPipeline: [
      {
        stageType: ValidPipelineSetupStages.CLOSED_WON,
        outputFields: ['entranceCriteria', 'description'],
      },
      {
        stageType: ValidPipelineSetupStages.CLOSED_LOST,
        outputFields: ['description', 'entranceCriteria'],
      },
    ],
  },
]

const otherSteps: Step[] = [
  {
    id: 'other',
    sufficient: true,
    goal: 'Other',
    title: 'Other',
    prompt: '',
    examplePrompts: [],
    effectOnPipeline: [],
  },
]

const stepsMap = {
  [NativeSuggestedPipelineTypes.NEW_CUSTOMER.key]: salesSteps,
  [NativeSuggestedPipelineTypes.OTHER.key]: otherSteps,
  [NativeSuggestedPipelineTypes.FINANCING_INVESTMENT.key]: fundraisingSteps,
  [NativeSuggestedPipelineTypes.PARTNER.key]: partnershipSteps,
  [NativeSuggestedPipelineTypes.EXISTING_CUSTOMER.key]: upsellSteps,
  [NativeSuggestedPipelineTypes.VENTURE_CAPITAL.key]: vcInvestmentSteps,
}

const PipelineCreateInteractive = ({ id, workspaceId, refetch }) => {
  const { pipelineType } = useParams()
  const { currentUser } = useAuth()
  const [title, setTitle] = useState('')
  const [selectedPipelineType, setSelectedPipelineType] = useState(null)
  const [stepState, setStepState] = useState<Step[]>([])

  const {
    data,
    loading,
    refetch: refetchPipelineForSetup,
  } = useQuery(GET_PIPELINE_INTERACTIVE, {
    variables: {
      id,
      workspaceId,
    },
    skip: !id || !workspaceId,
    onCompleted: (data) => {
      logger.dev({ data })
      if (data?.pipeline?.setupSteps?.length > 0) {
        //setStepState(data.pipeline.setupSteps)
      }
    },
  })

  const refetchAll = useCallback(() => {
    refetchPipelineForSetup()
    refetch()
  }, [refetchPipelineForSetup, refetch])

  const steps = useMemo(() => {
    return selectedPipelineType
      ? stepsMap[selectedPipelineType]
      : stepsMap[data?.pipeline?.type || pipelineType]
  }, [selectedPipelineType])

  logger.dev({ stepState })

  const [createPipeline, { data: createData, loading: createLoading }] =
    useMutation(CREATE_NEW_PIPELINE_INTERACTIVE, {
      onCompleted: (data) => {
        navigate(
          routes.objectDetail({
            objectId: data.createPipeline.id,
            objectTypeSlug: 'pipelines',
            workspaceId,
          })
        )
      },
    })

  const [updatePipeline, { data: updateData, loading: updateLoading }] =
    useMutation(UPDATE_PIPELINE_INTERACTIVE, {
      onCompleted: (data) => {
        logger.dev({ data })
      },
    })

  const [setupPipeline, { data: setupData, loading: setupLoading }] =
    useMutation(SETUP_PIPELINE_INTERACTIVE, {
      onCompleted: (data) => {
        const notes = data.setupPipeline
        const newSteps = stepState.map((s) => {
          const note = notes.find((n) => n.key === s.id)
          return {
            ...s,
            notesToUser: note ? note?.missingInformation : s.notesToUser,
            sufficient: note ? note?.confidence > 59 : s.sufficient,
            confidence: note ? note?.confidence : s.confidence,
          }
        })
        setStepState(newSteps)
        handleUpdatePipeline(newSteps)
      },
    })

  const pipeline = useMemo(() => {
    return data?.pipeline || createData?.createPipeline
      ? mergeObjects(data?.pipeline, createData?.createPipeline)
      : null
  }, [data, createData])

  const handleCreatePipeline = async () => {
    const type = selectedPipelineType
    if (!type) {
      toast.error('No pipeline type selected')
      return
    }

    await toast.promise(
      createPipeline({
        variables: {
          input: {
            workspaceId,
            title,
            type,
          },
        },
      }),
      {
        loading: 'Creating pipeline...',
        success: 'Pipeline created!',
        error: 'Error creating pipeline',
      }
    )
  }

  const handleUpdatePipeline = useCallback(
    async (steps: Step[]) => {
      if (!pipeline) {
        return
      }
      const input = {
        id: pipeline.id,
        workspaceId,
        setupSteps: steps,
        title: pipeline.title,
      }

      await updatePipeline({
        variables: {
          id: pipeline.id,
          input,
        },
      })
      await refetchAll()
    },
    [pipeline, updatePipeline, workspaceId]
  )

  const handleSubmit = async (stepId) => {
    logger.dev({ stepState })
    const step = stepState.find((s) => s.id === stepId)
    step.changed = true
    const canonicalStep = steps.find((s) => s.id === stepId)
    step.effectOnPipeline = canonicalStep?.effectOnPipeline
    for (const effect of step.effectOnPipeline || []) {
      if (effect.stageType) {
        if (!effect?.outputFields?.includes('description')) {
          effect.outputFields = [...(effect.outputFields || []), 'description']
        }
      }
    }
    await toast.promise(
      setupPipeline({
        variables: {
          id: pipeline.id,
          workspaceId,
          setupSteps: [step],
        },
      }),
      {
        loading: 'Taking a look...',
        success: ({ data }) => {
          logger.dev({ modelResultData: data })
          const resultStep = data.setupPipeline?.[0]
          const confidence = resultStep?.confidence
          logger.dev({ resultStep, confidence })

          let message: string
          if (confidence > 59) {
            message = 'Looks good, way to go!'
          } else if (confidence > 40) {
            message = 'Getting closer! Keep adding details ...'
          } else {
            message = 'Could use more information - see our feedback below.'
          }
          refetch()
          return message
        },
        error: 'Error',
      }
    )
  }

  useEffect(() => {
    if (!pipeline || !id) {
      logger.dev({ cannotInitialize: true, selectedPipelineType, id, steps })
      return
    }

    if (!selectedPipelineType && pipeline.type) {
      setSelectedPipelineType(pipeline.type)
    }

    if (title === '' && pipeline.title) {
      setTitle(pipeline.title)
    }

    if (steps && stepState.length === 0) {
      logger.dev({ steps })
      if (pipeline && !pipeline.setupSteps && steps?.length > 0) {
        const initialSteps = steps.map((step) => ({
          ...step,
          userResponse: '',
          sufficient: step.id === 'other',
          examplesOpen: false,
          changed: false,
        }))
        setStepState(initialSteps)
        handleUpdatePipeline(initialSteps)
        return
      }

      if (steps.length === 1 && steps[0].id === 'other') {
        const initOtherStep = [
          {
            ...steps[0],
            sufficient: true,
          },
        ]
        setStepState(initOtherStep)
        handleUpdatePipeline(initOtherStep)
        return
      } else if (!(pipeline?.setupSteps?.length > 0) && steps?.length > 0) {
        // Initialize
        setStepState(
          steps?.map((step) => ({
            ...step,
            userResponse: '',
            sufficient: step.id === 'other',
            examplesOpen: false,
            changed: false,
          }))
        )
      } else if (pipeline?.setupSteps?.length > 0) {
        // Load from db saved pipeline-setup state
        setStepState(pipeline.setupSteps.map((s) => ({ ...s, changed: false })))
      }
    }
  }, [
    stepState.length,
    id,
    pipeline,
    selectedPipelineType,
    title,
    steps,
    handleUpdatePipeline,
  ])

  const handleAddTestResponses = () => {
    setStepState((prev) => {
      const newSteps = prev.map((s) => ({
        ...s,
        sufficient: true,
        userResponse: testResponses.find((t) => t.id === s.id)?.response,
      }))
      handleUpdatePipeline(newSteps)
      return newSteps
    })
  }

  const farthestStepReached = useMemo(() => {
    if (!stepState) {
      return 0
    }
    const farthestStep = stepState.reduce((acc, step) => {
      return step.sufficient ? step.id : acc
    }, '')
    const index = stepState.findIndex((s) => s.id === farthestStep)
    return index || 0
  }, [stepState])

  const handleCompletePipelineSetup = () => {
    //alert('hooray')
  }

  // Add effect to handle pipelineType param
  useEffect(() => {
    if (pipelineType && !selectedPipelineType) {
      setSelectedPipelineType(pipelineType)
      // Set default title based on pipeline type
      const pipelineTypeInfo = NativeSuggestedPipelineTypes[pipelineType]
      if (pipelineTypeInfo) {
        setTitle(pipelineTypeInfo.label)
      } else {
        logger.warn(`Invalid pipeline type provided: ${pipelineType}`)
      }
    }
  }, [pipelineType, selectedPipelineType])

  return !pipeline && !loading ? (
    <Box
      sx={{
        width: '100%',
        height: 'calc(70vh)',
        background: (theme) => theme.palette.background.default,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {!selectedPipelineType && (
        <Card sx={{ width: '922px' }}>
          <CardContent>
            <Row
              gap={2}
              sx={{ mt: 3, flexWrap: 'wrap' }}
            >
              {Object.entries(NativeSuggestedPipelineTypes)
                .filter(([key, value]) => value.showInChooser)
                .map(([key, value]) => (
                  <Box
                    key={key}
                    sx={{
                      width: '280px',
                      height: '128px',
                      flexShrink: 0,
                      border: (theme) => `2px solid ${theme.palette.divider}`,
                      borderRadius: 1,
                      px: 4,
                      py: 4,
                      cursor: 'pointer',
                    }}
                    onClick={() => setSelectedPipelineType(key)}
                  >
                    <Row
                      gap={2}
                      sx={{ alignItems: 'top' }}
                    >
                      <Box
                        sx={{
                          display: key === 'OTHER' ? 'none' : 'block',
                        }}
                      >
                        {React.cloneElement(value.icon, {
                          size: 40,
                        })}
                      </Box>
                      <Box>
                        <Typography variant="h3">{value.label}</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>
                          {value.informalLabel}
                        </Typography>
                      </Box>
                    </Row>
                  </Box>
                ))}
            </Row>
          </CardContent>
        </Card>
      )}
      {selectedPipelineType && (
        <Card sx={{ width: '624px' }}>
          <CardContent>
            <TextField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth={true}
              placeholder="Enter a name for your pipeline"
              onKeyDown={(e) => {
                logger.dev({ key: e.key })
                if (e.key === 'Enter') {
                  handleCreatePipeline()
                }
              }}
            />
          </CardContent>
          <CardActions sx={{ justifyContent: 'space-between', px: 0 }}>
            {!pipelineType && (
              <Button
                startIcon={<IconChevronLeft size={14} />}
                onClick={() => setSelectedPipelineType(null)}
                disabled={createLoading}
              >
                Back
              </Button>
            )}
            <Button
              variant={!title ? 'outlined' : 'contained'}
              color={!title ? 'primary' : 'secondary'}
              disableElevation={true}
              disabled={!title || createLoading}
              onClick={handleCreatePipeline}
              fullWidth={!!pipelineType}
            >
              Create pipeline
            </Button>
          </CardActions>
        </Card>
      )}
    </Box>
  ) : (
    pipeline && stepState && (
      <>
        <Box
          sx={{
            height: 'calc(100%)',
            overflowY: 'auto',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              width: '100%',
              mt: 3,
            }}
          >
            {stepState?.map((step, index) => (
              <Box
                key={step.id}
                sx={{
                  width: '620px',
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  borderRadius: 2,
                  padding: 2,
                  background: (theme) => theme.palette.background.paper,
                }}
              >
                <Row sx={{ justifyContent: 'space-between' }}>
                  <Typography variant="h3">{step.goal}</Typography>
                  <Row gap={1}>
                    {step.sufficient ? (
                      <>
                        {farthestStepReached === stepState.length - 1 && (
                          <Button
                            variant="text"
                            color="primary"
                            size="small"
                            disableElevation={true}
                            onClick={() =>
                              setStepState((prev) =>
                                prev.map((s) =>
                                  s.id === step.id
                                    ? { ...s, sufficient: false }
                                    : s
                                )
                              )
                            }
                          >
                            edit
                          </Button>
                        )}
                        <IconCheck
                          style={{
                            color: step.sufficient ? 'white' : 'gray',
                            opacity: step.sufficient ? 1 : 0.5,
                            backgroundColor: step.sufficient
                              ? '#5FC18F'
                              : 'transparent',
                            borderRadius: '50%',
                            padding: 2,
                          }}
                        />
                      </>
                    ) : (
                      <Chip
                        label={step.title}
                        size="small"
                        variant="outlined"
                        sx={{
                          color: (theme) => theme.palette.text.primary,
                          width: '160px',
                          opacity:
                            index === farthestStepReached + 1 ||
                            (farthestStepReached === stepState.length - 1 &&
                              !step.sufficient)
                              ? 1.0
                              : 0.6,
                        }}
                      />
                    )}
                  </Row>
                </Row>
                {index === farthestStepReached + 1 ||
                (farthestStepReached === stepState.length - 1 &&
                  !step.sufficient) ? (
                  <>
                    <Typography
                      variant="body1"
                      sx={{
                        background: (theme) =>
                          lighten(theme.palette.divider, 0.2),
                        p: 1,
                        borderRadius: '4px',
                        mb: '-1px',
                        fontSize: '12px',
                        letterSpacing: '-0.3px',
                        fontWeight: 600,
                        mt: 2,
                        borderBottomLeftRadius: '0px',
                        borderBottomRightRadius: '0px',
                        border: (theme) => `1px solid ${theme.palette.divider}`,
                        borderBottom: 'none',
                      }}
                    >
                      {step.prompt}
                      <Chip
                        size="small"
                        variant="outlined"
                        sx={{ flexShrink: 0, height: '16px', ml: 1 }}
                        onClick={() =>
                          setStepState((prev) =>
                            prev.map((s) =>
                              s.id === step.id
                                ? { ...s, examplesOpen: !s.examplesOpen }
                                : s
                            )
                          )
                        }
                        label={
                          step.examplesOpen ? 'Hide examples' : 'See examples'
                        }
                      />
                    </Typography>

                    {step.examplesOpen && (
                      <Box
                        sx={{
                          border: (theme) =>
                            `1px solid ${theme.palette.divider}`,
                          borderTop: 'none',
                          borderBottom: 'none',
                          p: 2,
                        }}
                      >
                        {step.examplePrompts.map((prompt, promptIndex) => (
                          <Typography
                            key={`${promptIndex}-${index}`}
                            variant="body2"
                            sx={{
                              fontStyle: 'italic',
                              color: (theme) => theme.palette.text.secondary,
                              my: 1,
                            }}
                          >
                            {`"${prompt}"`}
                          </Typography>
                        ))}
                      </Box>
                    )}

                    {step.notesToUser && (
                      <Alert
                        severity={
                          step.confidence > 59
                            ? 'success'
                            : step.confidence > 40
                              ? 'info'
                              : 'warning'
                        }
                        sx={{
                          color: 'text.secondary',
                          lineHeight: '150%',
                          fontSize: '12px',
                          borderRadius: '0px',
                          my: 0,
                          px: 1,
                          border: (theme) =>
                            `1px solid ${theme.palette.divider}`,
                          borderTop: 'none',
                          borderBottom: 'none',
                          '& .MuiAlert-icon': {
                            mr: 1,
                          },
                        }}
                        icon={
                          step.confidence > 59 ? (
                            <IconThumbUpFilled size={18} />
                          ) : step.confidence > 40 ? (
                            <IconBrain size={18} />
                          ) : (
                            <IconAlertSquareFilled size={18} />
                          )
                        }
                      >
                        <AlertTitle>
                          {step.confidence > 59
                            ? 'Great answer. We have notes below if you want it to be even better.'
                            : step.confidence > 40
                              ? `Good start, let's improve it a bit`
                              : `Let's take a closer look`}
                        </AlertTitle>
                        {step.notesToUser}
                      </Alert>
                    )}
                    <TextField
                      value={step.userResponse}
                      fullWidth={true}
                      multiline={true}
                      rows={6}
                      onChange={(e) =>
                        setStepState((prev) =>
                          prev.map((s) =>
                            s.id === step.id
                              ? { ...s, userResponse: e.target.value }
                              : s
                          )
                        )
                      }
                      sx={{
                        mb: 2,
                        '& .MuiInputBase-root, & .MuiOutlinedInput-notchedOutline':
                          {
                            borderTopLeftRadius: '0px',
                            borderTopRightRadius: '0px',
                            borderColor: (theme) => theme.palette.divider,
                          },
                      }}
                    />
                    <Row
                      gap={2}
                      sx={{ mt: 2 }}
                    >
                      <Button
                        variant={
                          step.confidence > 59
                            ? 'text'
                            : step.notesToUser
                              ? 'outlined'
                              : 'contained'
                        }
                        disabled={!(step.userResponse?.length > 10)}
                        color={step.confidence > 59 ? 'primary' : 'secondary'}
                        disableElevation={true}
                        onClick={() => handleSubmit(step.id)}
                        fullWidth={true}
                      >
                        {step.notesToUser ? 'Try again' : 'Tell Day.ai'}
                      </Button>
                      {step.confidence > 59 && (
                        <Button
                          variant="outlined"
                          color="secondary"
                          disableElevation={true}
                          onClick={() => {
                            setStepState((prev) =>
                              prev.map((s) =>
                                s.id === step.id
                                  ? { ...s, sufficient: true }
                                  : s
                              )
                            )
                          }}
                          fullWidth={true}
                        >
                          Next
                        </Button>
                      )}
                    </Row>
                  </>
                ) : null}
              </Box>
            ))}
            {false &&
              stepState &&
              farthestStepReached === stepState.length - 1 && (
                <Button
                  disabled={farthestStepReached < stepState?.length - 1}
                  onClick={handleCompletePipelineSetup}
                  variant="contained"
                  color="secondary"
                  disableElevation={true}
                  sx={{
                    flexShrink: 0,
                    opacity:
                      farthestStepReached !== stepState.length - 1 ? 0 : 1,
                    transition: 'opacity 0.5s ease-in-out',
                    width: '620px',
                    height:
                      farthestStepReached !== stepState.length - 1
                        ? '0px'
                        : '64px',
                  }}
                >
                  You're ready, let's go!
                </Button>
              )}
          </Box>
        </Box>
      </>
    )
  )
}

/* what do we need to know?

Organization profile (firmographics and also things we could research)
Roles you sell to (target seniority, department)
What is discovery like? What needs to happen for discovery to be sufficient/complete? What steps do you take to gain a the necessary understanding of your prospect's problems to be able to present a viable, specific solution for them to evaluate?
What steps do buyers take to evaluate your product before they purchase? How do you know they are in those stages? Do you have a free trial or similar? Do you offer multiple demos? Do you deal with procurement?
How does a buyer complete their purchase? Do you send a proposal, a payment link, do you offer multiple quotes or options? (Becomes entrance criteria for consideration/negotiation and closed-won)
*/

export default PipelineCreateInteractive
