import { Box } from '@mui/material'
import { useEditor } from '@tiptap/react'

import { extensionsObjectSearchResult } from '../extensions'
import TiptapEditor from '../TiptapEditor/TiptapEditor'

const TiptapBlockPreviewer = ({ block }) => {
  const editor = useEditor({
    extensions: extensionsObjectSearchResult,
    content: {
      type: 'doc',
      content: [block],
    },
    autofocus: false,
    editable: false,
  })

  return (
    <Box sx={{ pointerEvents: 'none' }}>
      <TiptapEditor editor={editor} />
    </Box>
  )
}

export default TiptapBlockPreviewer
