import { useEffect, useState } from 'react'

import { KeyboardArrowRight } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import Row from 'src/components/Row/Row'

type ChildComponentProps = {
  setCount: React.Dispatch<React.SetStateAction<number>>
}

type Props = {
  title: string
  isOpen: boolean
  children?: React.ReactElement<ChildComponentProps>
  count?: number
  plainText?: string
  sx?: any
}

const SidebarSectionExpand: React.FC<Props> = ({
  title,
  isOpen = false,
  count,
  plainText,
  children,
  sx = {},
}) => {
  const [open, setOpen] = useState(isOpen)
  const [adjustableCount, setAdjustableCount] = useState(count)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    setAdjustableCount(count)
  }, [count])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        pb: 2,
        width: '100%',
        ...sx,
      }}
    >
      <Box
        sx={{ px: 2, width: 'calc(100%)' }}
        className="sidebar-section-expand-container"
      >
        <Row
          onClick={() => setOpen((prev) => !prev)}
          sx={{
            pt: 2,
            width: 'calc(100%)',
            cursor: 'pointer',
            justifyContent: 'space-between',
          }}
          className="sidebar-section-expand-title-row"
        >
          <Typography
            variant="h3"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              pr: '2px',
              lineHeight: '22px',
            }}
          >
            {title}
          </Typography>
          <Row>
            {typeof adjustableCount === 'number' && adjustableCount > 0 && (
              <Row
                sx={{
                  borderRadius: '50px',
                  backgroundColor: (theme) => theme.palette.divider,
                  width: '20px',
                  height: '20px',
                  justifyContent: 'center',
                  opacity: open ? 0 : 1,
                  transition: 'all 0.25s ease-in-out',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '12px',
                  }}
                >
                  {`${
                    typeof adjustableCount === 'number'
                      ? `${adjustableCount}`
                      : ''
                  }`}
                </Typography>
              </Row>
            )}
            <KeyboardArrowRight
              sx={{
                transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
                transition: 'all 0.25s linear',
              }}
            />
          </Row>
        </Row>
        <Box
          sx={{
            maxHeight: open ? 'auto' : '0px',
            transition: 'all 0.25s ease',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          {plainText && !children && (
            <Typography
              sx={{
                fontSize: '0.8rem',
                transition: 'all 0.25s linear',
                opacity: open ? 1 : 0,
                pt: open ? '8px' : '0px',
                width: '100%',
                fontWeight: 300,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {plainText}
            </Typography>
          )}
          {children && (
            <Box
              sx={{
                borderTop: (theme) => `0.5px solid ${theme.palette.divider}`,
                transition: 'all 0.25s linear',
                opacity: open ? 1 : 0,
                mt: open ? 1 : '0px',
                pt: open ? 1 : '0px',
                width: '100%',
                height: '100%',
              }}
            >
              {React.isValidElement(children) &&
                React.cloneElement(children, { setCount: setAdjustableCount })}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default SidebarSectionExpand
