import { useContext, useEffect, useState } from 'react'

import {
  Badge,
  Box,
  List,
  ListItemButton,
  Typography,
  useTheme,
} from '@mui/material'
import {
  RiAlertFill,
  RiContactsBook2Fill,
  RiFolderSharedFill,
  RiGoogleFill,
  RiLogoutBoxRLine,
} from '@remixicon/react'

import { Link, routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { DayContext } from 'src/lib/dayContext'

import ContactAvatar from '../../../components/ContactAvatar/ContactAvatar'
import Notifications from '../../../components/Notifications/Notifications'

const ProfileController = ({ userCoreContact, drawerWidth, collapsed }) => {
  const theme = useTheme()
  const { suggestedExclusions } = useContext(DayContext)
  const [expanded, setExpanded] = useState<boolean>(false)
  const { logOut } = useAuth()
  const [visible, setVisible] = useState<boolean>(false)

  const profileBoxHeight = '60px'
  const linkStyle = {
    fontSize: '0.9rem',
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }

  const iconStyle = {
    marginRight: '8px',
    height: '18px',
    width: '18px',
    color: theme.palette.text.secondary,
  }

  useEffect(() => {
    if (collapsed) {
      setExpanded(false)
      setVisible(false)
    } else {
      // wait 0.3s and then make visible=true
      setTimeout(() => {
        setVisible(true)
      }, 100)
    }
  }, [collapsed])

  const handleLogout = () => {
    localStorage.clear()
    logOut()
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        p: 0,
        zIndex: 10,
        background: (theme) =>
          collapsed
            ? theme.palette.background.paper
            : theme.palette.background.default,
        height: expanded ? '264px' : profileBoxHeight,
        transition: 'all 0.25s ease',
        width: collapsed ? '54px' : drawerWidth,
        cursor: 'pointer',
        '::before': {
          content: '""',
          position: 'absolute',
          top: '-20px',
          left: 0,
          right: 0,
          height: '20px',
          background: (theme) =>
            visible
              ? `linear-gradient(to top, ${theme.palette.background.default} 0%, rgba(255, 255, 255, 0) 100%)`
              : 'transparent',
          zIndex: 1,
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'top' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: profileBoxHeight,
            p: '10px',
            cursor: 'pointer',
          }}
          onClick={() => setExpanded((prev) => !prev)}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'left',
            }}
          >
            <Badge
              badgeContent={suggestedExclusions.length}
              color="error"
            >
              <ContactAvatar
                email={userCoreContact.email}
                size={42}
                borderRadius={4}
              />
            </Badge>
            {!collapsed && (
              <Typography
                sx={{
                  fontWeight: 500,
                  ml: 1,
                  fontSize: '0.8rem',
                  opacity: visible ? 1 : 0,
                  transition: 'all 0.25s ease',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '160px',
                }}
              >
                {userCoreContact?.firstName || ''}{' '}
                {userCoreContact?.lastName?.length &&
                userCoreContact?.firstName?.length +
                  userCoreContact?.lastName?.length <
                  24
                  ? userCoreContact.lastName
                  : ''}
              </Typography>
            )}
          </Box>

          {!collapsed && (
            <Box
              sx={{
                width: '32px',
                opacity: visible ? 1 : 0,
                transition: 'all 0.25s ease',
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Notifications />
            </Box>
          )}
        </Box>
      </Box>

      {!collapsed && (
        <List sx={{ pl: '5px' }}>
          <ListItemButton
            component={Link}
            to={routes.settings({ id: 'profile' })}
            sx={linkStyle}
          >
            <RiContactsBook2Fill style={iconStyle} />
            Your info
          </ListItemButton>
          <ListItemButton
            component={Link}
            to={routes.settings({ id: 'connectedAccounts' })}
            sx={linkStyle}
          >
            <RiGoogleFill style={iconStyle} />
            Google Accounts
          </ListItemButton>
          <ListItemButton
            component={Link}
            to={routes.workspaceSharing()}
            sx={{
              ...linkStyle,
              backgroundColor:
                suggestedExclusions?.length > 0
                  ? theme.palette.error.light
                  : 'inherit',
              color:
                suggestedExclusions?.length > 0
                  ? theme.palette.error.contrastText
                  : 'inherit',
              '&:hover': {
                backgroundColor:
                  suggestedExclusions?.length > 0
                    ? theme.palette.error.main
                    : 'inherit',
                color:
                  suggestedExclusions?.length > 0
                    ? theme.palette.error.contrastText
                    : 'inherit',
              },
            }}
          >
            {suggestedExclusions.length > 0 ? (
              <RiAlertFill
                style={{
                  ...iconStyle,
                  color: theme.palette.error.contrastText,
                }}
              />
            ) : (
              <RiFolderSharedFill style={iconStyle} />
            )}
            Email sharing rules
          </ListItemButton>
          <ListItemButton
            onClick={handleLogout}
            sx={linkStyle}
          >
            <RiLogoutBoxRLine style={iconStyle} />
            Log out
          </ListItemButton>
        </List>
      )}
    </Box>
  )
}

export default ProfileController
