import { useContext, useMemo } from 'react'

import { Chip, Tooltip } from '@mui/material'
import { RiCalendarLine } from '@remixicon/react'
import { Organization } from 'types/graphql'

import { dayjs } from 'src/lib/dayjs'
import { ellipsize } from 'src/lib/formatters'
import { logger } from 'src/lib/logger'
import { useQuery } from '@redwoodjs/web'
import { DayContext } from 'src/lib/dayContext'

const GET_ORGANIZATION_FOR_NEXT_MEETING_CHIP = gql`
  query GetOrganizationForNextMeetingChip(
    $workspaceId: String!
    $orgId: String!
  ) {
    workspaceOrganization(workspaceId: $workspaceId, domain: $orgId) {
      id
      relationship {
        id
        upcomingEvents
      }
    }
  }
`

const OrganizationNextMeetingChip = ({
  org = null,
  domain = null,
}: {
  org: Organization | null
  domain: string | null
}) => {
  const { selectedWorkspace } = useContext(DayContext)
  const { data: orgData, loading: organizationLoading } = useQuery(
    GET_ORGANIZATION_FOR_NEXT_MEETING_CHIP,
    {
      variables: {
        workspaceId: selectedWorkspace,
        orgId: domain,
      },
      skip: !!org || !domain || !selectedWorkspace,
    }
  )

  const nextMeeting = useMemo(() => {
    const orgtoUse = org || orgData?.workspaceOrganization
    const nm = orgtoUse?.relationship?.upcomingEvents?.[0]
    if (nm) {
      try {
        const startDt = dayjs(parseInt(nm.startTimestamp) * 1000)
        if (startDt.isAfter(dayjs())) {
          return nm
        }
      } catch (e) {
        logger.dev(`Error parsing next meeting start timestamp: ${e}`)
      }
    }
    return null
  }, [org])

  return nextMeeting ? (
    <Tooltip title={nextMeeting.title}>
      <Chip
        size="small"
        variant="outlined"
        icon={
          <RiCalendarLine
            size={14}
            style={{ marginLeft: '8px', marginRight: '0px', flexShrink: 0 }}
          />
        }
        label={`"${ellipsize(nextMeeting.title, 20)}" on ${
          nextMeeting.start_time
        }`}
        sx={{
          fontSize: '0.7rem',
          fontWeight: 500,
        }}
      />
    </Tooltip>
  ) : (
    <Chip
      size="small"
      variant="outlined"
      icon={<RiCalendarLine size={14} />}
      label={'No next meeting scheduled'}
      sx={{
        fontSize: '0.7rem',
        fontWeight: 500,
        pl: '4px',
      }}
    />
  )
}

export default OrganizationNextMeetingChip
