import { useLocation } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'

import CtaButton from '../custom-ui/CtaButton'

import { Logo } from './Icons'
import Label from './Label'

const GET_CORE_CONTACT_FOR_PUBLIC_SITE = gql`
  query GetCoreContactForPublicSite($email: String!) {
    coreContact(email: $email) {
      firstName
      lastName
      email
      photo
    }
  }
`

const Header = () => {
  const { currentUser } = useAuth()

  const { data } = useQuery(GET_CORE_CONTACT_FOR_PUBLIC_SITE, {
    variables: { email: currentUser?.email },
    skip: !currentUser,
  })

  const userLabel = data?.coreContact?.firstName
    ? `👋 Well hello, ${data.coreContact.firstName}`
    : 'Your Account'

  const location = useLocation()
  return (
    <div className="md:mb-20">
      {location.pathname != '/sequoia-backs-ai-native-crm' && <Label />}
      <div className="custom_container container flex items-center justify-between py-6">
        <a
          href="/"
          className="w-[104px]"
          aria-label="home page link"
        >
          <Logo />
        </a>
        <CtaButton
          title={currentUser ? userLabel : 'Get early access'}
          url={currentUser ? '/today' : 'https://get.day.ai/get-early-access/'}
        />
      </div>
    </div>
  )
}

export default Header
