import { cloneElement } from 'react'

import { Chip } from '@mui/material'

import { getRelationshipSummaryTypeMetadataFromKey } from 'src/lib/relationshipSummary'

const RelationshipTypeChip = ({ type }) => {
  if (!type) return null
  const typeObject = getRelationshipSummaryTypeMetadataFromKey(type)
  const excludedTypes = [
    'OTHER',
    'INSUFFICIENT_DATA',
    'Other',
    'Insufficient Data',
    'other',
  ]
  return (
    typeObject &&
    !excludedTypes.includes(type) && (
      <Chip
        //onClick={() => addSelectedType(typeObject.label)}
        label={typeObject.label}
        variant="outlined"
        size="small"
        icon={
          <span style={{ margin: '0px -4px 0px 8px' }}>
            {cloneElement(typeObject.icon, { size: 14 })}
          </span>
        }
        sx={{
          justifyContent: 'start',
          width: '100%',
          background: typeObject?.color || 'transparent',
          fontSize: '12px',
          fontWeight: 500,
          letterSpacing: '-0.15px',
        }}
      />
    )
  )
}

export default RelationshipTypeChip
