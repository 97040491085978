export const isPopulatedArray = (possibleArray: any) => {
  return Array.isArray(possibleArray) && possibleArray?.length > 0
}

export const ensureArray = (possibleArray: any) => {
  return Array.isArray(possibleArray) ? possibleArray : []
}

export const ensureObject = (possibleObject: any) => {
  return typeof possibleObject === 'object' && possibleObject !== null
    ? possibleObject
    : {}
}

export const getObjectByKeyWithFallback = (
  object: any,
  key: string,
  fallbackKey: string
) => {
  return object?.[key] || object?.[fallbackKey]
}
