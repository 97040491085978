import React, { createContext, useContext, ReactElement } from 'react'

type AvatarCacheContextType = {
  getCachedAvatar: (key: string) => ReactElement | undefined
  setCachedAvatar: (key: string, avatar: ReactElement) => void
  clearCacheForEmail: (email: string) => void
  clearCache: () => void
}

const MAX_CACHE_SIZE = 1000

class AvatarCache {
  private cache = new Map<string, ReactElement>()

  get(key: string) {
    return this.cache.get(key)
  }

  set(key: string, value: ReactElement) {
    if (this.cache.size >= MAX_CACHE_SIZE) {
      const firstKey = this.cache.keys().next().value
      this.cache.delete(firstKey)
    }
    this.cache.set(key, value)
  }

  clearForEmail(email: string) {
    for (const key of this.cache.keys()) {
      if (key.startsWith(`${email}-`)) {
        this.cache.delete(key)
      }
    }
  }

  clear() {
    this.cache.clear()
  }
}

const AvatarCacheContext = createContext<AvatarCacheContextType | null>(null)

export const AvatarCacheProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const cache = new AvatarCache()

  const value = {
    getCachedAvatar: (key: string) => cache.get(key),
    setCachedAvatar: (key: string, avatar: ReactElement) =>
      cache.set(key, avatar),
    clearCacheForEmail: (email: string) => cache.clearForEmail(email),
    clearCache: () => cache.clear(),
  }

  return (
    <AvatarCacheContext.Provider value={value}>
      {children}
    </AvatarCacheContext.Provider>
  )
}

export const useAvatarCache = () => {
  const context = useContext(AvatarCacheContext)
  if (!context) {
    throw new Error('useAvatarCache must be used within an AvatarCacheProvider')
  }
  return context
}
