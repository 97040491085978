import { logger } from 'src/lib/logger'

function trimPipelineData(data, xAxisLabels, timePeriod) {
  // Don't trim - return the full dataset
  return { data, xAxisLabels }
}

export const preparePipelineChartData = ({ stages, timePeriod }) => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const currentQuarter = Math.floor(currentMonth / 3)

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const result = {
    'opportunity-forecast-series-won': [] as number[],
    'opportunity-forecast-series-forecast': [] as number[],
    'opportunity-forecast-series-pipeline': [] as number[],
  }
  const xAxisLabels = []

  // Set periods based on time period selection
  const totalPeriods = timePeriod === 'quarter' ? 4 : 12

  for (let i = 0; i < totalPeriods; i++) {
    result['opportunity-forecast-series-won'].push(0)
    result['opportunity-forecast-series-forecast'].push(0)
    result['opportunity-forecast-series-pipeline'].push(0)

    // Calculate the actual month/quarter label
    const monthIndex = (currentMonth + i) % 12
    if (timePeriod === 'quarter') {
      const quarterIndex = (currentQuarter + i) % 4
      xAxisLabels.push(`Q${quarterIndex + 1}`)
    } else {
      xAxisLabels.push(monthNames[monthIndex])
    }
  }

  stages.forEach((stage) => {
    stage.opportunities.forEach((opportunity) => {
      const date = new Date(opportunity.expectedCloseDate)

      // Calculate months difference from current month
      const monthsDiff =
        (date.getFullYear() - currentDate.getFullYear()) * 12 +
        (date.getMonth() - currentDate.getMonth())

      // Calculate which quarter this falls into relative to current quarter
      const opportunityQuarter = Math.floor(date.getMonth() / 3)
      const currentQuarter = Math.floor(currentDate.getMonth() / 3)
      const quartersDiff =
        (date.getFullYear() - currentDate.getFullYear()) * 4 +
        (opportunityQuarter - currentQuarter)

      // Only process if within the display period (4 quarters or 12 months)
      if (
        monthsDiff >= 0 &&
        (timePeriod === 'quarter' ? quartersDiff < 4 : monthsDiff < 12)
      ) {
        const revenue = opportunity.expectedRevenue
        const likelihood = stage.likelihoodToClose

        // Calculate the appropriate index based on time period
        const index = timePeriod === 'quarter' ? quartersDiff : monthsDiff

        // Only add if within our display range
        if (index < totalPeriods) {
          if (likelihood === 1) {
            result['opportunity-forecast-series-won'][index] += revenue
          } else {
            const forecastedRevenue = revenue * likelihood
            result['opportunity-forecast-series-forecast'][index] +=
              forecastedRevenue
            result['opportunity-forecast-series-pipeline'][index] +=
              revenue - forecastedRevenue
          }
        }
      }
    })
  })

  logger.dev({
    timePeriod,
    totalPeriods,
    currentQuarter: Math.floor(currentDate.getMonth() / 3),
    xAxisLabels,
    result,
  })

  return { data: result, xAxisLabels }
}
