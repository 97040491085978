import { useContext } from 'react'

import { AccessTime, CalendarTodayOutlined } from '@mui/icons-material'
import { Box, Card, Typography, lighten } from '@mui/material'

import { useAuth } from 'src/auth'
import { DayContext } from 'src/lib/dayContext'
import { formatEventTimeForTable } from 'src/lib/formatters'
import { videoSubtitle } from 'src/lib/meetingRecordingFormatting'
import { getAvatarEmails } from 'src/pages/LibraryPage/LibraryPage'

import AvatarRow from '../AvatarRow/AvatarRow'
import { calculateMeetingLength } from '../MeetingRecordingsTable/MeetingRecordingsTable'
import OrganizationChip from '../Organizations/OrganizationChip/OrganizationChip'
import Row from '../Row/Row'

const MeetingRecordingListItem = ({ meetingRecording }) => {
  const { workAccounts } = useContext(DayContext)
  const { currentUser: user } = useAuth()

  const allUserEmails = [
    user.email,
    ...workAccounts.map((account) => account.email),
  ]

  const hasDomains = meetingRecording?.domains?.length > 0

  return (
    <Card
      elevation={0}
      sx={{
        p: 0,
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        boxShadow: 'none',
        border: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            pt: '64px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AvatarRow
            max={4}
            borderRadius={50}
            emails={getAvatarEmails(meetingRecording, allUserEmails)}
            showVerification={false}
            showSidebar={true}
            size={72}
            sx={{
              justifyContent: 'center',
              '& .personAvatarBox': {
                ml: '-24px',
                '&:last-of-type': {
                  ml: '0px',
                },
              },
            }}
          />
        </Box>

        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            pb: '64px',
          }}
        >
          <Typography
            sx={{
              fontSize: '1.1rem',
              fontWeight: 600,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
              textAlign: 'center',
              mt: 1,
              px: 4,
            }}
          >
            {meetingRecording?.calendarEvents[0]?.GoogleEvent?.title ||
              meetingRecording.title}
          </Typography>

          <Typography
            sx={{
              mt: 1,
              fontWeight: 300,
              fontSize: '0.8rem',
              display: 'flex',
              alignItems: 'top',
              justifyContent: 'center',
              flexDirection: 'row',
              height: '36px',
              lineHeight: '1.4',
              overflow: 'hidden',
              width: '90%',
              textAlign: 'center',
              color: (theme) => theme.palette.text.secondary,
              textOverflow: 'ellipsis',
            }}
          >
            {videoSubtitle(meetingRecording)}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '52px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            fontSize: '0.7rem',
            fontWeight: 600,
          }}
        >
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              borderRight: (theme) => `1px solid ${theme.palette.divider}`,
              width: hasDomains ? '25%' : '50%',
            }}
          >
            <CalendarTodayOutlined
              sx={{
                fontSize: '0.8rem',
                mr: 1,
                color: (theme) => lighten(theme.palette.text.secondary, 0.5),
              }}
            />
            <Box sx={{ mt: '2px' }}>
              {`${formatEventTimeForTable(meetingRecording.startedAt)}`}
            </Box>
          </Box>

          {hasDomains && (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                width: '50%',
                flexShrink: 1,
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{ mt: '2px', px: 1, display: 'block', overflow: 'hidden' }}
              >
                <Row gap={1}>
                  {meetingRecording.domains.map((domain, index) => (
                    <OrganizationChip
                      key={`meeting-recording-${meetingRecording.id}-${domain}-${index}`}
                      domain={domain}
                      sx={{ width: '100%' }}
                    />
                  ))}
                </Row>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              borderLeft: (theme) => `1px solid ${theme.palette.divider}`,

              width: hasDomains ? '25%' : '50%',
            }}
          >
            <AccessTime
              sx={{
                fontSize: '0.8rem',
                mr: 1,
                color: (theme) => lighten(theme.palette.text.secondary, 0.5),
              }}
            />
            <Box sx={{ mt: '2px' }}>
              {calculateMeetingLength(meetingRecording)}
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

export default MeetingRecordingListItem
