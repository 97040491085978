import { useContext, useEffect, useMemo, useState } from 'react'

import { Autocomplete, Box, TextField } from '@mui/material'
import { RiSearch2Line } from '@remixicon/react'
import Fuse from 'fuse.js'

import { NativeObjectFormatters } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'
import { getAllRelationshipSummaryTypes } from 'src/lib/relationshipSummary'

import ContactAvatar from '../../ContactAvatar/ContactAvatar'
import Row from '../../Row/Row'

const PersonFinder = ({
  onSelect,
  selected = [],
  selectedTypes = [],
  userEmail = null,
  excludedEmails = [],
  multiple = false,
  clearOnSelect = false,
  label = 'Find a person',
  size = 'medium',
  shadow = true,
  showAvatars = true,
  sx,
}: {
  onSelect: (person: any) => void
  selected?: string[]
  selectedTypes?: string[]
  userEmail?: string
  excludedEmails?: string[]
  multiple?: boolean
  clearOnSelect?: boolean
  label?: string
  size?: 'small' | 'medium'
  shadow?: boolean
  showAvatars?: boolean
  sx?: any
}) => {
  const { people } = useContext(DayContext)
  const [selectedValue, setSelectedValue] = useState(null)
  const [showResults, setShowResults] = useState(false)

  const handleSelect = (person) => {
    if (person) {
      onSelect(person)
      setShowResults(false)
    } else {
      onSelect(null)
    }
    if (clearOnSelect) {
      setSelectedValue(null)
    }
  }
  const handleInputChanged = (e) => {
    if (e?.target?.value?.length > 0 && !selectedValue) {
      setShowResults(true)
    } else {
      setShowResults(false)
    }
  }

  const highlightText = (fullText, match) => {
    let lastIndex = 0
    const highlighted = []

    // Loop through all index pairs in the match
    match.indices.forEach(([start, end]) => {
      // Add non-matching text
      if (start > lastIndex) {
        highlighted.push(fullText.slice(lastIndex, start))
      }
      // Add matching text wrapped in a span
      highlighted.push(
        <span
          key={`highlight_${start}`}
          style={{ fontWeight: 600 }}
        >
          {fullText.slice(start, end + 1)}
        </span>
      )
      lastIndex = end + 1
    })

    // Add any remaining text after the last match
    if (lastIndex < fullText.length) {
      highlighted.push(fullText.slice(lastIndex))
    }

    return highlighted
  }

  const renderLabel = (option) => {
    const matches = option?.matches || []
    const fullName = option?.properties?.fullName || ''
    const objectId = option?.objectId || ''

    const fullNameMatch = matches.find((m) => m.key === 'properties.fullName')
    const objectIdMatch = matches.find((m) => m.key === 'objectId')

    return (
      <div>
        {fullNameMatch ? highlightText(fullName, fullNameMatch) : fullName}
        {fullName ? ' - ' : ''}
        {objectIdMatch ? highlightText(objectId, objectIdMatch) : objectId}
      </div>
    )
  }

  const scorePerson = (person) => {
    return 0
    /*
    let score = 0
    if (person.properties?.firstName) {
      score++
    }
    if (person.properties?.lastName) {
      score++
    }
    if (person.properties?.['relationshipSummary/short']) {
      score += 2
    }

    if (person.properties?.updatedAt) {
      // Assuming `person.properties.updatedAt` is a valid date string or timestamp
      const updatedAt = new Date(person.properties.updatedAt)
      const now = new Date()
      const diffTime = Math.abs(now - updatedAt) // Time difference in milliseconds
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) // Convert milliseconds to days

      // Boosting the score based on the last update time
      if (diffDays < 7) {
        score += 2 // Boost score by 2 if updated within the last week
      } else if (diffDays < 30) {
        score += 1 // Boost score by 1 if updated within the last 30 days
      }
    }

    score += getScoreForSearchFromCrmObject(person)

    return score || 0*/
  }

  const searchOptions = {
    keys: ['properties.fullName', 'objectId', 'properties.companyName'],
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
  }
  const allPeople = people

  const filteredPeople = useMemo(() => {
    const cleanPeopleSet = [...allPeople].filter((person) => {
      let shouldReturn = !excludedEmails.includes(person.objectId)
      if (selectedTypes.length > 0 && shouldReturn) {
        const allMatchingKeys = getAllRelationshipSummaryTypes(selectedTypes)
        shouldReturn = allMatchingKeys.includes(
          person.properties['relationshipSummary/type']
        )
      }

      return shouldReturn
    })
    return cleanPeopleSet
  }, [allPeople, selectedTypes])

  const fuse = useMemo(() => {
    return new Fuse(filteredPeople, searchOptions)
  }, [filteredPeople])

  const filterOptions = (options, state) => {
    if (state.inputValue) {
      const matches = fuse.search(state.inputValue).map((result) => {
        return { ...result.item, matches: result.matches }
      })

      /*const sortedOptions = matches
        .sort((a, b) => (scorePerson(b) < scorePerson(a) ? 1 : 0))
        .filter((person) => {
          return !excludedSenderNames.some((excludeString) =>
            person.objectId.includes(excludeString)
          )
        })

      return sortedOptions*/

      return matches
    } else if (selectedTypes.length > 0) {
      return options
    } else return []
  }

  useEffect(() => {
    const selectedPerson = allPeople?.filter((person) =>
      selected?.includes(person.objectId)
    )[0]
    if (selectedPerson) {
      setSelectedValue(selectedPerson)
    } else {
      setSelectedValue(null)
    }
  }, [selected, allPeople])

  return (
    <Row
      key={`personFinder_${selectedValue?.objectId || 'none'}`}
      sx={{
        width: '100%',
      }}
    >
      <Autocomplete
        sx={{
          ...sx,
          flexGrow: 1,
        }}
        options={filteredPeople}
        filterOptions={filterOptions}
        multiple={multiple}
        open={!!(showResults || selectedTypes.length > 0)}
        getOptionLabel={(option) =>
          `${NativeObjectFormatters[NativeObjectTypes.Contact].label(
            option
          )} (${NativeObjectFormatters[NativeObjectTypes.Contact].subtitle(
            option
          )})`
        }
        getOptionKey={(option) => option.objectId}
        renderOption={(props, option) => (
          <Row
            sx={{
              fontSize: '0.7rem',
              px: 1,
              position: 'relative',
              zIndex: 9999,
            }}
            {...props}
          >
            {showAvatars && (
              <ContactAvatar
                email={option.objectId}
                size={20}
              />
            )}
            <Box
              sx={{
                ml: showAvatars ? 1 : 0,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {renderLabel(option)}
            </Box>
          </Row>
        )}
        value={selectedValue}
        onInput={handleInputChanged}
        onChange={(e, v) => handleSelect(v)}
        slotProps={{
          popper: {
            sx: {
              opacity: showResults || selectedTypes.length > 0 ? 1 : 0,
              maxHeight: '280px',
              overflowY: 'scroll',
              background: (theme) => theme.palette.background.paper,
              boxShadow: shadow
                ? `
                0 1px 2px rgba(136, 161, 183, 0.07),
                0 2px 4px rgba(136, 161, 183, 0.07),
                0 4px 8px rgba(136, 161, 183, 0.07),
                0 8px 16px rgba(136, 161, 183, 0.07),
                0 16px 32px rgba(136, 161, 183, 0.07),
                0 32px 64px rgba(136, 161, 183, 0.07)
              `
                : 'none',
              transition: 'opacity 0.2s',
              borderRadius: '0px',
              '& > div': {
                borderRadius: '0px',
              },
            },
          },
        }}
        popupIcon={<></>}
        noOptionsText="No people found"
        renderInput={(params) => (
          <TextField
            {...params}
            size={size}
            placeholder={label}
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                py: 0,
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: <RiSearch2Line size={18} />,
              sx: {
                background: (theme) => theme.palette.background.paper,
              },
            }}
          />
        )}
      />
    </Row>
  )
}

export default PersonFinder
