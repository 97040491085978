import { useContext, useEffect, useMemo, useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  RiArrowLeftRightLine,
  RiCheckLine,
  RiFileCopyLine,
  RiMoreLine,
  RiSubtractLine,
  RiTimeLine,
} from '@remixicon/react'
import { Dayjs } from 'dayjs'
import toast from 'react-hot-toast'
import { Action, WorkspaceMember } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import ActionDialog from 'src/components/Actions/ActionDialog/ActionDialog'
import { DayContext } from 'src/lib/dayContext'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'

import ContactTile from '../../ContactTile/ContactTile'
import Row from '../../Row/Row'
import WorkspaceMemberSelect from '../../WorkspaceMemberSelect/WorkspaceMemberSelect'
import {
  IconCheck,
  IconClock,
  IconFileStack,
  IconThumbDown,
  IconUserShare,
} from '@tabler/icons-react'

const DELETE_ACTION = gql`
  mutation DeleteAction($id: String!, $workspaceId: String!) {
    deleteAction(id: $id, workspaceId: $workspaceId)
  }
`

const COMPLETE_ACTION = gql`
  mutation CompleteAction($id: String!, $workspaceId: String!) {
    completeAction(id: $id, workspaceId: $workspaceId)
  }
`

const SNOOZE_ACTION = gql`
  mutation SnoozeAction(
    $id: String!
    $workspaceId: String!
    $snoozeUntil: DateTime!
  ) {
    snoozeAction(id: $id, workspaceId: $workspaceId, snoozeUntil: $snoozeUntil)
  }
`

const REASSIGN_ACTION = gql`
  mutation ReassignAction(
    $id: String!
    $workspaceId: String!
    $reassigneeEmail: String!
    $reassigneeId: String!
  ) {
    reassignAction(
      id: $id
      workspaceId: $workspaceId
      reassigneeEmail: $reassigneeEmail
      reassigneeId: $reassigneeId
    )
  }
`

const DEDUPLICATE_ACTION = gql`
  mutation DeduplicateAction($id: String!, $workspaceId: String!) {
    deduplicateActionAsync(workspaceId: $workspaceId, actionId: $id)
  }
`

const ActionActions = ({
  action,
  onUpdate,
  inFocus = false,
  onClose = () => {},
  onHide = () => {},
  showButtons = false,
  size = 18,
  buttons = [],
}: {
  action: Action
  onUpdate: (args?: {
    id?: string
    status?: string
    owner?: { id: string; email: string }
  }) => void
  inFocus?: boolean
  onClose?: () => void
  onHide?: (actionId: string) => void
  showButtons?: boolean
  buttons?: string[]
  size?: number
}) => {
  const { selectedWorkspace: workspaceId } = useContext(DayContext)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const [openDialog, setOpenDialog] = useState(false)
  const [reassignOpen, setReassignOpen] = useState(false)
  const [snoozeOpen, setSnoozeOpen] = useState(false)
  const [snoozeValue, setSnoozeValue] = useState<Dayjs | null>(null)
  const [reassignee, setReassignee] = useState<WorkspaceMember | null>(null)

  const [deleteAction] = useMutation(DELETE_ACTION)
  const [completeAction] = useMutation(COMPLETE_ACTION)
  const [reassignAction] = useMutation(REASSIGN_ACTION)
  const [snoozeAction] = useMutation(SNOOZE_ACTION)
  const [deduplicateAction] = useMutation(DEDUPLICATE_ACTION)

  const buttonsToShow = useMemo(() => {
    if (buttons.length === 0)
      return ['SNOOZE', 'REASSIGN', 'DELETE', 'DEDUPLICATE', 'COMPLETE']
    return buttons
  }, [action.status, buttons])

  useEffect(() => {
    setOpenDialog(inFocus)
  }, [inFocus])

  const handleDelete = async () => {
    try {
      onHide(action.id)
      await deleteAction({
        variables: { id: action.id, workspaceId },
      })
      onUpdate({ id: action.id, status: 'DISMISSED' })
    } catch (error) {
      toast.error('Error dismissing action')
      logger.error('Error deleting action:', error)
    }
  }

  const handleComplete = async () => {
    try {
      onHide(action.id)
      await completeAction({
        variables: { id: action.id, workspaceId },
      })
      onUpdate({ id: action.id, status: 'COMPLETED' })
    } catch (error) {
      toast.error('Error completing action')
      logger.error('Error completing action:', error)
    }
  }

  const handleReassign = async () => {
    try {
      onHide(action.id)
      setReassignOpen(false)
      setReassignee(null)
      await reassignAction({
        variables: {
          id: action.id,
          workspaceId,
          reassigneeEmail: reassignee.email,
          reassigneeId: reassignee.id,
        },
      })
      toast.success('Action reassigned')
      onUpdate({
        id: action.id,
        owner: { id: reassignee.id, email: reassignee.email },
      })
    } catch (error) {
      toast.error('Error reassigning action')
      logger.error('Error reassigning action:', error)
    }
  }

  const handleSnooze = async () => {
    try {
      await snoozeAction({
        variables: {
          id: action.id,
          workspaceId,
          snoozeUntil: snoozeValue?.toDate(),
        },
      })
      onUpdate({ id: action.id, status: 'SNOOZED' })
      setSnoozeOpen(false)
    } catch (error) {
      toast.error('Error snoozing action')
      logger.error('Error snoozing action:', error)
    }
  }

  const handleDeduplicate = async () => {
    try {
      await deduplicateAction({
        variables: { id: action.id, workspaceId },
      })
      onUpdate({ id: action.id, status: 'NONE' })
    } catch (error) {
      toast.error('Error deduplicating action')
      logger.error('Error deduplicating action:', error)
    }
  }

  if (!action?.id) {
    return null
  }

  return (
    <Box
      sx={{
        '& .MuiButtonBase-root': {
          p: '3px',
          borderRadius: '3px',
          '&:hover': {
            backgroundColor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.secondary.contrastText,
          },
        },
      }}
    >
      {showButtons ? (
        <Row gap={1}>
          {buttonsToShow.includes('COMPLETE') && (
            <Tooltip
              title="Mark complete"
              arrow={true}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  handleComplete()
                }}
              >
                <IconCheck size={size} />
              </IconButton>
            </Tooltip>
          )}
          {buttonsToShow.includes('SNOOZE') && (
            <Tooltip
              title="Snooze action"
              arrow={true}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  setSnoozeOpen(true)
                }}
              >
                <IconClock size={size} />
              </IconButton>
            </Tooltip>
          )}
          {buttonsToShow.includes('REASSIGN') && (
            <Tooltip
              title="Reassign"
              arrow={true}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  setReassignOpen(true)
                }}
              >
                <IconUserShare size={size} />
              </IconButton>
            </Tooltip>
          )}
          {buttonsToShow.includes('DEDUPLICATE') && (
            <Tooltip
              title="Ask AI to deduplicate"
              arrow={true}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  handleDeduplicate()
                }}
              >
                <IconFileStack size={size} />
              </IconButton>
            </Tooltip>
          )}
          {buttonsToShow.includes('DELETE') && (
            <Tooltip
              title="Dismiss"
              arrow={true}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  handleDelete()
                }}
              >
                <IconThumbDown size={size} />
              </IconButton>
            </Tooltip>
          )}
        </Row>
      ) : (
        <Row sx={{ height: `${size}px` }}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setAnchorEl(e.currentTarget)
            }}
            sx={{ p: '3px', borderRadius: '3px' }}
          >
            <RiMoreLine size={size} />
          </IconButton>
          <Menu
            open={menuOpen}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            sx={{
              p: 0,
              '& .MuiTypography-root': {
                fontSize: '12px',
                fontWeight: 500,
                letterSpacing: '-0.3px',
                color: 'text.secondary',
              },
            }}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation()
                handleComplete()
              }}
              sx={{ p: 0, pr: 3 }}
            >
              <Row>
                <IconButton>
                  <RiCheckLine size={size} />
                </IconButton>
                <Typography>Complete</Typography>
              </Row>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation()
                setReassignOpen(true)
              }}
              sx={{ p: 0, pr: 3 }}
            >
              <Row>
                <IconButton>
                  <RiArrowLeftRightLine size={size} />
                </IconButton>
                <Typography>Reassign</Typography>
              </Row>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation()
                setSnoozeOpen(true)
              }}
              sx={{ p: 0, pr: 3 }}
            >
              <Row>
                <IconButton>
                  <RiTimeLine size={size} />
                </IconButton>
                <Typography>Snooze</Typography>
              </Row>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation()
                handleDelete()
              }}
              sx={{ p: 0, pr: 3 }}
            >
              <Row>
                <IconButton>
                  <RiSubtractLine size={size} />
                </IconButton>
                <Typography>Dismiss</Typography>
              </Row>
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                e.stopPropagation()
                handleDeduplicate()
              }}
              sx={{ p: 0, pr: 3 }}
            >
              <Row>
                <IconButton>
                  <RiFileCopyLine size={size} />
                </IconButton>
                <Typography>Ask AI to deduplicate</Typography>
              </Row>
            </MenuItem>
          </Menu>
        </Row>
      )}
      {openDialog && (
        <ActionDialog
          action={action}
          onClose={() => {
            onClose()
            setOpenDialog(false)
          }}
          onUpdate={onClose}
        />
      )}
      <Dialog
        open={reassignOpen}
        onClose={() => {
          setReassignee(null)
          setReassignOpen(false)
        }}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>Reassign Action</DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              p: 2,
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderRadius: 10,
              fontWeight: 500,
              letterSpacing: '-0.3px',
              mb: 3,
            }}
          >
            {`"${action.title}"`}
          </Typography>
          <Box sx={{ pt: 1 }}>
            {reassignee ? (
              <ContactTile
                email={reassignee.email}
                showSidebar={false}
              />
            ) : (
              <WorkspaceMemberSelect
                onSelect={(members) => {
                  setReassignee(members?.[0])
                }}
                value={[]}
                exclude={[action.owner?.id, action.owner?.email]}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!reassignee || reassignee?.id === action.owner?.id}
            onClick={handleReassign}
            size="large"
            variant={reassignee ? 'contained' : 'outlined'}
            disableElevation={true}
            fullWidth={true}
          >
            {reassignee?.id === action.owner?.id
              ? 'Already assigned'
              : 'Reassign'}
          </Button>
        </DialogActions>
      </Dialog>
      {snoozeOpen && (
        <Dialog
          open={snoozeOpen}
          onClose={() => setSnoozeOpen(false)}
        >
          <DialogTitle>Snooze until ...</DialogTitle>
          <DialogContent sx={{ height: '324px', overflow: 'hidden' }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              dateLibInstance={dayjs}
            >
              <DateCalendar
                value={snoozeValue}
                onChange={(newValue) => setSnoozeValue(newValue)}
                timezone="system"
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions sx={{ pt: 0 }}>
            <Button
              variant="outlined"
              fullWidth={true}
              onClick={handleSnooze}
            >
              Snooze
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  )
}

export default ActionActions
