import { CssBaseline, GlobalStyles } from '@mui/material'
import { Toaster } from 'react-hot-toast'

type OnboardingLayoutProps = {
  children?: React.ReactNode
}

const OnboardingLayout = ({ children }: OnboardingLayoutProps) => {
  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: (theme) =>
              `${theme.palette.background.default} !important`,
          },
        }}
      />
      <Toaster />
      <CssBaseline />
      {children}
    </>
  )
}

export default OnboardingLayout
