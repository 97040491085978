type ScopeEntry = {
  label: string
  reason: string
  link: string
  googleDocLink: string
  level: 'non-senstitive' | 'sensitive' | 'restricted'
  icon: string
  necessary: boolean
}

type ScopeEntries = {
  [scope: string]: ScopeEntry
}

export const requiredGoogleScopes = {
  'https://www.googleapis.com/auth/gmail.compose': {
    label: 'Compose Gmail Messages',
    reason: 'Needed to create email drafts and send emails on your behalf.',
    googleDocLink:
      'https://developers.google.com/gmail/api/v1/reference/users/messages/send',
    level: 'critical',
    icon: 'ri-mail-send-line',
    type: 'GMAIL',
    necessary: true,
  },
  'https://www.googleapis.com/auth/gmail.readonly': {
    label: 'Read Gmail Messages',
    reason:
      'Day.ai analyzes your Gmail history to build up-to-date "Relationship Summaries" that represent where you stand with each person you work with, including outstanding action items, promises-made, and other key information to help you further and deepen your business relationship. This information is stored securely and encrypted, is not readable by either Day.ai employees or by other members of conencted Workspaces. You will see clearly in the Day.ai application where this information appears, and that this information is totally private to you, unless in specific cases that you expressly permit in a controlled and revokable manner. We take this permission extremely seriously and have passed the Google Security Review and CASA-2-based Price Waterhouse Cooper audit (annual) required by Google to use this permission.',
    googleDocLink: 'https://developers.google.com/gmail/api/v1/reference/',
    level: 'sensitive',
    icon: 'ri-mail-line',
    type: 'GMAIL',
    necessary: true,
  },
  'https://www.googleapis.com/auth/userinfo.profile': {
    label: 'Access User Profile',
    reason:
      'Day.ai uses this to auto-populate your user profile, to save you time entering your name. Day.ai does not use the photo from this response though, because it is usually not high quality.',
    googleDocLink:
      'https://developers.google.com/identity/protocols/oauth2/scopes#userinfo.profile',
    level: 'sensitive',
    icon: 'ri-account-circle-line',
    type: 'CONTACTS',
    necessary: true,
  },
  openid: {
    label: 'OpenID',
    reason:
      'Enables sign in with your Google account for a seamless experience.',
    googleDocLink:
      'https://developers.google.com/identity/protocols/oauth2/openid-connect',
    level: 'sensitive',
    icon: 'ri-key-line',
    type: 'USER',
    necessary: true,
  },
  'https://www.googleapis.com/auth/userinfo.email': {
    label: 'Access User Email',
    reason:
      'Day.ai uses this to verify the email identity of this Google Account.',
    googleDocLink:
      'https://developers.google.com/identity/protocols/oauth2/scopes#userinfo.email',
    level: 'sensitive',
    icon: 'ri-mail-line',
    type: 'USER',
    necessary: true,
  },
  'https://www.googleapis.com/auth/calendar.events': {
    label: 'Manage Calendar Events',
    reason:
      'Day.ai uses this scope to watch your calendar for upcoming meetings to record, and to provide you controls to set the Day.ai Assistant to record or not record the meeting. Also Day.ai helps you prepare for your upcoming meetings.',
    googleDocLink: 'https://developers.google.com/calendar/v3/reference/events',
    level: 'sensitive',
    icon: 'ri-calendar-event-line',
    type: 'CALENDAR',
    necessary: true,
  },
  'https://www.googleapis.com/auth/contacts.readonly': {
    label: 'Read Contacts',
    reason:
      'Day.ai uses this permission to automatically build your People (contacts) database in the Workspace(s) to which you connect this Google Account.',
    googleDocLink: 'https://developers.google.com/contacts/v3/',
    level: 'sensitive',
    icon: 'ri-contacts-line',
    type: 'CONTACTS',
    necessary: true,
  },
  'https://www.googleapis.com/auth/contacts.other.readonly': {
    label: 'Read Other Contacts',
    reason:
      'Day.ai uses this permission to automatically build your People (contacts) database in the Workspace(s) to which you connect this Google Account.',
    googleDocLink: 'https://developers.google.com/contacts/v3/other-contacts',
    level: 'sensitive',
    icon: 'ri-group-line',
    type: 'CONTACTS',
    necessary: true,
  },
}

export const isWorkAccountHealthy = ({ workAccount }) => {
  let healthy = false
  const gmailStatus = workAccount.googleSyncStatuses.find(
    (status) => status.type === 'GMAIL'
  )
  const calendarStatus = workAccount.googleSyncStatuses.find(
    (status) => status.type === 'CALENDAR'
  )
  const contactsStatus = workAccount.googleSyncStatuses.find(
    (status) => status.type === 'CONTACTS'
  )

  if (
    gmailStatus?.status != 'SUSPENDED' &&
    calendarStatus?.status != 'SUSPENDED' &&
    contactsStatus?.status != 'SUSPENDED'
  ) {
    healthy = true
  }

  return healthy
}
