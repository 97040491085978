import { useEffect, useMemo, useState } from 'react'

import { Avatar, AvatarGroup, Box, Skeleton, Typography } from '@mui/material'
import {
  RiCalendarEventLine,
  RiDraggable,
  RiFilmLine,
  RiMovieFill,
  RiTimeLine,
} from '@remixicon/react'
import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react'
import dayjs from 'dayjs'

import { useQuery } from '@redwoodjs/web'

import AvatarRow from 'src/components/AvatarRow/AvatarRow'
import MeetingRecordingDialog from 'src/components/MeetingRecordingDialog/MeetingRecordingDialog'
import { calculateMeetingLength } from 'src/components/MeetingRecordingsTable/MeetingRecordingsTable'
import Row from 'src/components/Row/Row'
import { logger } from 'src/lib/logger'

import {
  nodeDescriptionSx,
  nodeDragHandleSx,
  nodeTextDetailsContainerSx,
  nodeTitleSx,
  nodeViewContainerSx,
} from '../styles'

const GET_MEETING_RECORDING_FOR_TIPTAP = gql`
  query GetMeetingRecordingForTiptap($id: String!) {
    meetingRecording: meetingRecordingForPageBlock(id: $id) {
      id
      statusHistory {
        level
        status
        reason
        message
        createdAt
      }
      title
      startedAt
      endedAt
      summary {
        status
        output
      }
      video {
        private {
          thumbnail {
            status
            url
          }
          download {
            status
            urls {
              default
              quality
              url
            }
          }
          playbackId
          tokens {
            video
            thumbnail
            storyboard
          }
        }
      }
      clips {
        id
        title
        description
        video {
          private {
            thumbnail {
              status
              url
            }
            download {
              status
              urls {
                default
                quality
                url
              }
            }
            playbackId
            tokens {
              video
              thumbnail
              storyboard
            }
          }
        }
      }
      participants {
        email
        contact {
          objectType
          objectId
          properties
        }
      }
    }
  }
`

const TiptapNodeMeetingRecordingRenderer = (props) => {
  const [open, setOpen] = useState(false)
  const [recording, setRecording] = useState(null)
  const meetingRecordingId = useMemo(
    () => props.node.attrs.id,
    [props.node.attrs.id]
  )

  const { loading } = useQuery(GET_MEETING_RECORDING_FOR_TIPTAP, {
    variables: {
      id: meetingRecordingId,
    },
    skip: recording,
    onCompleted: (result) => {
      setRecording(result.meetingRecording)
    },
  })

  useEffect(() => {
    logger.dev({ open })
  }, [open])

  return (
    <NodeViewWrapper
      as="div"
      className="day-ai-meetingrecording"
      draggable={props.editor.isEditable}
      {...{ 'data-drag-handle': '' }}
    >
      <Row
        className={`day-ai-node ${
          props.editor.isEditable ? 'editable' : 'public'
        } ${props.selected ? 'selected' : ''}`}
        sx={{ ...nodeViewContainerSx, height: '104px', cursor: 'pointer' }}
        onClick={(e) => {
          e.stopPropagation()
          setOpen(true)
        }}
      >
        <Box
          className="drag-handle"
          sx={nodeDragHandleSx}
        >
          <RiDraggable />
        </Box>
        {loading ? (
          <Box sx={{ pl: 3, width: '100%' }}>
            <Row>
              <Skeleton
                animation="wave"
                variant="circular"
                height={48}
                width={48}
                sx={{ flexShrink: 0 }}
              />
              <Box sx={{ width: '100%', ml: 2 }}>
                <Skeleton
                  animation="wave"
                  height={24}
                />
                <Skeleton
                  animation="wave"
                  height={16}
                />
                <Skeleton
                  animation="wave"
                  height={16}
                />
              </Box>
            </Row>
          </Box>
        ) : (
          <Row sx={{ width: '100%', pl: 3, cursor: 'pointer' }}>
            <AvatarRow
              emails={recording?.participants.map(
                (participant) => participant.email
              )}
              size={56}
              borderRadius={100}
              max={5}
              margin={'-18px'}
            />
            <Box sx={{ ...nodeTextDetailsContainerSx, height: 'auto' }}>
              <Typography
                sx={{
                  ...nodeTitleSx,
                  fontSize: '1.0rem',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <RiMovieFill
                  size={16}
                  style={{ marginRight: '8px' }}
                />
                {recording?.title}
              </Typography>
              <Typography sx={nodeDescriptionSx}>
                {recording?.summary?.output.Summary_Short}
              </Typography>
              <Row sx={{ mt: '4px' }}>
                <RiTimeLine size={14} />
                <Typography
                  sx={{ ml: '4px', fontSize: '0.7rem !important', mr: 2 }}
                >
                  {calculateMeetingLength(recording)}
                </Typography>
                <RiCalendarEventLine size={14} />
                <Typography
                  sx={{ ml: '4px', fontSize: '0.7rem !important', mr: 2 }}
                >
                  {dayjs(recording?.startedAt).format('MMM D, YYYY')}
                </Typography>
                {recording?.clips?.length > 0 && (
                  <>
                    <RiFilmLine size={14} />
                    <AvatarGroup
                      max={8}
                      componentsProps={{
                        additionalAvatar: { sx: { display: 'none' } },
                      }}
                      variant="rounded"
                    >
                      {recording?.clips.map((clip) => (
                        <Avatar
                          key={`clipAvatar-${clip.id}`}
                          alt={clip.title}
                          src={clip.video.private.thumbnail.url}
                          sx={{ width: 14, height: 14 }}
                        />
                      ))}
                    </AvatarGroup>
                    <Typography
                      sx={{ ml: '4px', fontSize: '0.7rem !important' }}
                    >
                      {`${recording?.clips?.length} clips`}
                    </Typography>
                  </>
                )}
              </Row>
            </Box>
          </Row>
        )}
      </Row>
      <MeetingRecordingDialog
        open={open}
        id={meetingRecordingId}
        //prefetched={recording}
        onClose={() => setOpen(false)}
      />
    </NodeViewWrapper>
  )
}

const TiptapNodeMeetingRecording = Node.create({
  name: 'meetingrecording',
  group: 'block',
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'day-ai-meetingrecording',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const meetingRecordingAttributes = mergeAttributes(HTMLAttributes, {
      'data-type': 'meetingrecording',
    })
    return ['day-ai-meetingRecording', meetingRecordingAttributes]
  },

  addNodeView() {
    return ReactNodeViewRenderer(TiptapNodeMeetingRecordingRenderer)
  },
})

export default TiptapNodeMeetingRecording
