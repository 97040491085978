import { datadogLogs, type Logger } from '@datadog/browser-logs'

type JsonValue =
  | string
  | number
  | boolean
  | null
  | undefined
  | JsonValue[]
  | { [key: string]: JsonValue }

type LoggerContext = {
  [key: string]: JsonValue
}

const consoleLogger =
  process.env.NODE_ENV === 'production'
    ? datadogLogs.createLogger('console-logger', {
        handler: 'console',
      })
    : console

let datadogLogger: Logger
if (process.env.NODE_ENV === 'production') {
  datadogLogger = datadogLogs.createLogger('datadog-logger', {
    handler: 'http',
  })
}

export const logger = {
  error: <T extends Error>(
    message: string,
    error: T,
    context?: LoggerContext
  ) => {
    if (datadogLogger) {
      datadogLogger.error(message, context, error)
    } else {
      consoleLogger.error(message, context, error)
    }
  },
  warn: (message: string, context?: LoggerContext) => {
    if (datadogLogger) {
      datadogLogger.warn(message, context)
    } else {
      consoleLogger.warn(message, context)
    }
  },
  info: (message: string, context?: LoggerContext) => {
    if (datadogLogger) {
      datadogLogger.info(message, context)
    } else {
      consoleLogger.info(message, context)
    }
  },
  debug: (message: string, context?: LoggerContext) => {
    if (datadogLogger) {
      datadogLogger.debug(message, context)
    } else {
      consoleLogger.debug(message, context)
    }
  },
  dev: (...args: any[]) => {
    if (process.env.NODE_ENV === 'production') {
      return
    }

    console.log(...args)
  },
}
