import React, { useState, useEffect } from "react";
import { BackToTopArrow } from "./Icons";
const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <button aria-label="back to top"
      className={`back_to_top fixed flex items-center  h-9 w-9 sm:h-10 sm:w-10 lg:h-12 lg:w-12 justify-center  p-3 bg-matt_purple hover:scale-110 hover:text-white hover:bg-blue transition-all right-6  duration-200 border-2 border-blue text-blue bg-white  rounded-full z-50 ${
        isVisible
          ? " bottom-6 opacity-100 scale-100"
          : "-bottom-20 opacity-0 scale-0"
      }`}
      onClick={scrollToTop}>
      <span>
        <BackToTopArrow />
      </span>
    </button>
  );
};
export default BackToTop;
