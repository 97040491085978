import { useCallback, useContext, useEffect, useMemo } from 'react'

import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Tooltip,
  Typography,
  darken,
  lighten,
} from '@mui/material'
import { RiBarChartFill, RiFlashlightFill } from '@remixicon/react'
import dayjs from 'dayjs'

import { useAuth } from 'src/auth'
import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import Row from '../../Row/Row'
import {
  IconBoltFilled,
  IconCircleCheck,
  IconThumbDown,
  IconThumbUp,
} from '@tabler/icons-react'
import PersonChip from 'src/components/People/PersonChip/PersonChip'

const formatTimestampExpectedClose = (timestamp: string): string => {
  const date = dayjs(timestamp)

  return date.format(`MMMM 'YY`)
}

export const formatDealAmount = (value: number): string => {
  if (!value) return '$0'
  return `$${value.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })}`
}

export const warmthEmoji = {
  all: '🌐',
  hot: '🔥',
  warm: '😎',
  cold: '🧊',
}

const PipelineBoardCard = ({
  data,
  dragging,
  setIsDragging,
  pipeline,
  refetch,
  actions,
  onApproveGenericOpportunity,
  onDeclineGenericOpportunity,
}) => {
  const { currentUser } = useAuth()
  const { setSidebarObject, userCoreContact } = useContext(DayContext)

  const todosForUser = useMemo(
    () => actions?.filter((action) => action?.owner?.id === currentUser?.id),
    [actions, currentUser?.id]
  )

  const todoForUser = null // todosForUser?.[0]?.title

  const memoizedSetIsDragging = useCallback(
    (value) => setIsDragging(value),
    [setIsDragging]
  )

  const handleMouseUp = useCallback(
    (e) => {
      if (!dragging) {
        e.stopPropagation()
        const opp = {
          objectType: NativeObjectTypes.Opportunity,
          objectId: data.id,
          properties: {
            ...data,
            opportunityTypes: pipeline?.opportunityTypes,
            refetch,
          },
        }
        setSidebarObject(opp)
      }
    },
    [dragging]
  )

  useEffect(() => {
    memoizedSetIsDragging(dragging)
  }, [dragging, memoizedSetIsDragging])

  return (
    <>
      <Card
        className={`react-kanban-card dragging-${dragging} ribbon`}
        onMouseUp={handleMouseUp}
        sx={{
          height: '220px',
          flexShrink: 0,
          mb: '6px',
          p: '4px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Box>
            <Row
              sx={{
                opacity: data?.isSuggested ? 0.4 : 1,
                height: '36px',
              }}
            >
              <Box
                sx={{
                  p: 0,
                  m: 0,
                  height: '36px',
                  width: '36px',
                }}
              >
                <Badge
                  badgeContent={
                    todosForUser?.length > 0 ? (
                      <Tooltip
                        title={`${todosForUser?.length} action${
                          todosForUser?.length > 1 ? 's' : ''
                        } for you`}
                        arrow={true}
                      >
                        <IconBoltFilled size={12} />
                      </Tooltip>
                    ) : null
                  }
                  color="primary"
                  sx={{
                    '& .MuiBadge-badge': {
                      px: '2px',
                    },
                  }}
                >
                  {data?.domain ? (
                    <DomainAvatar
                      photoUrl={data?.organization?.photoSquare}
                      domain={data?.domain}
                      size={36}
                      borderRadius={100}
                    />
                  ) : data?.primaryPerson?.objectId ? (
                    <ContactAvatar
                      email={data?.primaryPerson?.objectId}
                      size={36}
                      borderRadius={100}
                    />
                  ) : (
                    <Avatar
                      src={null}
                      sx={{
                        height: '36px',
                        width: '36px',
                        objectFit: 'cover',
                        borderRadius: '6px',
                      }}
                    >
                      <RiBarChartFill
                        style={{ height: '20px', width: '20px' }}
                      />
                    </Avatar>
                  )}
                </Badge>
              </Box>

              <Box sx={{ pl: '6px', width: '100%' }}>
                <Row sx={{ justifyContent: 'space-between', width: '100%' }}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: '600',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: pipeline?.hasRevenue ? '132px' : '196px',
                      letterSpacing: '-0.9px',
                      lineHeight: '130%',
                    }}
                  >
                    {data?.title || data?.organization?.name}
                  </Typography>

                  {pipeline?.hasRevenue ? (
                    <Typography
                      sx={{
                        fontSize: '1.0rem',
                        fontWeight: '600',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        letterSpacing: '-0.3px',
                        color: (theme) =>
                          data?.stage?.likelihoodToClose === 1
                            ? theme.palette.success.main
                            : theme.palette.text.primary,
                      }}
                    >
                      {formatDealAmount(data?.expectedRevenue)}
                    </Typography>
                  ) : null}
                </Row>

                <Row sx={{ justifyContent: 'space-between', width: '100%' }}>
                  <Typography
                    sx={{
                      fontSize: '0.7rem',
                      fontWeight: '400',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {data?.type}
                  </Typography>

                  {pipeline?.hasRevenue &&
                  data?.stage?.likelihoodToClose < 1 ? (
                    <Typography
                      sx={{
                        fontSize: '0.7rem',
                        fontWeight: '400',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {`Forecast ${formatDealAmount(
                        data?.expectedRevenue * data?.stage?.likelihoodToClose
                      )}`}
                    </Typography>
                  ) : null}
                </Row>
              </Box>
            </Row>
            <Divider sx={{ my: '12px' }} />
            {data?.isSuggested ? (
              <Box
                sx={{
                  mt: 1,
                  background: (theme) =>
                    lighten(theme.palette.success.light, 0.8),
                  borderRadius: '4px',
                  border: (theme) =>
                    `1px solid ${lighten(theme.palette.success.light, 0.4)}`,
                  height: '90px',
                  overflow: 'hidden',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 600,
                    letterSpacing: '-0.2px',
                    p: 1,
                    lineHeight: '100%',
                    textAlign: 'center',
                    color: (theme) => darken(theme.palette.success.dark, 0.2),
                  }}
                >
                  Suggested Opportunity
                </Typography>
                <Typography
                  sx={{
                    fontSize: '11px',
                    fontWeight: 500,
                    px: 1,
                    my: '-4px',
                    py: 0,
                    color: (theme) => theme.palette.success.dark,
                    textAlign: 'center',
                  }}
                >
                  Click to learn more
                </Typography>
                {/*}
                <Typography
                  sx={{
                    fontSize: '11px',
                    fontWeight: 500,
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: 'vertical',
                    height: '28px',
                    px: 1,
                    my: '0px',
                    color: (theme) => theme.palette.success.dark,
                  }}
                >
                  {data?.currentStatus}
                </Typography>*/}
                <Row
                  sx={{ justifyContent: 'space-between', px: 1, mt: '12px' }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<IconThumbUp size={16} />}
                    sx={{
                      width: '48%',
                      background: (theme) => theme.palette.background.paper,
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      e.nativeEvent.stopImmediatePropagation()
                      onApproveGenericOpportunity(data.id)
                    }}
                    onMouseUp={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      e.nativeEvent.stopImmediatePropagation()
                    }}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<IconThumbDown size={16} />}
                    sx={{
                      width: '48%',
                      background: (theme) => theme.palette.background.paper,
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      e.nativeEvent.stopImmediatePropagation()
                      onDeclineGenericOpportunity(data.id)
                    }}
                    onMouseUp={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      e.nativeEvent.stopImmediatePropagation()
                    }}
                  >
                    Decline
                  </Button>
                </Row>
              </Box>
            ) : todoForUser ? (
              <>
                <Row
                  sx={{
                    my: 2,
                    color: (theme) => theme.palette.secondary.main,
                    background: (theme) =>
                      lighten(theme.palette.secondary.main, 0.9),
                    px: 1,
                    py: '4px',
                    borderRadius: '4px',
                    border: '1px solid',
                    borderColor: (theme) =>
                      lighten(theme.palette.secondary.main, 0.5),
                  }}
                  gap={1}
                >
                  <ContactAvatar
                    email={currentUser.email}
                    size={16}
                    borderRadius={4}
                  />
                  <Typography
                    sx={{
                      fontSize: '0.7rem',
                      fontWeight: 500,
                      lineHeight: '150%',
                    }}
                  >{`${userCoreContact?.firstName} has ${
                    todosForUser.length
                  } next step${
                    todosForUser.length > 1 ? 's' : ''
                  }`}</Typography>
                </Row>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.primary,
                    fontSize: '0.7rem',
                    fontWeight: 500,
                    height: '54px',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  <RiFlashlightFill
                    size={16}
                    style={{ display: 'inline', marginRight: '4px' }}
                  />
                  {todoForUser}
                  {todosForUser?.length > 1 && (
                    <Box
                      component="span"
                      sx={{ fontWeight: 600, ml: 1 }}
                    >
                      +{todosForUser?.length - 1} more
                    </Box>
                  )}
                </Typography>
              </>
            ) : (
              <Typography
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  fontSize: '12px',
                  letterSpacing: '-0.6px',
                  lineHeight: '130%',
                  fontWeight: 400,
                  m: 0,
                  p: 0,
                  height: '48px',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  opacity: 0.8,
                }}
              >
                {data?.currentStatus}
              </Typography>
            )}
          </Box>
          {!data?.isSuggested ? (
            <Box>
              <Divider sx={{ my: '12px' }} />
              <Row sx={{ justifyContent: 'space-between' }}>
                <Row
                  sx={{
                    color: (theme) =>
                      lighten(theme.palette.text.secondary, 0.4),
                    fontSize: '0.7rem',
                    fontWeight: 500,
                    height: '18px',
                  }}
                >
                  {/*
                <RiTimeLine
                  size={16}
                  style={{
                    marginRight: '4px',
                  }}
                />
                {formatTimestampExpectedClose(data?.expectedCloseDate)}
                */}
                  <PersonChip
                    email={data?.ownerEmail}
                    variant="text"
                  />
                </Row>
                {data?.stage?.likelihoodToClose === 1 ? (
                  <Chip
                    icon={<IconCircleCheck size={16} />}
                    label="Closed Won"
                    size="small"
                    color="success"
                  />
                ) : data?.stage?.likelihoodToClose === 0 ? (
                  <Chip
                    label="Closed Lost"
                    size="small"
                    variant="outlined"
                  />
                ) : (
                  <Tooltip
                    arrow={true}
                    title={
                      data?.daysInStage > 90
                        ? 'Opportunity has been in this stage for over 90 days'
                        : data?.daysInStage > 45
                          ? 'Opportunity has been in this stage for over 45 days'
                          : data?.daysInStage > 30
                            ? 'Opportunity has been in this stage for over 30 days'
                            : 'Opportunity is waiting for a response'
                    }
                  >
                    <Chip
                      label={
                        data?.daysInStage > 90
                          ? 'Stuck'
                          : data?.daysInStage > 45
                            ? 'Stuck'
                            : data?.daysInStage > 30
                              ? 'Stuck'
                              : data?.daysInStage > 3
                                ? `${data?.daysInStage} days in stage`
                                : 'New in stage'
                      }
                      color={
                        data?.daysInStage > 90
                          ? 'info'
                          : data?.daysInStage > 45
                            ? 'error'
                            : data?.daysInStage > 30
                              ? 'warning'
                              : data?.daysInStage > 3
                                ? 'primary'
                                : 'success'
                      }
                      size="small"
                      variant={data?.daysInStage > 30 ? 'filled' : 'outlined'}
                      sx={{
                        height: '18px',
                        fontWeight: 600,
                        letterSpacing: '-0.2px',
                        border: 'none',
                        opacity: data?.daysInStage > 30 ? 1 : 0.8,
                        px: data?.daysInStage > 30 ? 1 : 0,
                      }}
                    />
                  </Tooltip>
                )}
              </Row>
            </Box>
          ) : null}
        </Box>
      </Card>
    </>
  )
}

export default PipelineBoardCard
