import { useState } from 'react'

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { RiFeedbackLine, RiSlackFill } from '@remixicon/react'
import toast from 'react-hot-toast'

import { useLocation } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'

const SEND_SLACK_SUPPORT = gql`
  mutation SendSlackSupport($input: SlackSupportInput!) {
    slackSupport(input: $input)
  }
`

const SlackSupport = ({
  label = 'Notify us',
  defaultMessage = '',
  size = 16,
  sx = {},
}) => {
  const location = useLocation()
  const { currentUser } = useAuth()

  const [sendSlackSupport] = useMutation(SEND_SLACK_SUPPORT)
  const [message, setMessage] = useState(defaultMessage)
  const [messageOpen, setMessageOpen] = useState(false)

  const handleSend = async () => {
    const email = currentUser.email
    const url = `${process.env.HOST}${location.pathname}`

    // use hot toast promise to send message:
    const promise = sendSlackSupport({
      variables: {
        input: {
          email,
          message,
          url,
        },
      },
    })

    await toast.promise(promise, {
      loading: 'Sending...',
      success: () => {
        setMessage('')
        setMessageOpen(false)
        return 'Message sent!'
      },
      error: 'Failed to send message',
    })
  }

  return (
    <>
      {label ? (
        <Button
          startIcon={<RiSlackFill />}
          size="small"
          variant="contained"
          sx={{ ...sx, flexShrink: 0, ml: 3, background: '#4A154B' }}
          onClick={() => setMessageOpen(true)}
        >
          {label}
        </Button>
      ) : (
        <IconButton
          onClick={() => setMessageOpen(true)}
          sx={{ ...sx, p: '4px', borderRadius: '2px' }}
        >
          <RiFeedbackLine size={size} />
        </IconButton>
      )}
      <Dialog
        open={messageOpen}
        onClose={() => setMessageOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>Tell us your question or concern</DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <TextField
            autoFocus={true}
            multiline={true}
            fullWidth={true}
            rows={8}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <Button
            onClick={handleSend}
            color="primary"
            fullWidth={true}
            variant="contained"
            sx={{ mt: 2, background: '#4A154B' }}
            startIcon={<RiSlackFill />}
            size="small"
          >
            Send
          </Button>
          <Typography sx={{ mt: 2, fontSize: '0.8rem' }}>
            We (the Day.ai team: Christopher, Mike, Gwen and Erik) will get this
            message instantly in Slack and work with you to resolve any issues.
            We will be notified of the page you are on and your email address,
            as well as your comment.
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default SlackSupport
