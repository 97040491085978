import { useContext, useMemo, useState } from 'react'

import {
  Button,
  CircularProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { IconBubbleText } from '@tabler/icons-react'
import dayjs from 'dayjs'

import { useQuery } from '@redwoodjs/web'

import ContextEditSidebar from 'src/components/ContextEditSidebar/ContextEditSidebar'
import Row from 'src/components/Row/Row'
import WorkspaceMemberChip from 'src/components/WorkspaceMemberChip/WorkspaceMemberChip'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

const GET_NOTES_FOR_PERSON = gql`
  query GetNotesForPerson($email: String!, $workspaceId: String!) {
    getNotesForPerson(email: $email, workspaceId: $workspaceId) {
      id
      plainTextValue
      createdAt
      userId
      parentReferenceKey
      referencedObjectIds
      createdAt
      updatedAt
      workspaceId
    }
  }
`

const SidebarNotes = ({ email }: { email: string }) => {
  const { selectedWorkspace } = useContext(DayContext)

  const [noteEdit, setNoteEdit] = useState(null)
  const { data, loading, refetch } = useQuery(GET_NOTES_FOR_PERSON, {
    variables: { email, workspaceId: selectedWorkspace },
  })

  const notes = useMemo(() => {
    if (!data) return []

    return data?.getNotesForPerson
  }, [data])

  const parentReferenceKey = `${NativeObjectTypes.Person} : ${email} : root`

  return (
    <>
      {notes.length > 0 ? (
        <>
          <List sx={{ p: 0 }}>
            {data?.getNotesForPerson.map((note) => (
              <ListItemButton
                key={note.id}
                sx={{ p: 0, px: 2 }}
                onClick={() => setNoteEdit(note)}
              >
                <ListItemText
                  primary={note.plainTextValue}
                  secondary={dayjs(note.createdAt).format('MM/DD/YYYY')}
                  primaryTypographyProps={{
                    noWrap: true,
                    variant: 'subtitle2',
                  }}
                  secondaryTypographyProps={{
                    noWrap: true,
                    variant: 'caption',
                  }}
                />

                <ListItemIcon
                  sx={{
                    minWidth: '172px',
                    width: '172px',
                    justifyContent: 'center',
                    ml: 2,
                    p: 0,
                  }}
                >
                  <WorkspaceMemberChip userId={note.userId} />
                </ListItemIcon>
              </ListItemButton>
            ))}
          </List>
        </>
      ) : loading ? (
        <CircularProgress size={16} />
      ) : (
        <Row sx={{ justifyContent: 'space-between', px: 2 }}>
          <Typography variant="subtitle2">
            No context has been added to this person
          </Typography>
          <Button
            startIcon={<IconBubbleText size={16} />}
            onClick={() => setNoteEdit({ new: true, parentReferenceKey })}
            size="small"
            variant="outlined"
          >
            Add context
          </Button>
        </Row>
      )}

      {noteEdit && (
        <ContextEditSidebar
          context={noteEdit}
          onClose={() => setNoteEdit(null)}
          onInit={() =>
            setNoteEdit({
              parentReferenceKey,
              new: true,
            })
          }
          onUpdate={() => {
            setNoteEdit(null)
            refetch()
          }}
        />
      )}
    </>
  )
}

export default SidebarNotes
