import { useContext, useMemo, useState } from 'react'

import ReactJson from '@microlink/react-json-view'
import {
  Box,
  Chip,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  RiAdminLine,
  RiCircleFill,
  RiCloseLine,
  RiShareForwardLine,
} from '@remixicon/react'
import { IconBubbleText } from '@tabler/icons-react'
import toast from 'react-hot-toast'

import { routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ContextEditSidebar from 'src/components/ContextEditSidebar/ContextEditSidebar'
import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import InteractionsTimeline from 'src/components/InteractionsTimeline/InteractionsTimeline'
import OrganizationActions from 'src/components/OrganizationActions/OrganizationActions'
import OrganizationPageCreateInstructions from 'src/components/OrganizationPageCreateInstructions/OrganizationPageCreateInstructions'
import OrganizationPeople from 'src/components/OrganizationPeople/OrganizationPeople'
import OrganizationLogoChooser from 'src/components/Organizations/OrganizationLogoChooser/OrganizationLogoChooser'
import OrganizationOneSentence from 'src/components/Organizations/OrganizationOneSentence/OrganizationOneSentence'
import OrganizationQuotes from 'src/components/Organizations/OrganizationQuotes/OrganizationQuotes'
import OrganizationSocialLinks from 'src/components/Organizations/OrganizationSocialLinks/OrganizationSocialLinks'
import OpportunityDomainChip from 'src/components/Pipeline/OpportunityDomainChip/OpportunityDomainChip'
import { DayContext } from 'src/lib/dayContext'
import { isInternalUser } from 'src/lib/gates'
import { logger } from 'src/lib/logger'
import { deepMerge, NativeObjectTypes } from 'src/lib/objects'

import Row from '../../Row/Row'
import { actionChipStyle, sidebarBodyTextStyle } from '../Sidebar'
import SidebarSlack from '../SidebarSlack/SidebarSlack'
import AiFeature from 'src/components/AiFeature/AiFeature'
import ActionCreateDialog from 'src/components/Actions/ActionCreateDialog/ActionCreateDialog'
import RelationshipOrgSidebar from 'src/components/Relationships/RelationshipOrgSidebar/RelationshipOrgSidebar'

const GET_ORGANIZATION_FOR_ORG_SIDEBAR = gql`
  query GetOrganizationForOrgSidebar($workspaceId: String!, $orgId: String!) {
    workspaceOrganization(workspaceId: $workspaceId, domain: $orgId) {
      id
      name
      domain
      employeeCount
      about {
        id
        description
        aiDescription
        phoneNumbers
        isHiring
        industryType
        founded
        employeesFrom
        employeesTo
        selling
      }
      headquarters {
        id
        address
        city
        state
        country
        postalCode
        latitude
        longitude
      }
      purpose {
        id
        differentiators
        marketingPromises
        missionAndVision
      }
      people {
        id
        fullName
        email
        currentJobTitle
        photoUrl
      }
      roles {
        id
        name
        email
        role
      }
      opportunities {
        id
        workspaceId
        title
        pipelineId
        pipelineTitle
        stage
        updatedAt
      }
      pages {
        id
        title
      }
      photos {
        id
        square
      }
      relationship {
        id
        upcomingEvents
        quotes {
          personEmail
          text
          meetingId
        }
        oneSentenceSummary
        proofOfPayment
        sensitiveToWhom
        sensitiveReasoning
        warmth
        origin
        types {
          id
          label
          createdAt
          key
          value
          userId
        }
      }
      links {
        id
        facebook
        x
        instagram
        linkedIn
        website
        websiteResolvedUrl
      }
      lifecycle {
        id
        pipelineType
        stageType
      }
      updatedAt
    }
  }
`

const SidebarLayoutOrganization = ({ domain, setOpen }) => {
  const { currentUser } = useAuth()
  const { selectedWorkspace, orgsByDomain } = useContext(DayContext)

  const [noteCreate, setNoteCreate] = useState(null)
  const [adminDebug, setAdminDebug] = useState(null)
  const [tab, setTab] = useState('status')

  const {
    data: orgData,
    loading: organizationLoading,
    refetch,
  } = useQuery(GET_ORGANIZATION_FOR_ORG_SIDEBAR, {
    variables: {
      workspaceId: selectedWorkspace,
      orgId: domain,
    },
    skip: !domain || !selectedWorkspace,
    onCompleted: ({ workspaceOrganization }) => {
      logger.dev('Data fetched')
    },
  })

  const organization = useMemo(() => {
    const merged = deepMerge(
      orgsByDomain[domain],
      orgData?.workspaceOrganization
    )
    return merged
  }, [orgData?.workspaceOrganization, orgsByDomain, domain])

  return (
    <>
      <Row sx={{ justifyContent: 'space-between', pt: 2, px: 3, pb: '4px' }}>
        <Row gap={2}>
          <DomainAvatar
            domain={domain}
            size={52}
          />
          <Box sx={{ width: '410px', overflow: 'hidden' }}>
            <Row gap={1}>
              <Typography
                variant="h1"
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  flexShrink: 1,
                  fontSize: '1.5rem',
                }}
              >
                {organization?.name || organization?.domain || domain}
              </Typography>
              <OrganizationSocialLinks organization={organization} />

              <AiFeature
                workspaceId={selectedWorkspace}
                featureKey="organizations"
                objectId={organization?.domain || domain}
                variant="outlined"
                size={16}
                lastUpdated={organization?.updatedAt}
                sx={{ p: '6px' }}
              />
            </Row>
            <Typography>{organization?.domain || domain}</Typography>
          </Box>
        </Row>
        <Row gap={1}>
          <IconButton onClick={() => setOpen(false)}>
            <RiCloseLine />
          </IconButton>
        </Row>
      </Row>

      <Row
        sx={{ mt: 1, px: 3 }}
        gap={1}
      >
        <Tooltip title={`Add note to ${organization?.name}`}>
          <Chip
            clickable={true}
            icon={<IconBubbleText size={16} />}
            label="Add context"
            variant="outlined"
            size="small"
            sx={{ ...actionChipStyle }}
            onClick={() => {
              setNoteCreate({
                parentReferenceKey: `${NativeObjectTypes.Organization} : ${domain} : root`,
                new: true,
              })
            }}
          />
        </Tooltip>

        <ActionCreateDialog
          variant="chip"
          orgs={[domain]}
          people={[]}
          oppIds={[]}
          workspaceId={selectedWorkspace}
          onUpdate={() => {
            refetch()
          }}
        />
        {noteCreate && (
          <ContextEditSidebar
            title={organization?.name || domain}
            context={noteCreate}
            onClose={() => {
              setNoteCreate(null)
            }}
            onInit={() => {}}
            onUpdate={() => {
              refetch()
            }}
          />
        )}
        <Tooltip
          title="Copy link to clipboard"
          arrow={true}
        >
          <Chip
            clickable={true}
            onClick={() => {
              navigator.clipboard.writeText(
                `${process.env.HOST}${routes.objectDetail({
                  workspaceId: selectedWorkspace,
                  objectTypeSlug: 'organizations',
                  objectId: organization?.domain || domain,
                })}`
              )
              toast.success('Link copied to clipboard!')
            }}
            size="small"
            sx={{ ...actionChipStyle }}
            label="Share"
            variant="outlined"
            icon={<RiShareForwardLine size={14} />}
          />
        </Tooltip>
        {false && <OrganizationPageCreateInstructions orgId={domain} />}
        {isInternalUser(currentUser) && (
          <Tooltip title="Debug organization">
            <IconButton
              onClick={() =>
                setAdminDebug((prev) => {
                  if (!prev) {
                    return 'logo'
                  }
                  return null
                })
              }
            >
              <RiAdminLine size={14} />
            </IconButton>
          </Tooltip>
        )}
      </Row>

      {isInternalUser(currentUser) && adminDebug && (
        <Box sx={{ px: 3, mt: 3 }}>
          <Tabs
            value={adminDebug}
            onChange={(_, value) => {
              if (value === 'edit') {
                logger.dev(value)
                window.open(
                  routes.adminOrganizationGlobal({
                    domain,
                  }),
                  '_blank'
                )
              } else {
                setAdminDebug(value)
              }
            }}
          >
            <Tab
              label="JSON"
              value="json"
            />
            <Tab
              label="Logo"
              value="logo"
            />
            <Tab
              label="Edit"
              value="edit"
            />
          </Tabs>
          {adminDebug === 'json' && (
            <ReactJson
              src={organization}
              collapsed={1}
            />
          )}
          {adminDebug === 'logo' && (
            <Box>
              <OrganizationLogoChooser domain={domain} />
            </Box>
          )}
        </Box>
      )}
      <Row sx={{ height: '64px' }}>
        <Tabs
          value={tab}
          onChange={(_, value) => {
            setTab(value)
          }}
          sx={{
            width: '100%',
            mx: 3,
            mt: 3,
            background: (theme) => theme.palette.secondary.light,
            borderRadius: '4px',
            '& .MuiTab-root': {
              border: 'none',
              borderRadius: '4px',
              flexGrow: 1,
              py: '4px',
              px: '12px',
              textTransform: 'none',
              fontSize: '12px',
              fontWeight: 600,
              minHeight: '0px',
              height: '30px',
              fontFeatureSettings: 'liga off, clig off',
              m: '4px',
              '&.Mui-selected': {
                background: (theme) => theme.palette.background.paper,
              },
            },
          }}
          TabIndicatorProps={{
            sx: {
              height: '0px',
            },
          }}
        >
          <Tab
            label="Status"
            value="status"
          />
          <Tab
            label="Timeline"
            value="timeline"
          />
          <Tab
            label="Opportunities"
            value="opportunities"
          />
          <Tab
            label="About"
            value="about"
          />
        </Tabs>
      </Row>
      <Box
        sx={{ px: 3, height: 'calc(100vh - 128px - 64px)', overflowY: 'auto' }}
      >
        {tab === 'status' && (
          <>
            {organization?.relationship?.oneSentenceSummary && (
              <Box
                sx={{
                  mt: 3,
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
              >
                <OrganizationOneSentence org={organization} />
              </Box>
            )}
            <RelationshipOrgSidebar
              org={organization}
              workspaceId={selectedWorkspace}
            />

            {organization?.roles?.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <OrganizationPeople organization={organization} />
              </Box>
            )}
            <SidebarSlack
              orgId={organization?.domain || domain}
              sx={{ mt: 5 }}
            />
            <Box sx={{ mt: 3 }}>
              <OrganizationActions orgId={organization?.domain || domain} />
            </Box>

            <Box>
              {organization?.relationship?.quotes && (
                <Box sx={{ mt: 3 }}>
                  <OrganizationQuotes org={organization} />
                </Box>
              )}
            </Box>
          </>
        )}
        <Box sx={{ mt: 3, display: tab === 'timeline' ? 'block' : 'none' }}>
          <InteractionsTimeline
            orgId={organization?.domain || domain}
            showHeader={false}
          />
        </Box>
        {tab === 'opportunities' && (
          <>
            <Box
              sx={{
                pt: 2,
                mb: 5,
              }}
            >
              <Row sx={{ justifyContent: 'space-between' }}>
                <Typography variant="h2">Opportunities</Typography>
                <OpportunityDomainChip
                  organization={organization}
                  openSidebar={true}
                />
              </Row>
            </Box>
          </>
        )}
        {tab === 'about' && (
          <Box sx={{ '& h2': { mb: 1 } }}>
            {(organization?.about?.aiDescription ||
              organization?.about?.description) && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h2">About</Typography>
                <Typography sx={sidebarBodyTextStyle}>
                  {organization?.about?.aiDescription ||
                    organization?.about?.description}
                </Typography>
              </Box>
            )}
            {organization?.purpose?.missionAndVision && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h2">Mission & Vision</Typography>
                <Typography sx={sidebarBodyTextStyle}>
                  {organization?.purpose?.missionAndVision}
                </Typography>
              </Box>
            )}
            {organization?.purpose?.values && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h2">Values</Typography>
                {organization?.purpose?.values.map((value, index) => (
                  <Row
                    key={`${value}-${index}-${organization?.domain}`}
                    gap={'12px'}
                    sx={{ mb: 2 }}
                  >
                    <RiCircleFill size={10} />
                    <Typography sx={sidebarBodyTextStyle}>{value}</Typography>
                  </Row>
                ))}
              </Box>
            )}
            {organization?.purpose?.differentiators && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h2">Differentiators</Typography>
                {organization?.purpose?.differentiators.map(
                  (differentiator, index) => (
                    <Row
                      key={`${differentiator}-${index}-${organization?.domain}`}
                      gap={'12px'}
                      sx={{ mb: 2 }}
                    >
                      <RiCircleFill size={10} />
                      <Typography sx={sidebarBodyTextStyle}>
                        {differentiator}
                      </Typography>
                    </Row>
                  )
                )}
              </Box>
            )}
            {organization?.purpose?.marketingPromises && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h2">Marketing Promises</Typography>
                {organization?.purpose?.marketingPromises.map(
                  (promise, index) => (
                    <Row
                      key={`${promise}-${index}-${organization?.domain}`}
                      gap={'12px'}
                      sx={{ mb: 2 }}
                    >
                      <RiCircleFill size={10} />
                      <Typography sx={sidebarBodyTextStyle}>
                        {promise}
                      </Typography>
                    </Row>
                  )
                )}
              </Box>
            )}
            {organization?.headquarters?.address && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h2">Headquarters</Typography>
                {['address', 'city', 'state', 'country', 'postalCode'].map(
                  (key) => (
                    <Typography
                      sx={sidebarBodyTextStyle}
                      key={`${key}-${organization?.domain}`}
                    >
                      {organization?.headquarters?.[key]}
                    </Typography>
                  )
                )}
              </Box>
            )}
            {organization?.about?.isHiring && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h2">Growth</Typography>
                <Typography sx={sidebarBodyTextStyle}>
                  {organization?.about?.isHiring
                    ? `${organization?.name} is hiring! Day.ai Assistant has uncovered evidence that this organization is hiring.`
                    : `Day.ai Assistant has not uncovered evidence that ${organization?.name} is hiring, though it is possible that they are.`}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  )
}

export default SidebarLayoutOrganization
