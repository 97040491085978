import { useContext, useEffect, useState } from 'react'

import {
  Alert,
  Box,
  Grid,
  ListItemButton,
  Pagination,
  useTheme,
} from '@mui/material'
import {
  RiCheckboxCircleFill,
  RiErrorWarningFill,
  RiRestartFill,
} from '@remixicon/react'

import { navigate } from '@redwoodjs/router'
import { Metadata, useQuery } from '@redwoodjs/web'

import LibraryNav from 'src/components/Library/LibraryDropdownFilterSort/LibraryNav/LibraryNav'
import LibraryTabs from 'src/components/LibraryTabs/LibraryTabs'
import LogoLoader from 'src/components/LogoLoader/LogoLoader'
import MeetingRecordingCalendarSettings from 'src/components/MeetingRecordingCalendarSettings/MeetingRecordingCalendarSettings'
import MeetingRecordingListItem from 'src/components/MeetingRecordingListItem/MeetingRecordingListItem'
import MeetingRecordingsTable from 'src/components/MeetingRecordingsTable/MeetingRecordingsTable'
import PersonFinder from 'src/components/People/PersonFinder/PersonFinder'
import Row from 'src/components/Row/Row'
import ViewmodeToggle from 'src/components/ViewmodeToggle/ViewmodeToggle'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import {
  addMeetingRecordingStatus,
  recordingLink,
} from 'src/lib/meetingRecordingFormatting'

const MEETING_RECORDINGS_FOR_LIBRARY = gql`
  query MeetingRecordingsForLibrary($limit: Int!, $page: Int!) {
    meetingRecordingsPaged(limit: $limit, page: $page) {
      pageInfo {
        limit
        page
        totalPages
      }
      recordings {
        id
        title
        summary {
          output
        }
        startedAt
        endedAt
        statusHistory {
          level
          status
          createdAt
          reason
          message
        }
        participants {
          email
        }
        clips {
          id
          startSeconds
          endSeconds
        }
        calendarEvents {
          id
          GoogleEvent {
            title
          }
        }
      }
    }
  }
`

export const getStringLeaves = (obj: any): string[] => {
  const leaves: string[] = []

  function traverse(current: any) {
    if (typeof current === 'object' && current !== null) {
      for (const key in current) {
        traverse(current[key])
      }
    } else if (typeof current === 'string') {
      leaves.push(current)
    }
  }

  traverse(obj)
  return leaves
}

export const getAvatarEmails = (meetingRecording, allUserEmails) => {
  const emails = meetingRecording.participants
    ?.filter((p) => !!p.email && !allUserEmails.includes(p.email))
    .map((p) => p.email)

  if (emails.length === 0) {
    emails.push('assistant@day.ai')
  }
  return emails
}

const LibraryPage = () => {
  const FEATURED = 3
  const PAGE_SIZE = 20
  const theme = useTheme()
  const { workAccounts, setSidebarObject } = useContext(DayContext)
  const [allRecordings, setAllRecordings] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [meetingRecordings, setMeetingRecordings] = useState<any[]>([])
  const [viewmode, setViewmode] = useState<'hybrid' | 'grid' | 'list'>('hybrid')
  const [currentTab, setCurrentTab] = useState<string>('ready')

  const { data, error, loading } = useQuery(MEETING_RECORDINGS_FOR_LIBRARY, {
    variables: { limit: PAGE_SIZE, page },
    pollInterval: 60000,
    onCompleted: (result) => {
      logger.dev({ result })
      const allRecordingsWithoutStatus = [
        ...result.meetingRecordingsPaged.recordings,
      ]
      const allRecordingsWithStatus = addMeetingRecordingStatus({
        meetingRecordings: allRecordingsWithoutStatus,
      })

      allRecordingsWithStatus.sort(
        (a, b) => new Date(b.startedAt) - new Date(a.startedAt)
      )
      setAllRecordings(allRecordingsWithStatus)
      setMeetingRecordings(
        allRecordingsWithStatus.filter(
          (recording) => recording.tab === currentTab
        )
      )
    },
  })

  useEffect(() => {
    if (currentTab) {
      setMeetingRecordings(
        allRecordings.filter((recording) => recording.tab === currentTab)
      )
    }
  }, [currentTab])

  useEffect(() => {
    const viewmode = localStorage.getItem('library_viewmode')
    if (viewmode) {
      setViewmode(viewmode as 'grid' | 'list')
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('library_viewmode', viewmode)
  }, [viewmode])

  logger.dev({ meetingRecordings })

  return (
    <>
      <Metadata
        title="Library | Day.ai"
        description="Library | Day.ai"
      />
      <Row
        sx={{
          justifyContent: 'space-between',
          height: '64px',
          px: 3,
        }}
      >
        <LibraryNav tab="meetings" />
      </Row>
      <Row
        sx={{
          justifyContent: 'space-between',
          height: '64px',
        }}
      >
        <Row
          sx={{
            height: '64px',
            pl: 3,
            gap: 1,
          }}
        >
          <LibraryTabs
            tab={currentTab}
            setTab={setCurrentTab}
            options={[
              {
                label: 'Ready',
                value: 'ready',
                icon: (
                  <RiCheckboxCircleFill
                    size={16}
                    style={{ color: theme.palette.success.main }}
                  />
                ),
              },
              {
                label: 'Pending',
                value: 'pending',
                icon: (
                  <RiRestartFill
                    size={16}
                    style={{ color: theme.palette.info.main }}
                  />
                ),
              },
              {
                label: 'Did not record',
                value: 'failed',
                icon: (
                  <RiErrorWarningFill
                    size={16}
                    style={{ color: theme.palette.warning.main }}
                  />
                ),
              },
            ]}
          />

          <MeetingRecordingCalendarSettings refetch={() => {}} />
        </Row>
        <Row
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <PersonFinder
            showAvatars={false}
            onSelect={(person) => {
              // add one key value pair to person.properties to indicate the meeting sidebar should be open:
              const personForSidebar = {
                ...person,
                properties: {
                  ...person.properties,
                  sidebarFocus: 'MEETINGS',
                },
              }
              setSidebarObject(personForSidebar)
            }}
            size="small"
            clearOnSelect={true}
            label={'Search by person'}
            sx={{
              width: '256px',
              mr: 2,
              borderColor: (theme) => theme.palette.divider,
              '& .MuiFormControl-root, .MuiTextField-root': {
                '& .MuiFormLabel-root': {
                  fontSize: '0.8rem',
                  fontWeight: 500,
                  color: (theme) => theme.palette.text.secondary,
                },
              },
              '& .MuiInputBase-root': {
                p: 0,
                '& .MuiAutocomplete-input': {
                  p: 0,
                  pl: 2,
                  textTransform: 'none',
                  fontSize: '0.8rem',
                  fontWeight: 500,
                  color: (theme) => theme.palette.text.secondary,
                  borderColor: (theme) => theme.palette.divider,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                },
                '& .MuiInputLabel-shrink': {
                  top: 0,
                },
              },
            }}
          />
          {/*<LibraryDropdownFilterSort
              sortOptions={[
                { value: 'date', label: 'Date' },
                { value: 'length', label: 'Length' },
                { value: 'numClips', label: '# of Clips' },
              ]}
              filterOptions={[
                { value: 'all', label: 'All' },
                { value: 'oneOnOne', label: '1:1' },
                { value: 'group', label: 'Group' },
                { value: 'pending', label: 'Pending' },
              ]}
              onChange={handleFilterSortChange}
              objectName={'meetings'}
            />*/}

          <ViewmodeToggle
            viewmode={viewmode}
            setViewmode={setViewmode}
            options={['hybrid', 'grid', 'list']}
          />
        </Row>
      </Row>

      <Box
        sx={{
          width: '100%',
          height: 'calc(100vh - 128px)',
          overflowY: 'auto',
        }}
      >
        {loading && !data && (
          <Box
            sx={{
              p: 0,
              m: 0,
              width: '100%',
              height: 'calc(100vh - 300px)',
            }}
          >
            <LogoLoader />
          </Box>
        )}

        {(!loading || data) && (
          <Box sx={{ py: 1 }}>
            {meetingRecordings?.length > 0 ? (
              <Box sx={{ px: 3 }}>
                <Grid
                  container
                  spacing={3}
                >
                  {meetingRecordings?.length > 0 &&
                    ['hybrid', 'grid'].includes(viewmode) &&
                    meetingRecordings
                      .slice(
                        viewmode === 'hybrid' ? 0 : undefined,
                        viewmode === 'hybrid' ? FEATURED : undefined
                      )
                      .map((video, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={7 - FEATURED}
                          lg={7 - FEATURED}
                          xl={7 - FEATURED}
                          key={`video_list_large_tile_${index}`}
                        >
                          <ListItemButton
                            onClick={() => {
                              navigate(
                                recordingLink({ meetingRecording: video })
                              )
                            }}
                            sx={{
                              borderRadius: 4,
                              height: '100%',
                              padding: 0,
                            }}
                          >
                            <MeetingRecordingListItem
                              meetingRecording={video}
                            />
                          </ListItemButton>
                        </Grid>
                      ))}
                </Grid>
                {meetingRecordings &&
                  ((viewmode === 'hybrid' &&
                    meetingRecordings.length > FEATURED) ||
                    viewmode === 'list') && (
                    <Box sx={{ mt: 3 }}>
                      <MeetingRecordingsTable
                        tab={currentTab}
                        meetingRecordings={
                          viewmode === 'list'
                            ? meetingRecordings
                            : meetingRecordings.slice(FEATURED)
                        }
                      />
                      {data.meetingRecordingsPaged.pageInfo.totalPages > 1 && (
                        <Row sx={{ justifyContent: 'center', py: 3 }}>
                          <Pagination
                            count={
                              data.meetingRecordingsPaged.pageInfo.totalPages
                            }
                            shape="rounded"
                            page={page}
                            onChange={(event, value) => {
                              setPage(value)
                            }}
                          />
                        </Row>
                      )}
                    </Box>
                  )}
              </Box>
            ) : (
              <Box sx={{ px: 3 }}>
                {!loading && (
                  <>
                    {allRecordings.length === 0 ? (
                      <Alert severity="info">You have no recordings yet</Alert>
                    ) : (
                      <Alert severity="info">
                        No recordings match your filters
                      </Alert>
                    )}
                  </>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  )
}

export default LibraryPage
