import { useContext } from 'react'

import { Google } from '@mui/icons-material'
import { Alert, Box, Button } from '@mui/material'

import { DayContext } from 'src/lib/dayContext'
import { gatedForWorkAccountRemediationBanner } from 'src/lib/gates'

import { isWorkAccountHealthy } from '../WorkAccount/workAccountUtil'

const UserErrorRemediation = () => {
  const { workAccounts } = useContext(DayContext)
  return (
    <>
      {workAccounts?.length > 0 &&
        workAccounts.map((workAccount) => (
          <Box key={`workAccountError_${workAccount.id}`}>
            {!isWorkAccountHealthy({ workAccount }) &&
              !gatedForWorkAccountRemediationBanner.includes(
                workAccount.email
              ) && (
                <Alert
                  severity="error"
                  sx={{}}
                  action={
                    <Button
                      component="a"
                      href={'/api/authRedirectGoogle/authRedirectGoogle'}
                      variant="contained"
                      startIcon={<Google />}
                      size="small"
                      color="error"
                    >
                      Reconnect
                    </Button>
                  }
                >
                  <Box
                    component="span"
                    sx={{ fontWeight: 600, mr: 4 }}
                  >
                    {workAccount.email}
                  </Box>
                  {`There is an error with your Google Account connection. Reconnect & grant permissions to fix.`}
                </Alert>
              )}
          </Box>
        ))}
    </>
  )
}

export default UserErrorRemediation
