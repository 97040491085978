import { useContext, useMemo, useState } from 'react'

import {
  Box,
  Card,
  CardHeader,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import {
  RiCloseLargeLine,
  RiMailAddLine,
  RiStickyNoteAddLine,
} from '@remixicon/react'
import { useEditor } from '@tiptap/react'
import toast from 'react-hot-toast'

import { useQuery } from '@redwoodjs/web'

import { NativeObjectFormatters } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

import LogoLoader from '../LogoLoader/LogoLoader'
import ObjectAvatar from '../ObjectAvatar/ObjectAvatar'
import Row from '../Row/Row'
import { extensionsObjectSearchResult } from '../Tiptap/extensions'
import TiptapBlockPreviewer from '../Tiptap/TiptapBlockPreviewer/TiptapBlockPreviewer'
import TiptapEditor from '../Tiptap/TiptapEditor/TiptapEditor'

const OBJECT_SEARCH = gql`
  query ObjectSearch(
    $objectId: String!
    $objectType: String!
    $workspaceId: String!
  ) {
    search(
      objectId: $objectId
      objectType: $objectType
      workspaceId: $workspaceId
    ) {
      contentJson
    }
  }
`

const getAllBlocks = (contentJson) => {
  const blockTypes = ['objectChip', 'organization']
  // resursively search for blocks, checking their type to see if it's in the list of blocks we want to display
  const getBlocks = (contentJson) => {
    if (!contentJson) return []

    if (Array.isArray(contentJson)) {
      return contentJson.flatMap((block) => getBlocks(block))
    }
    if (contentJson.type && blockTypes.includes(contentJson.type)) {
      return [contentJson]
    }
    if (contentJson.content) {
      return getBlocks(contentJson.content)
    }
    return []
  }
  return getBlocks(contentJson)
}

const ObjectFinderActions = ({
  resultObject,
  onDeselect,
  forceDisplayMode = null,
  addToPage = (blockJson) => {},
}) => {
  const { selectedWorkspace } = useContext(DayContext)
  const [mode, setMode] = useState<'page' | 'blocks'>(
    forceDisplayMode || 'page'
  )

  const { data } = useQuery(OBJECT_SEARCH, {
    variables: {
      objectId: resultObject.objectId,
      objectType: resultObject.objectType,
      workspaceId: selectedWorkspace,
    },
    onCompleted: ({ search }) => {
      logger.dev({ search })
    },
  })

  const editor = useEditor({
    extensions: extensionsObjectSearchResult,
    content: data?.search?.contentJson,
    autofocus: true,
    editable: true,
  })

  if (data?.search?.contentJson && editor && editor.isEmpty) {
    editor.commands.setContent(data?.search?.contentJson)
  }

  const blocks = useMemo(() => {
    return getAllBlocks(data?.search?.contentJson)
  }, [data])

  return (
    selectedWorkspace &&
    resultObject && (
      <Card sx={{ height: '100%' }}>
        <CardHeader
          disableTypography={false}
          avatar={
            <ObjectAvatar
              crmObject={{
                objectType: resultObject.objectType,
                objectId: resultObject.objectId,
                properties: {
                  photoUrl: resultObject.photoUrl,
                  ...resultObject.properties,
                },
              }}
              showSidebar={true}
              size={36}
              borderRadius={100}
            />
          }
          title={resultObject.label}
          titleTypographyProps={{ sx: { fontSize: '1.0rem', fontWeight: 600 } }}
          subheader={NativeObjectFormatters[resultObject.objectType].displayId(
            resultObject
          )}
          action={
            <Row>
              {forceDisplayMode === 'page' && (
                <>
                  <IconButton>
                    <RiMailAddLine size={20} />
                  </IconButton>
                  <IconButton>
                    <RiStickyNoteAddLine size={20} />
                  </IconButton>
                </>
              )}
              <IconButton onClick={onDeselect}>
                <RiCloseLargeLine size={20} />
              </IconButton>
            </Row>
          }
        />
        {!forceDisplayMode && (
          <Row sx={{ justifyContent: 'center' }}>
            <Typography
              variant="caption"
              sx={{ color: 'text.secondary', height: '36px' }}
            >
              View results as:
            </Typography>
            <ToggleButtonGroup
              value={mode}
              exclusive={true}
              onChange={(_, newMode) => setMode(newMode)}
              aria-label="search or ask"
            >
              <ToggleButton value="page">Page</ToggleButton>
              <ToggleButton value="blocks">Blocks</ToggleButton>
            </ToggleButtonGroup>
          </Row>
        )}
        <>
          {data ? (
            <>
              {mode === 'page' ? (
                <TiptapEditor editor={editor} />
              ) : (
                <Box>
                  <Typography sx={{ fontWeight: 600, fontSize: '1.1rem' }}>
                    Add to page
                  </Typography>
                  <List>
                    {blocks.map((block, index) => (
                      <ListItemButton
                        key={`blockResults_${index}`}
                        onClick={(e) => {
                          addToPage(block)
                          const button = e.target as HTMLElement
                          button.style.display = 'none'
                          toast.success('Added to page')
                        }}
                        sx={{ alignItems: 'flex-start', borderRadius: '4px' }}
                      >
                        <ListItemIcon>
                          <RiStickyNoteAddLine size={24} />
                        </ListItemIcon>
                        <TiptapBlockPreviewer block={block} />
                      </ListItemButton>
                    ))}
                  </List>
                </Box>
              )}
            </>
          ) : (
            <LogoLoader />
          )}
        </>
      </Card>
    )
  )
}

export default ObjectFinderActions
