export const getBoardStyles = ({ theme }) => {
  const themeValues = {
    backgroundColorCard: theme.palette.background.paper,
    backgroundColorColumn: theme.palette.background.default,
    borderColor: theme.palette.divider,
    hoverBackgroundColor: theme.palette.action.hover,
    borderRadius: '4px',
    boxShadowDragging: `
      0 1px 2px rgba(136, 161, 183, 0.08),
      0 2px 4px rgba(136, 161, 183, 0.08),
      0 4px 8px rgba(136, 161, 183, 0.08),
      0 8px 16px rgba(136, 161, 183, 0.08),
      0 16px 32px rgba(136, 161, 183, 0.08),
      0 32px 64px rgba(136, 161, 183, 0.08)
    `,
    boxShadowNotDragging: `
      0 0.5px 1px rgba(136, 161, 183, 0.05),
      0 1px 2px rgba(136, 161, 183, 0.05),
      0 2px 4px rgba(136, 161, 183, 0.05),
      0 4px 8px rgba(136, 161, 183, 0.05),
      0 8px 16px rgba(136, 161, 183, 0.05),
      0 16px 32px rgba(136, 161, 183, 0.05)
    `,
    cardSkeletonWidth: '288px',
    cardHeight: '178px',
    cardMargin: '0px',
    cardBorder: `1px solid ${theme.palette.divider}`,
    cardPadding: '12px',
    inputOutlineFocus: 'none',
  }

  return {
    height: '100%',
    width: '100%',
    position: 'relative',
    overflowX: 'auto',
    overflowY: 'visible',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      width: '100px',
      background:
        'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #F9FAFB 100%)',
      pointerEvents: 'none',
    },
    '.react-kanban-board': {
      padding: 0,
      pl: 3,
      pr: '80px',
      height: '100%',
      '& > div': {
        height: '100%',
      },
      overflowX: 'auto',
      scrollbarWidth: 'thin',
    },
    '.react-kanban-card': {
      height: themeValues.cardHeight,
      borderRadius: themeValues.borderRadius,
      border: themeValues.cardBorder,
      backgroundColor: themeValues.backgroundColorCard,
      padding: themeValues.cardPadding,
      margin: themeValues.cardMargin,
      marginBottom: '18px',
      width: themeValues.cardSkeletonWidth,
      boxShadow: themeValues.boxShadowNotDragging,
      transition: 'all 0.5s ease-in-out',
      '&.dragging-true': {
        boxShadow: themeValues.boxShadowDragging,
        transform: 'rotate(-3deg)',
      },
      '&__description': {
        paddingTop: '10px',
      },
      '&__title': {
        borderBottom: `1px solid ${themeValues.borderColor}`,
        paddingBottom: '5px',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    '.react-kanban-card-skeleton': {
      boxSizing: 'border-box',
      maxWidth: themeValues.cardSkeletonWidth,
      minWidth: themeValues.cardSkeletonWidth,
      flexShrink: 0,
    },
    '.react-kanban-column': {
      borderRadius: themeValues.borderRadius,
      width: `calc(${themeValues.cardSkeletonWidth} + 24px)`,
      backgroundColor: themeValues.backgroundColorColumn,
      mr: 0,
      flexShrink: 0,
      height: '100%',
      '& input:focus': {
        outline: themeValues.inputOutlineFocus,
      },
      '& > div:nth-of-type(2)': {
        height: 'calc(100vh - 136px) !important',
        overflowY: 'auto',
        pt: 2,
      },
      '& > div': {
        pl: 0,
        scrollbarWidth: 'none',
      },
    },
    '.react-kanban-card-adder-form': {
      borderRadius: themeValues.borderRadius,
      backgroundColor: themeValues.backgroundColorCard,
      padding: themeValues.cardPadding,
      marginBottom: themeValues.cardMargin,
      '& input': {
        border: 0,
        fontFamily: 'inherit',
        fontSize: 'inherit',
        '&:focus': {
          outline: themeValues.inputOutlineFocus,
        },
      },
    },
    '.react-kanban-card-adder-button': {
      width: '100%',
      marginTop: '5px',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      border: `1px solid ${themeValues.borderColor}`,
      transition: '0.3s',
      borderRadius: themeValues.borderRadius,
      fontSize: '20px',
      marginBottom: '10px',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: themeValues.hoverBackgroundColor,
      },
    },
    '.react-kanban-card-adder-form__title, .react-kanban-card-adder-form__description':
      {
        fontWeight: 'bold',
        borderBottom: `1px solid ${themeValues.borderColor}`,
        paddingBottom: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        '&:focus': {
          outline: themeValues.inputOutlineFocus,
        },
      },
    '.react-kanban-card-adder-form__button': {
      backgroundColor: themeValues.backgroundColorColumn,
      border: 'none',
      padding: '5px',
      width: '45%',
      marginTop: '5px',
      borderRadius: themeValues.borderRadius,
      '&:hover': {
        transition: '0.3s',
        cursor: 'pointer',
        backgroundColor: themeValues.hoverBackgroundColor,
      },
    },
    '.react-kanban-column-header': {
      pb: 1,
      width: 'calc(100% - 32px)',
      '& .MuiTypography-root.title, input': {
        fontWeight: 600,
        fontSize: '18px',
        letterSpacing: '-0.6px',
        lineHeight: '150%',
      },
      '& input:focus': {
        outline: themeValues.inputOutlineFocus,
      },
    },
    '.react-kanban-column-header__button': {
      color: '#333',
      backgroundColor: '#fff',
      borderColor: themeValues.borderColor,
      '&:hover, &:focus, &:active': {
        backgroundColor: '#e6e6e6',
      },
    },
    '.react-kanban-column-adder-button': {
      border: '2px dashed #eee',
      height: '132px',
      margin: '5px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '.react-kanban-column-adder-button-new': {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }
}
