import { Box, Typography } from '@mui/material'
import { NativeObjectTypes } from 'src/lib/objects'
import { Organization } from 'types/graphql'
import RelationshipChooser from '../RelationshipChooser/RelationshipChooser'
import Row from 'src/components/Row/Row'
import { useCallback, useContext } from 'react'
import { DayContext } from 'src/lib/dayContext'
import RelationshipChip from '../RelationshipChip/RelationshipChip'
import { logger } from 'src/lib/logger'
import { useMutation } from '@redwoodjs/web'
import { cacheWorkspaceOrganizations } from 'src/lib/indexedDb'
import toast from 'react-hot-toast'

const UPDATE_ORG_RELATIONSHIP_FROM_SIDEBAR = gql`
  mutation UpdateOrganizationRelationshipFromSidebar(
    $input: UpdateOrganizationRelationshipInput!
  ) {
    updateOrganizationRelationship(input: $input)
  }
`

const CREATE_RELATIONSHIP_FROM_SIDEBAR = gql`
  mutation CreateRelationshipEdgeFromSidebar(
    $input: UpdateOrganizationRelationshipInput!
  ) {
    createRelationshipEdge(input: $input) {
      id
      domain
      relationship {
        id
        types {
          id
          label
          userId
          createdAt
          objectId
          source
          key
          value
        }
      }
    }
  }
`

const RelationshipOrgSidebar = ({
  org,
  workspaceId,
}: {
  org: Organization
  workspaceId: string
}) => {
  const { refetchWorkspaceOrganizations } = useContext(DayContext)

  const [updateOrganizationRelationshipFromSidebar] = useMutation(
    UPDATE_ORG_RELATIONSHIP_FROM_SIDEBAR
  )

  const [createRelationshipEdgeFromSidebar] = useMutation(
    CREATE_RELATIONSHIP_FROM_SIDEBAR,
    {
      onCompleted: (data) => {
        logger.dev({ data })
        const domain = data.createRelationshipEdge.domain
        cacheWorkspaceOrganizations(
          workspaceId,
          { [domain]: data.createRelationshipEdge } || {}
        )
        refetchWorkspaceOrganizations()
      },
    }
  )

  const handleCreateRelationship = useCallback(
    ({ objectType, objectId, relationship }) => {
      const input = {
        workspaceId,
        orgId: objectId,
        type: relationship.type,
        stage: relationship.stage,
        direction: relationship.direction,
        value: true,
      }
      toast.promise(
        createRelationshipEdgeFromSidebar({
          variables: { input },
        }),
        {
          loading: 'Creating relationship...',
          success: () => {
            refetchWorkspaceOrganizations()
            return 'Created relationship'
          },
          error: 'Failed to create relationship',
        }
      )
    },
    [createRelationshipEdgeFromSidebar]
  )

  const handleRemoveRelationship = useCallback(
    ({ objectType, objectId, relationship }) => {
      const input = {
        workspaceId,
        orgId: objectId,
        type: relationship.type,
        stage: relationship.stage,
        direction: relationship.direction,
        value: false,
      }

      toast.promise(
        updateOrganizationRelationshipFromSidebar({
          variables: {
            input,
          },
        }),
        {
          loading: 'Removing relationship...',
          success: () => {
            refetchWorkspaceOrganizations()
            return 'Removed relationship'
          },
          error: 'Failed to remove relationship',
        }
      )
    },
    [updateOrganizationRelationshipFromSidebar, refetchWorkspaceOrganizations]
  )

  return (
    org && (
      <Box>
        <Row sx={{ justifyContent: 'space-between', flexWrap: 'wrap', mt: 2 }}>
          <Typography variant="h2">Relationship</Typography>
          <Row gap={1}>
            {org &&
              Array.isArray(org.relationship?.types) &&
              org.relationship.types.map((relationshipType) => (
                <RelationshipChip
                  objectId={org.id}
                  objectType={NativeObjectTypes.Organization}
                  relationship={relationshipType}
                  handleRemoveRelationship={handleRemoveRelationship}
                />
              ))}
            <RelationshipChooser
              objectType={NativeObjectTypes.Organization}
              objectId={org.id}
              onCreate={handleCreateRelationship}
              workspaceId={workspaceId}
            />
          </Row>
        </Row>
      </Box>
    )
  )
}

export default RelationshipOrgSidebar
