import Table from '@tiptap/extension-table'

const TiptapNodeTableBasic = Table.extend({
  name: 'day-ai-table-text',
  group: 'block',
  atom: true,
  draggable: true,

  addAttributes() {
    return {}
  },

  parseHTML() {
    return [
      {
        tag: 'table.day-ai-table-text',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'table',
      { ...HTMLAttributes, class: 'day-ai-table-text' },
      ['tbody', 0],
    ]
  },
})

export default TiptapNodeTableBasic
