import { useState } from 'react'

import { Box, Typography } from '@mui/material'
import { RiDraggable } from '@remixicon/react'
import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react'

import MeetingRecordingActionItems from 'src/components/MeetingRecordingActionItems/MeetingRecordingActionItems'
import Row from 'src/components/Row/Row'

import { nodeDragHandleSx, nodeViewContainerSx } from '../styles'
import TiptapSignupCta from '../TiptapSignupCta/TiptapSignupCta'

const TiptapNodeMeetingRecordingActionItemsRenderer = (props) => {
  const [ctaOpen, setCtaOpen] = useState(false)
  const id = props.node.attrs.id

  return (
    id && (
      <NodeViewWrapper
        as="div"
        className="day-ai-meeting-recording-action-items"
        draggable={props.editor.isEditable}
        data-drag-handle=""
        onClick={() => {
          if (!props.editor.isEditable && !ctaOpen) {
            setCtaOpen(true)
          }
        }}
      >
        <Row
          sx={{ ...nodeViewContainerSx, pt: 0, height: 'auto' }}
          className={`day-ai-node  ${
            props.editor.isEditable ? 'editable' : 'public'
          } ${props.selected ? 'selected' : ''}`}
        >
          <Box
            className="drag-handle"
            sx={{ ...nodeDragHandleSx, pt: '14px' }}
          >
            <RiDraggable />
          </Box>
          <Box
            sx={{
              pt: 1,
              flexGrow: 1,
              width: '100%',
            }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: '1.4rem !important' }}>
              Post-meeting Action Items
            </Typography>
            <MeetingRecordingActionItems
              meetingRecordingId={id}
              isPublic={!props.editor.isEditable}
            />
          </Box>
        </Row>
        <TiptapSignupCta
          open={ctaOpen}
          setClosed={() => setCtaOpen(false)}
          text={`Mark action items as done by signing up for Day.ai`}
          redirectTo="/login"
        />
      </NodeViewWrapper>
    )
  )
}

const TiptapNodeMeetingRecordingActionItems = Node.create({
  name: 'meetingRecordingActionItems',
  group: 'block',
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'day-ai-meeting-recording-action-items',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'day-ai-meeting-recording-action-items',
      mergeAttributes(HTMLAttributes),
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(TiptapNodeMeetingRecordingActionItemsRenderer)
  },
})

export default TiptapNodeMeetingRecordingActionItems
