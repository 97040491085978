import { useMutation } from '@apollo/client'
import { Box, MenuItem, Select } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

const UPDATE_CALENDAR_AUTO_RECORD_SETTINGS = gql`
  mutation UpdateCalendarAutoRecordSettings(
    $input: UpdateCalendarAutoRecordSettingsInput!
  ) {
    updateCalendarAutoRecordSettings(input: $input) {
      mode
      availableModes
    }
  }
`

const labels = {
  ALL: 'All meetings by default',
  INTERNAL_ONLY: 'Internal meetings by default',
  EXTERNAL_ONLY: 'External meetings by default',
  NONE: 'No meetings (unless specifically requested)',
}

const WorkAccountCalendarRecordingSettings = ({
  workAccount,
  refetch,
  showLogo = true,
}) => {
  const { refetchWorkAccounts } = useContext(DayContext)
  const [settings, setSettings] = useState(
    workAccount?.calendarAutoRecordSettings
  )

  useEffect(() => {
    setSettings(workAccount?.calendarAutoRecordSettings)
  }, [workAccount?.calendarAutoRecordSettings])

  const [updateCalendarAutoRecordSettings] = useMutation(
    UPDATE_CALENDAR_AUTO_RECORD_SETTINGS,
    {
      onCompleted: () => {
        refetchWorkAccounts()
      },
    }
  )

  const handleModeChange = (e) => {
    setSettings((prev) => {
      return { ...prev, mode: e.target.value }
    })

    const promise = updateCalendarAutoRecordSettings({
      variables: {
        input: {
          workAccountId: workAccount.id,
          mode: e.target.value,
        },
      },
    })

    toast.promise(promise, {
      loading: 'Updating settings...',
      success: () => {
        if (typeof refetch === 'function') {
          setTimeout(refetch, 1500)
        }
        return 'Settings updated'
      },
      error: (error) => {
        logger.dev({ error })
        return 'Failed to update settings'
      },
    })
  }

  return (
    workAccount &&
    settings && (
      <Box>
        {showLogo && (
          <Box>
            <Box component="span">{`For your `}</Box>
            <Box
              component="span"
              sx={{ fontWeight: 600 }}
            >
              {`${workAccount.email}`}
            </Box>
            <Box component="span">{` calendar, Day.ai should record:`}</Box>
          </Box>
        )}

        <Select
          fullWidth={true}
          size="small"
          variant="standard"
          value={settings.mode}
          disableUnderline={!showLogo}
          sx={{
            fontSize: '0.8rem',
            fontWeight: '500',
            alignItems: 'center',
            pb: 0,
            '& .MuiSelect-select': {
              p: '6px',
              borderRadius: '4px',
            },
          }}
          onChange={(e) => {
            handleModeChange(e)
          }}
        >
          {settings.availableModes.map((mode) => (
            <MenuItem
              key={mode}
              value={mode}
            >
              {labels[mode]}
            </MenuItem>
          ))}
        </Select>
      </Box>
    )
  )
}

export default WorkAccountCalendarRecordingSettings
